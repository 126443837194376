export const getRichContentMaxWidth = (calendarType) => {
  switch (calendarType) {
    case "advent":
      return "500px";
    case "memory-game":
      return "193px";
    case "hide-and-seek":
      return "500px";
    default:
      return "500px";    
  }
}
