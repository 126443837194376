import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  HelpBlock,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import Button from '../../components/CustomButton/CustomButton'
import { FormInputs } from '../../components/FormInputs/FormInputs'
import { firebaseAuth } from '../../firebase'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'
import Message from './Parts/Message.js'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactPasswordStrength from 'react-password-strength-bar'
import Loading from '../../components/Loading/Loading'
import { Method, cloudFunctionRequest } from '../../helpers/cloudFunction'

const recaptchaRef = React.createRef()
class Login extends Component {
  state = {
    isRegistred: false,
    errorEmail: null,
    errorPass1: null,
    errorPass2: null,
    errorFirstName: null,
    errorLastName: null,
    errorCompanyName: null,
    errorCountry: null,
    errorTerms: null,
    errorGlobal: null,
    loading: false,
    recaptcha: false
  }

  componentDidMount() {
    firebaseAuth.onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        this.setState({ ...this.state, isAuthentificated: true })
      }
    })
  }

  handleSubmit = event => {
    const { t } = this.props

    event.preventDefault()

    let errorEmail,
      errorPass1,
      errorPass2,
      errorFirstName,
      errorLastName,
      errorCompanyName,
      errorTerms,
      errorRecaptcha

    const data = new FormData(event.target)
    const email = data.get('email')
    const password1 = data.get('password1')
    const password2 = data.get('password2')
    const firstName = data.get('firstName')
    const lastName = data.get('lastName')
    const companyName = data.get('companyName')
    const terms = data.get('terms')
    const recaptcha = this.state.recaptcha

    if (
      email &&
      password1 &&
      password1 === password2 &&
      firstName &&
      lastName &&
      companyName &&
      terms &&
      recaptcha
    ) {
      this.registerWithEmail(email, password1, firstName, lastName, companyName)
    } else {
      if (!email) {
        errorEmail = t('auth.register.error.emailIsRequired')
      }
      if (!password1) {
        errorPass1 = t('auth.register.error.passwordIsRequired')
      }
      if (!password2) {
        errorPass2 = t('auth.register.error.password2IsRequired')
      }
      if (password1 !== password2) {
        // errorPass1 = t('auth.register.error.passwordDoesntMatch')
        errorPass2 = t('auth.register.error.passwordDoesntMatch')
      }
      if (!firstName) {
        errorFirstName = t('auth.register.error.firstNameIsRequired')
      }
      if (!lastName) {
        errorLastName = t('auth.register.error.lastNameIsRequired')
      }
      if (!companyName) {
        errorCompanyName = t('auth.register.error.companyNameIsRequired')
      }
      if (!terms) {
        errorTerms = t('auth.register.error.terms')
      }
      if (!recaptcha) {
        errorRecaptcha = t('auth.register.error.recaptchaIsRequired')
      }
      this.setState({
        ...this.state,
        errorPass1: errorPass1,
        errorPass2: errorPass2,
        errorEmail: errorEmail,
        errorFirstName: errorFirstName,
        errorLastName: errorLastName,
        errorCompanyName: errorCompanyName,
        errorTerms: errorTerms,
        errorRecaptcha: errorRecaptcha,
        errorGlobal: null
      })
    }
  }

  registerWithEmail = (email, password, firstName, lastName, companyName) => {
    const { t } = this.props

    this.setState({ ...this.state, loading: true })
    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        const currentUser = firebaseAuth.currentUser
        currentUser
          .sendEmailVerification()
          .then(() => {
            const displayName = firstName + ' ' + lastName
            currentUser
              .updateProfile({
                displayName: displayName
              })
              .catch(error => {
                this.setState({
                  ...this.state,
                  errorGlobal: error,
                  loading: false
                })
              })
          })
          .catch(error => {
            this.setState({ ...this.state, errorGlobal: error, loading: false })
          })
        return currentUser.getIdToken()
      })
      .then(idToken => {
        const customClaimData = {
          userToken: idToken,
          companyName: companyName
        }
        return cloudFunctionRequest(
          'auth/addCustomClaims',
          Method.POST,
          customClaimData
        )
      })
      .then(result => {
        return firebaseAuth.currentUser.getIdToken(true)
      })
      .then(res => {
        this.setState({ ...this.state, isRegistred: true, loading: false })
        return firebaseAuth.signOut()
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/invalid-email':
            this.setState({
              ...this.state,
              errorEmail: t('auth.register.error.invalidEmailFormat'),
              errorGlobal: null,
              loading: false
            })
            break
          case 'auth/weak-password':
            this.setState({
              ...this.state,
              errorPass1: t('auth.register.error.weakPassword'),
              errorPass2: t('auth.register.error.weakPassword'),
              errorGlobal: null,
              loading: false
            })
            break
          case 'auth/email-already-in-use':
            this.setState({
              ...this.state,
              errorEmail: t('auth.register.error.emailInUse'),
              errorGlobal: null,
              loading: false
            })
            break
          case 'auth/too-many-requests':
            this.setState({
              ...this.state,
              errorGlobal: t('auth.register.error.tooManyRequests'),
              loading: false
            })
            break
          default:
            this.setState({
              ...this.state,
              errorGlobal: error.message,
              loading: false
            })
        }
      })
  }

  validatePasswords = () => {
    const { t } = this.props

    const pass1 = document.getElementById('password1').value
    const pass2 = document.getElementById('password2').value

    let errorPass1, errorPass2

    if (pass1 !== pass2) {
      // errorPass1 = t('auth.register.error.passwordDoesntMatch')
      errorPass2 = t('auth.register.error.passwordDoesntMatch')
    } else {
      errorPass1 = ''
      errorPass2 = ''
    }
    this.setState({
      ...this.state,
      errorPass1: errorPass1,
      errorPass2: errorPass2,
      errorGlobal: null
    })
  }

  onChangeCaptcha = value => {
    const { t } = this.props
    const recaptchaValue = recaptchaRef.current.getValue()
    if (value === recaptchaValue) {
      this.setState({ ...this.state, recaptcha: true, errorRecaptcha: null })
    } else {
      this.setState({
        ...this.state,
        recaptcha: false,
        errorRecaptcha: t('auth.register.error.recaptchaIsRequired')
      })
    }
  }

  handlePasswordChange = (name, password) => {
    this.setState({ [name]: password }, this.validatePasswords())
  }

  render() {
    const { t } = this.props

    return (
      <div className='content'>
        {this.state.isAuthentificated && (
          <Redirect
            path='/'
            to='/company/undefined/calendar/undefined/dashboard'
          />
        )}
        <Grid>
          <Row>
            <Col xs={0} sm={1} md={2} />
            <Col xs={12} sm={8} md={8}>
              {this.state.isRegistred && (
                <Message
                  icon='pe-7s-like2'
                  title={t('auth.register.registrationSuccessful')}
                  text={t('auth.register.pleaseVerifyYourEmail')}
                />
              )}
              {!this.state.isRegistred && (
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={
                          this.state.errorFirstName ? 'error' : null
                        }
                      >
                        <FormInputs
                          ncols={['col-md-12']}
                          value=''
                          proprieties={[
                            {
                              label: t('auth.register.firstName'),
                              type: 'text',
                              id: 'firstName',
                              name: 'firstName',
                              bsClass: 'form-control',
                              defaultValue: '',
                              placeholder: t(
                                'auth.register.pleaseTypeYourFirstName'
                              ),
                              disabled: false
                            }
                          ]}
                        />
                        {this.state.errorFirstName && (
                          <HelpBlock>{this.state.errorFirstName}</HelpBlock>
                        )}
                      </FormGroup>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={
                          this.state.errorLastName ? 'error' : null
                        }
                      >
                        <FormInputs
                          ncols={['col-md-12']}
                          value=''
                          proprieties={[
                            {
                              label: t('auth.register.lastName'),
                              type: 'text',
                              id: 'lastName',
                              name: 'lastName',
                              bsClass: 'form-control',
                              defaultValue: '',
                              placeholder: t(
                                'auth.register.pleaseTypeYourLastName'
                              ),
                              disabled: false
                            }
                          ]}
                        />
                        {this.state.errorLastName && (
                          <HelpBlock>{this.state.errorLastName}</HelpBlock>
                        )}
                      </FormGroup>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={
                          this.state.errorCompanyName ? 'error' : null
                        }
                      >
                        <FormInputs
                          ncols={['col-md-12']}
                          value=''
                          proprieties={[
                            {
                              label: t('auth.register.companyName'),
                              type: 'text',
                              id: 'companyName',
                              name: 'companyName',
                              bsClass: 'form-control',
                              defaultValue: '',
                              placeholder: t(
                                'auth.register.pleaseTypeCompanyName'
                              ),
                              disabled: false
                            }
                          ]}
                        />
                        {this.state.errorCompanyName && (
                          <HelpBlock>{this.state.errorCompanyName}</HelpBlock>
                        )}
                      </FormGroup>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={
                          this.state.errorCountry ? 'error' : null
                        }
                      >
                        <ControlLabel>
                          {t('auth.register.country')}
                        </ControlLabel>
                        <FormControl
                          componentClass='select'
                          name='country'
                          placeholder=''
                        >
                          <option value='eu'>{t('auth.register.eu')}</option>
                          <option value='others'>
                            {t('auth.register.others')}
                          </option>
                        </FormControl>
                        {this.state.errorCountry && (
                          <HelpBlock>{this.state.errorCountry}</HelpBlock>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={this.state.errorEmail ? 'error' : null}
                      >
                        <FormInputs
                          ncols={['col-md-12']}
                          proprieties={[
                            {
                              label: t('auth.register.email'),
                              type: 'email',
                              id: 'email',
                              name: 'email',
                              bsClass: 'form-control',
                              defaultValue: '',
                              placeholder: t('auth.register.pleaseEnterEmail'),
                              disabled: false
                            }
                          ]}
                        />
                        {this.state.errorEmail && (
                          <HelpBlock>{this.state.errorEmail}</HelpBlock>
                        )}
                      </FormGroup>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={this.state.errorPass1 ? 'error' : null}
                        onChange={(e) => this.handlePasswordChange('password1', e.target.value)}
                      >
                        <ControlLabel>
                          {t('auth.register.password')}
                        </ControlLabel>
                        <FormInputs
                          ncols={['col-md-12']}
                          proprieties={[
                            {
                              type: 'password',
                              id: 'password1',
                              name: 'password1',
                              bsClass: 'form-control',
                              defaultValue: '',
                              autoComplete: 'off',
                              disabled: false
                            }
                          ]}
                        />
                        <ReactPasswordStrength
                          password={this.state.password1}
                          minLength={5}
                          style={{ marginTop: -5 }}
                          minScore={2}
                          scoreWords={[
                            t('auth.register.passwordScore.weak'),
                            t('auth.register.passwordScore.okay'),
                            t('auth.register.passwordScore.good'),
                            t('auth.register.passwordScore.strong'),
                            t('auth.register.passwordScore.stronger')
                          ]}
                        />
                        {this.state.errorPass1 && (
                          <HelpBlock>{this.state.errorPass1}</HelpBlock>
                        )}
                      </FormGroup>
                      <FormGroup
                        controlId='formBasicText'
                        validationState={this.state.errorPass2 ? 'error' : null}
                        onChange={(e) => this.handlePasswordChange('password2', e.target.value)}
                        style={{ marginTop: -38 }}
                      >
                        <ControlLabel>
                          {t('auth.register.repeatPassword')}
                        </ControlLabel>
                        <FormInputs
                          ncols={['col-md-12']}
                          proprieties={[
                            {
                              type: 'password',
                              id: 'password2',
                              name: 'password2',
                              bsClass: 'form-control',
                              defaultValue: '',
                              autoComplete: 'off',
                              disabled: false
                            }
                          ]}
                        />
                        {this.state.errorPass2 && (
                          <HelpBlock>{this.state.errorPass2}</HelpBlock>
                        )}
                      </FormGroup>
                      <FormGroup
                        validationState={
                          this.state.errorRecaptcha ? 'error' : null
                        }
                      >
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey='6LfE3ZoUAAAAAMU0GDaDc_fXD8dWytOlTETgt74j'
                          onChange={this.onChangeCaptcha}
                        />
                        {this.state.errorRecaptcha && (
                          <HelpBlock>{this.state.errorRecaptcha}</HelpBlock>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup
                    controlId='formBasicText'
                    validationState={this.state.errorTerms ? 'error' : null}
                  >
                    <FormInputs
                      ncols={['col-md-12']}
                      proprieties={[
                        {
                          label: t('auth.register.agreeWithTermsOfUse'),
                          type: 'checkbox',
                          id: 'terms',
                          name: 'terms',
                          bsClass: 'form-control',
                          defaultValue: 'agree',
                          placeholder: '',
                          disabled: false,
                          style: { width: 14, height: 14}
                        }
                      ]}
                    />
                    {this.state.errorTerms && (
                      <HelpBlock>{this.state.errorTerms}</HelpBlock>
                    )}
                  </FormGroup>

                  {this.state.errorGlobal && (
                    <FormGroup
                      controlId='formBasicText'
                      validationState='error'
                    >
                      <HelpBlock>{this.state.errorGlobal}</HelpBlock>
                    </FormGroup>
                  )}
                  <Button bsStyle='success' pullRight fill type='submit'>
                    {this.state.loading ? (
                      <Loading
                        type='spin'
                        color='white'
                        height='20'
                        width='20'
                      />
                    ) : (
                      t('auth.register.createAccount')
                    )}
                  </Button>
                  <Link to='/auth/login'>
                    <Button simple pullRight>
                      {t('auth.register.alreadyHaveAccount')}
                    </Button>
                  </Link>
                  <div className='clearfix' />
                </form>
              )}
            </Col>
            <Col xs={0} sm={1} md={2} />
          </Row>
        </Grid>
      </div>
    )
  }
}

export default translate('translations')(Login)
