import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/functions'

import { firebaseConfig } from './config'

export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const firebaseDb = firebase.firestore()
export const storageRef = firebase.storage()
export const firebaseAuth = firebase.auth()
export const firebaseAuthClass = firebase.auth
export const firebaseFunctions = firebase.functions()

const settings = { timestampsInSnapshots: true }
firebaseDb.settings(settings)
