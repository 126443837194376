import { defineFileAction, ChonkyIconName } from 'chonky';

export const RenameFolderAction = defineFileAction({
  id: 'rename_folder',
  requiresSelection: true,
  fileFilter: file => file && file.isDir,
  button: {
    name: 'Rename Folder',
    group: 'Options',
    contextMenu: true,
    icon: ChonkyIconName.folder
  },
});

export const RenameFileAction = defineFileAction({
  id: 'rename_file',
  requiresSelection: true,
  fileFilter: file => file && !file.isDir,
  button: {
    name: 'Rename File',
    group: 'Options',
    contextMenu: true,
    icon: ChonkyIconName.file
  },
});

export const DeleteAction = defineFileAction({
  id: 'delete',
  requiresSelection: true,
  button: {
    name: 'Delete',
    group: 'Options',
    contextMenu: true,
    icon: ChonkyIconName.trash
  },
});

export const PreviewFileAction = defineFileAction({
  id: 'preview_file',
  requiresSelection: true,
  fileFilter: file => file && !file.isDir,
  button: {
    name: 'Preview File',
    group: 'Options',
    contextMenu: true,
    icon: ChonkyIconName.openFiles
  },
});

export const GetURLAction = defineFileAction({
  id: 'get_url',
  requiresSelection: true,
  fileFilter: file => file && !file.isDir,
  button: {
    name: 'Get URL',
    group: 'Options',
    contextMenu: true,
    icon: ChonkyIconName.download
  },
});