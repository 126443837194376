import React, { useState, useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tabs,
  Tab
} from 'react-bootstrap'
import { Card } from '../../components/Card/Card'
import Button from '../../components/CustomButton/CustomButton'
import Loading from '../../components/Loading/Loading'
import ReactQuill from 'react-quill'
import { firebaseDb as db } from '../../firebase'
import ReactTooltip from 'react-tooltip'
import { translate } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';

// Translation model
import { read } from '../../helpers/translationModel/read'
import { write } from '../../helpers/translationModel/write'

// Redux
import { useSelector } from 'react-redux'
import FileSelector from '../FileStorage/Parts/FileSelector'

const CalHeaderBottom = props => {
  const {
    activeCompany,
    activeCalendar,
    calendarLang,
    calendarLangPrimary
  } = useSelector(state => state)

  const [loading, setLoading] = useState(true)
  const [type, setType] = useState('hide-and-seek')
  const [modules, setModules] = useState()

  const [newsletterDisplay, setNewsletterDisplay] = useState(true)
  const [newsletterLinkLabel, setNewsletterLinkLabel] = useState('default')
  const [newsletterLinkUrl, setNewsletterLinkUrl] = useState('default')
  const [newsletterText, setNewsletterText] = useState(
    'Loading, please wait ....'
  )

  const [reminderDisplay, setReminderDisplay] = useState(true)
  const [reminderLinkLabel, setReminderLinkLabel] = useState('default')
  const [reminderLinkUrl, setReminderLinkUrl] = useState('default')
  const [reminderText, setReminderText] = useState('Loading, please wait ....')
  const [counterText, setCounterText] = useState('Loading, please wait ....')

  const [sponsorDisplay, setSponsorDisplay] = useState(false)
  const [sponsors, setSponsors] = useState({ 0: { logo: '', text: '', linkLabel: '', linkUrl: '', default: true } })

  useEffect(() => {
    activeCompany && activeCalendar && calendarLang && loadFromDb()
  }, [activeCompany, activeCalendar, calendarLang])

  const loadFromDb = () => {
    activeCompany &&
      activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          // Replace data with translations
          data = await read(
            activeCompany,
            activeCalendar,
            calendarLangPrimary,
            calendarLang,
            'settings/grid',
            data
          )

          const modules = {
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              [('bold', 'italic', 'underline', 'strike', 'blockquote')],
              [{ align: [] }],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
              ],
              ['link', 'image'],
              [{ color: Object.values(data.colors) }, { color: [] }],
              ['clean']
            ]
          }

          if (data.newsletter) {
            setNewsletterText(data.newsletter.text)
            setNewsletterDisplay(data.newsletter.display)
            setNewsletterLinkLabel(data.newsletter.linkLabel)
            setNewsletterLinkUrl(data.newsletter.linkUrl)
          }

          setReminderText(data.reminder && data.reminder.text)
          setReminderDisplay(data.reminder && data.reminder.display)
          setReminderLinkLabel(data.reminder && data.reminder.linkLabelReminder)
          setReminderLinkUrl(data.reminder && data.reminder.linkUrlReminder)
          setCounterText(data.counterText)
          setType(data.type)
          setModules(modules)
          setLoading(false)
          if (data.sponsors) {
            setSponsorDisplay(data.sponsorDisplay)
            setSponsors(data.sponsors)
          } else {
            setSponsors({ 0: { ...sponsors[0], default: false } })
          }
        })
        .catch(error => {
          console.log('error', error)
          notificationOpen('error', error)
        })
  }

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'not saved! Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  const handleSubmitCounter = event => {
    event.preventDefault()
    const quillEditor = document.querySelector('#counter .ql-editor').innerHTML

    let udpateContent = {
      counterText: quillEditor
    }
    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        udpateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .then(res => { })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const quillEditor = document.querySelector('#newsletter .ql-editor')
      .innerHTML

    let udpateContent = {
      newsletter: {
        text: quillEditor,
        display: newsletterDisplay === 'true' || newsletterDisplay === true,
        linkLabel: newsletterLinkLabel,
        linkUrl: newsletterLinkUrl
      }
    }

    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        udpateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const handleSubmitReminder = event => {
    event.preventDefault()
    const quillEditor = document.querySelector('#reminder .ql-editor').innerHTML

    let udpateContent = {
      reminder: {
        text: quillEditor,
        display: reminderDisplay,
        linkLabelReminder: reminderLinkLabel,
        linkUrlReminder: reminderLinkUrl
      }
    }
    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        udpateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const handleSubmitSponsors = event => {
    event.preventDefault()

    let updateContent = {
      sponsors: { ...sponsors },
      sponsorDisplay
    }
    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        updateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const handleChangeQuillCounter = (e) => { setCounterText(e) }
  const handleChangeQuillNewsletter = (e) => { setNewsletterText(e) }
  const handleChangeQuillReminder = (e) => { setReminderText(e) }
  const handleChangeQuillSponsor = (e, index) => { !sponsors[index].default && setSponsors({ ...sponsors, [index]: { ...sponsors[index], text: e } }) }
  const { t } = props

  return (
    <>
      <Row
        className='show-grid'
        float='center'
        style={loading ? { display: 'block' } : { display: 'none' }}
      >
        <Col xs={12} xsOffset={6} valign='middle'>
          <Loading type='spin' color='green' />
        </Col>
      </Row>

      <Grid
        fluid
        style={!loading ? { visibility: 'visible' } : { visibility: 'hidden' }}
      >
        <Row>
          {['hide-and-seek', 'memory-game'].includes(type) && (
            <Col md={6}>
              <Card
                title={t('calCounterAndNewsletter.counter')}
                content={
                  <form onSubmit={handleSubmitCounter}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('counterAndNewsletter.counterText')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calCounterAndNewsletter.textOfCounter')}
                      </ControlLabel>
                      <ReactQuill
                        value={counterText}
                        modules={modules}
                        id='counter'
                        name='content'
                        onChange={handleChangeQuillCounter}
                      />
                    </FormGroup>

                    <Button bsStyle='info' pullRight fill type='submit'>
                      {t('calCounterAndNewsletter.updateCounter')}
                    </Button>
                    <div className='clearfix' />
                  </form>
                }
              />
            </Col>
          )}

          {type === 'advent' && (
            <Col md={6}>
              <Card
                title={t('calCounterAndNewsletter.reminder')}
                content={
                  <form onSubmit={handleSubmitReminder}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel>
                            <div className='checkbox'>
                              <input
                                type='checkbox'
                                id='displayReminder'
                                name='displayReminder'
                                checked={reminderDisplay}
                                onChange={e =>
                                  setReminderDisplay(e.target.checked)
                                }
                              />
                              <label htmlFor='displayReminder'>
                                {t('calCounterAndNewsletter.displayReminder')}
                              </label>
                            </div>
                          </ControlLabel>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('counterAndNewsletter.displayReminder')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 0 0 5px',
                              fontSize: '15px'
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: reminderDisplay === true ? 'block' : 'none'
                      }}
                    >
                      <FormGroup>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('counterAndNewsletter.text')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calCounterAndNewsletter.textOfReminder')}
                        </ControlLabel>
                        <ReactQuill
                          value={reminderText}
                          modules={modules}
                          id='reminder'
                          name='contentReminder'
                          onChange={handleChangeQuillReminder}
                        />
                      </FormGroup>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('counterAndNewsletter.linkLabel')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calCounterAndNewsletter.reminderLinkLabel')}
                            </ControlLabel>
                            <FormControl
                              type='text'
                              name='linkLabelReminder'
                              value={reminderLinkLabel}
                              onChange={e => {
                                setReminderLinkLabel(e.target.value)
                              }}
                              id='linkLabelReminder'
                              placeholder='select'
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('counterAndNewsletter.linkUrl')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calCounterAndNewsletter.reminderLinkUrl')}
                            </ControlLabel>
                            <FormControl
                              type='text'
                              name='linkUrlReminder'
                              value={reminderLinkUrl}
                              onChange={e => {
                                setReminderLinkUrl(e.target.value)
                              }}
                              id='linkUrlReminder'
                              placeholder={t(
                                'calCounterAndNewsletter.urlToTargetingReminderPage'
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <Button bsStyle='info' pullRight fill type='submit'>
                      {t('calCounterAndNewsletter.updateReminder')}
                    </Button>
                    <div className='clearfix' />
                  </form>
                }
              />
            </Col>
          )}

          <Col md={6}>
            <Card
              title={t('calCounterAndNewsletter.newsletter')}
              content={
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <ControlLabel>
                          <div className='checkbox'>
                            <input
                              type='checkbox'
                              id='newsletterDisplay'
                              name='display'
                              checked={newsletterDisplay}
                              onChange={e =>
                                setNewsletterDisplay(e.target.checked)
                              }
                            />
                            <label htmlFor='newsletterDisplay'>
                              {t('calCounterAndNewsletter.displayNewsLetter')}
                            </label>
                          </div>
                        </ControlLabel>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('counterAndNewsletter.display')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 0 0 5px',
                            fontSize: '15px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: newsletterDisplay === true ? 'block' : 'none'
                    }}
                  >
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('counterAndNewsletter.text')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calCounterAndNewsletter.textOfNewsletter')}
                      </ControlLabel>
                      {modules && (
                        <ReactQuill
                          value={newsletterText}
                          modules={modules}
                          id='newsletter'
                          name='content'
                          onChange={handleChangeQuillNewsletter}
                        />
                      )}
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('counterAndNewsletter.linkLabel')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calCounterAndNewsletter.newsletterLinkLabel')}
                          </ControlLabel>
                          <FormControl
                            type='text'
                            name='linkLabel'
                            id='linkLabel'
                            value={newsletterLinkLabel}
                            onChange={e =>
                              setNewsletterLinkLabel(e.target.value)
                            }
                            placeholder={t(
                              'calCounterAndNewsletter.newsletterLinkLabel'
                            )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('counterAndNewsletter.linkUrl')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calCounterAndNewsletter.newsletterLinkUrl')}
                          </ControlLabel>
                          <FormControl
                            type='text'
                            name='linkUrl'
                            value={newsletterLinkUrl}
                            onChange={e => setNewsletterLinkUrl(e.target.value)}
                            id='linkUrl'
                            placeholder={t(
                              'calCounterAndNewsletter.urlToTargetingPage'
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <Button bsStyle='info' pullRight fill type='submit'>
                    {t('calCounterAndNewsletter.updateNewsletter')}
                  </Button>
                  <div className='clearfix' />
                </form>
              }
            />
          </Col>
          <Col md={6}>
            <Card
              title={t('calCounterAndNewsletter.sponsors')}
              content={
                <form onSubmit={handleSubmitSponsors}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <ControlLabel>
                          <div className='checkbox'>
                            <input
                              type='checkbox'
                              checked={sponsorDisplay}
                              id='sponsorDisplay'
                              onChange={e =>
                                setSponsorDisplay(e.target.checked)
                              }
                            />
                            <label htmlFor='sponsorDisplay'>
                              {t('calCounterAndNewsletter.displaySponsors')}
                            </label>
                          </div>
                        </ControlLabel>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('counterAndNewsletter.display')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 0 0 5px',
                            fontSize: '15px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Tabs
                    defaultActiveKey="sponsor1"
                    className="mb-3"
                    style={{
                      display: sponsorDisplay === true ? 'block' : 'none'
                    }}
                    onSelect={(target) => {
                      if (!target) {
                        setSponsors({
                          ...sponsors, [Object.keys(sponsors).length]: { logo: '', text: '', linkLabel: '', linkUrl: '', default: false }
                        })
                      }
                    }}
                  >
                    {sponsors && Object.keys(sponsors).map((index) => {
                      const sponsor = sponsors[index]
                      return (
                        <Tab eventKey={`sponsor${+index + 1}`} style={{ paddingTop: 10 }} title={
                          <div style={{ color: '#555' }}>
                            <b>{`Sponsor ${+index + 1} `}</b>
                            <FontAwesomeIcon
                              icon={faTrash}
                              size='lg'
                              onClick={() => {
                                const indexes = [...Object.keys(sponsors)].filter(val => val !== index)
                                const newSponsors = {}
                                indexes.forEach((item, index) => newSponsors[`${index}`] = sponsors[`${item}`])
                                setSponsors(newSponsors)
                              }}
                            />
                          </div>}>
                          <div>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calCounterAndNewsletter.logoOfSponsor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>
                                {t('calCounterAndNewsletter.logoOfSponsor')}
                              </ControlLabel>
                              <FileSelector
                                type='image'
                                url={sponsor?.logo || ''}
                                disableDragAndDropProvider={true}
                                activeCompany={activeCompany}
                                onSelect={(file) => setSponsors({ ...sponsors, [index]: { ...sponsors[index], logo: file.url, default: false } })}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calCounterAndNewsletter.textOfSponsor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>
                                {t('calCounterAndNewsletter.textOfSponsor')}
                              </ControlLabel>
                              {modules && (
                                <ReactQuill
                                  value={sponsor?.text || ''}
                                  // defaultValue={sponsor?.text || ''}
                                  id='sponsor'
                                  modules={modules}
                                  onChange={(e) => { handleChangeQuillSponsor(e, index) }}
                                />
                              )}
                            </FormGroup>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <i
                                    className='pe-7s-help1 text-success'
                                    data-tip={t('counterAndNewsletter.linkLabel')}
                                    style={{
                                      position: 'relative',
                                      top: '1px',
                                      padding: '5px 5px 0 0',
                                      fontSize: '15px'
                                    }}
                                  />
                                  <ControlLabel>
                                    {t('calCounterAndNewsletter.sponsorLinkLabel')}
                                  </ControlLabel>
                                  <FormControl
                                    type='text'
                                    name='sponsorLinkLabel'
                                    value={sponsors[index]?.linkLabel || ''}
                                    onChange={e => {
                                      setSponsors({ ...sponsors, [index]: { ...sponsors[index], linkLabel: e.target.value, default: false } })
                                    }}
                                    id='sponsorLinkLabel'
                                    placeholder='select'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <i
                                    className='pe-7s-help1 text-success'
                                    data-tip={t('counterAndNewsletter.linkUrl')}
                                    style={{
                                      position: 'relative',
                                      top: '1px',
                                      padding: '5px 5px 0 0',
                                      fontSize: '15px'
                                    }}
                                  />
                                  <ControlLabel>
                                    {t('calCounterAndNewsletter.sponsorLinkUrl')}
                                  </ControlLabel>
                                  <FormControl
                                    type='text'
                                    name='sponsorLinkUrl'
                                    value={sponsors[index]?.linkUrl || ''}
                                    onChange={e => {
                                      setSponsors({ ...sponsors, [index]: { ...sponsors[index], linkUrl: e.target.value, default: false } })
                                    }}
                                    id='sponsorLinkUrl'
                                    placeholder={t(
                                      'calCounterAndNewsletter.urlToTargetingReminderPage'
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </Tab>
                      )
                    })}
                    <Tab disabled={sponsors && Object.keys(sponsors).length >= 15} title={<FontAwesomeIcon
                      icon={faCirclePlus}
                      size='lg'
                      color='#555'
                    />} />
                  </Tabs>
                  <Button bsStyle='info' pullRight fill type='submit'>
                    {t('calCounterAndNewsletter.updateSponsors')}
                  </Button>
                  <div className='clearfix' />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
      <ReactTooltip place='top' type='dark' effect='solid' />
    </>
  )
}

export default translate('translations')(CalHeaderBottom)
