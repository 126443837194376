import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Alert } from 'react-bootstrap'
import CompanyTab from './Parts/CompanyTab'
import _ from 'lodash'
import AdminTab from './Parts/AdminTab'
import { firebaseAuth } from '../../firebase'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { admin: false }
  }
  createLegend(json) {
    var legend = []
    for (var i = 0; i < json['names'].length; i++) {
      var type = 'fa fa-circle text-' + json['types'][i]
      legend.push(<i className={type} key={i} />)
      legend.push(' ')
      legend.push(json['names'][i])
    }
    return legend
  }

  componentDidMount() {
    firebaseAuth.currentUser
      .getIdTokenResult()
      .then(idTokenResult => { this.setState({ admin: idTokenResult.claims.admin }) })
  }

  render() {
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <Alert bsStyle="danger" style={{ width: 'fit-content' }}>
                Calendar can be created after selecting a company
              </Alert>
            </Col>
          </Row>
          <Row>
            {this.props.companiesData &&
              _.map(this.props.companiesData, (companiesData, index) => {
                return (
                  <CompanyTab
                    {...this.props}
                    index={index}
                    companyName={companiesData.name}
                    calendarsLimit={companiesData.calendarsLimit}
                    validUntil={companiesData.validUntil}
                    numCalendars={_.size(companiesData.calendars) + _.size(companiesData.disabledCalendars)}
                    key={index}
                  />
                )
              })}
            {
              this.state.admin && <AdminTab {...this.props} />
            }
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeActiveCompany: activeCompany => dispatch({ type: 'CHANGE_ACTIVE_COMPANY', value: activeCompany })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
