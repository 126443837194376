import { storageRef as storage } from '../../../firebase'
import cloudFunctionRequest, { Method } from '../../../helpers/cloudFunction';

const THUMB_PREFIX = '.thumbnails/';

export const getFiles = async (path) => {
  var fileList = []
  var folderList = []
  const storageRef = storage.ref(path)
  // Now we get the references of these images
  await storageRef.listAll().then(async function (result) {
    const filePromises = []
    result.items.forEach(async function (fileRef) {
      // Don't display .keep files
      if (fileRef.name === '.keep') {
        return
      }
      filePromises.push(
        fileRef.getMetadata().then(async (metadata) => {
          const validType = metadata.contentType.startsWith('image')
          const thumbUrl = validType && await getThumbnailURL(fileRef.fullPath)
          // And finally display them
          fileList.push(
            {
              id: fileRef.fullPath,
              name: fileRef.name,
              modDate: new Date(metadata.updated),
              metadata: metadata,
              thumbnailUrl: thumbUrl,
            }
          )
        }))
    });
    await Promise.all(filePromises)
    result.prefixes.forEach(function (folderRef) {
      if (folderRef.fullPath.includes('.thumbnails')) {
        return
      }
      // And finally display them
      folderList.push(
        {
          id: folderRef.fullPath,
          name: folderRef.name,
          isDir: true
        }
      )
    });
  }).catch(function (error) {
    // Handle any errors
    console.log(error)
  });
  return { files: fileList, folders: folderList }
}

export const createFolder = async (path, folderName, onExist) => {
  const filesFromFirebase = await getFiles(path)
  const folders = filesFromFirebase.folders
  if (folders.find(folder => folder.name === folderName)) {
    onExist('error', `Folder ${folderName} exists`)
    return
  }
  const keepFileRef = storage.ref(path + '/' + folderName + '/.keep')
  return keepFileRef.putString('', 'base64')
}

export const findAllFiles = async (path) => {
  const fileList = []
  const storageRef = storage.ref(path)
  await storageRef.listAll().then(async function (result) {
    result.items.forEach(function (fileRef) {
      // And finally display them
      fileList.push(fileRef)
    });
    for (const folderRef of result.prefixes) {
      const subFiles = await findAllFiles(folderRef.fullPath)
      subFiles && fileList.push(...subFiles)
    }
  }).catch(function (error) {
    // Handle any errors
    console.log(error)
  });
  return fileList
}

export const renameFolder = (token, path, source, dest) => {
  return new Promise(async (resolve, reject) => {
    const postData = {
      userToken: token,
      sourceFolder: `${path}/${source}`,
      destinationFolder: `${path}/${dest}`
    }
    await cloudFunctionRequest(
      'renameFolder',
      Method.POST,
      postData
    ).then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const renameFile = async (token, path, source, dest) => {
  return new Promise(async (resolve, reject) => {
    const postData = {
      userToken: token,
      sourceFile: `${path}/${source}`,
      destinationFile: `${path}/${dest}`
    }
    await cloudFunctionRequest(
      'renameFile',
      Method.POST,
      postData
    ).then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const moveFiles = async (token, files, dest) => {
  return new Promise(async (resolve, reject) => {
    const postData = {
      userToken: token,
      sourceFiles: files,
      destinationFolder: `${dest}`
    }
    await cloudFunctionRequest(
      'moveFiles',
      Method.POST,
      postData
    ).then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const deleteFiles = async (files) => {
  const promises = []
  for (const file of files) {
    if (file.isDir) {
      const foundFiles = await findAllFiles(file.id)
      foundFiles.forEach((fileRef) => {
        promises.push(fileRef.delete())
      })
    } else {
      promises.push(storage.ref(file.id).delete())
    }
  }
  await Promise.all(promises)
}

export const uploadFiles = async (path, files) => {
  const promises = []
  files.forEach((file) => {
    promises.push(storage.ref(`${path}/${file.name}`).put(file))
  })
  await Promise.all(promises)
}

export const initializeStorage = (activeCompany) => {
  return new Promise(async (resolve, reject) => {
    const storageFile = storage.ref('company/' + activeCompany + '/storage')
    storageFile.listAll().then(function (result) {
      if (result.items.length === 0 && result.prefixes.length === 0) {
        createFolder('company/' + activeCompany, 'storage', reject).then(() => resolve())
      } else {
        resolve()
      }
    })
  })
}

export const getDownloadURL = (path) => {
  const storageFile = storage.ref(path)
  return storageFile.getDownloadURL()
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export const getThumbnailURL = (filePath) => {
  const storagePath = filePath.substring(0, filePath.indexOf('/storage') + 9)
  const thumbFileName = replaceAll(filePath.replace(storagePath, ''), '/', '_');
  const thumbFilePath = `${storagePath}${THUMB_PREFIX}${thumbFileName}.png`;
  const thumbFile = storage.ref(thumbFilePath)
  return thumbFile.getDownloadURL().catch(() => '')
}

export const getMetadata = (path) => {
  const storageFile = storage.ref(path)
  return storageFile.getMetadata()
}
