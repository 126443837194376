const initialState = {
  companiesData: null,
  calendarLang: 'en'
}

const reducer = (state = initialState, action) => {
  if (action.type === 'CHANGE_ACTIVE_COMPANY') {
    return {
      ...state,
      activeCompany: action.value
    }
  } else if (action.type === 'CHANGE_ACTIVE_CALENDAR') {
    return {
      ...state,
      activeCalendar: action.value
    }
  } else if (action.type === 'CHANGE_COMPANIES_DATA') {
    return {
      ...state,
      companiesData: action.value
    }
  } else if (action.type === 'CHANGE_CALENDAR_LANG') {
    return {
      ...state,
      calendarLang: action.value.lang,
      calendarLangPrimary: action.value.primary
    }
  } else if (action.type === 'CHANGE_CALENDAR_LANGS') {
    return {
      ...state,
      calendarLangs: action.value
    }
  }
  return state
}

export default reducer
