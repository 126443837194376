import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router'
import Settings from './Tabs/Settings'
import Builder from './Tabs/Builder'
import { firebaseDb as db } from '../../../firebase'
import { translate } from 'react-i18next'
import moment from 'moment'

import { ButtonGroup } from 'react-bootstrap'

// Redux
import { useSelector } from 'react-redux'

// Translation model
import { read } from '../../../helpers/translationModel/read'
import { write } from '../../../helpers/translationModel/write'

const FormBuilder = props => {
  const {
    activeCalendar,
    activeCompany,
    calendarLang,
    calendarLangPrimary
  } = useSelector(state => state)
  const [changedFormData, setChangedFormData] = useState()
  const [changedBuilderData, setChangedBuilderData] = useState()

  const PROMPT_MESSAGE =
    'You have unsaved changes, are you sure you want to leave?'
  const { t, selectedForm, modalData, calendarType } = props

  const [saved, setSaved] = useState(true)
  const [initialData, setInitialData] = useState()
  const [formError, setFormError] = useState()
  const [loading, setLoading] = useState(false)

  const defaultContentEditor = (calendarType !== 'memory-game') ? 'builder' : 'settings'
  const [contentEditor, setContentEditor] = useState(defaultContentEditor)
  const [dayData, setDayData] = useState()

  useEffect(() => {
    if (activeCompany && activeCalendar && selectedForm) {
      loadDataFromDB()
    }
  }, [activeCalendar, activeCompany, selectedForm, calendarLang])

  const handleChangeType = type => {
    setContentEditor(type)
  }

  const saveToDB = () => {
    if(formError) {
      props.notificationOpen("error", formError);
      return
    }
    let items = {
      data: JSON.stringify(changedBuilderData)
    }
    if (contentEditor === 'settings') {
      items = {
        start: changedFormData.start ? new Date(changedFormData.start) : '',
        stop: changedFormData.stop ? new Date(changedFormData.stop) : '',
        stopText: changedFormData.stopText,
        formSubmitClicks: changedFormData.formSubmitClicks,
        hubspotFormId: changedFormData.hubspotFormId,
        afterFormMessage: changedFormData.afterFormMessage,
        numberOfAttemptsText: changedFormData.numberOfAttemptsText,
        externalPageEnable: changedFormData.externalPageEnable,
        externalPageLink: changedFormData.externalPageLink,
        disableOtherBoxes: changedFormData.disableOtherBoxes,
        richContent: changedFormData.richContent,
        contentType: changedFormData.contentType,
        failsGreaterThan: changedFormData.failsGreaterThan,
        failsLessThan: changedFormData.failsLessThan,
      }
      if (changedFormData.articleImage) {
        items.image = changedFormData.articleImage
      }
    }
    setLoading(true)
    // udpateContent
    write(
      activeCompany,
      activeCalendar,
      calendarLangPrimary,
      calendarLang,
      'days/' + selectedForm,
      items,
      'days/x'
    )
      .then(res => {
        db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
          .update({ changeToPublish: true })
          .then(res => {
            if (contentEditor === 'settings') {
              const itemsClone = JSON.parse(JSON.stringify(items))
              delete itemsClone.data
              setDayData((prev) => ({ ...prev, ...itemsClone }))
            }
           })
          .catch(error => {
            console.log(error)
          })
        if (contentEditor === 'settings') {
          updateDaysGridDB()
        }
        //load content from db just for builder -> for modal view
        if (contentEditor === 'builder') {
          loadDataFromDB()
        }
        setLoading(false)
        setSaved(true)
        // set publish button
        const publishButton = document.getElementById('btn-publish')
        if (publishButton) {
          document.getElementById('btn-publish').className =
            'btn btn-xs btn-publish'
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const updateDaysGridDB = () => {
    const values = {
      ['days.' + selectedForm + '.color']: changedFormData.color,
      ['days.' + selectedForm + '.bgPos']: changedFormData.bgPos,
      ['days.' + selectedForm + '.start']: changedFormData.start,
      ['days.' + selectedForm + '.stop']: changedFormData.stop,
      ['days.' + selectedForm + '.stopText']: changedFormData.stopText,
      ['days.' +
        selectedForm +
        '.externalPageEnable']: changedFormData.externalPageEnable,
      ['days.' +
        selectedForm +
        '.externalPageLink']: changedFormData.externalPageLink,
    }
    const updateValues = {
      color: changedFormData.color,
      bgPos: changedFormData.bgPos,
      start: changedFormData.start,
      stop: changedFormData.stop,
      stopText: changedFormData.stopText,
      externalPageEnable: changedFormData.externalPageEnable,
      externalPageLink: changedFormData.externalPageLink,
    }
    if (changedFormData.icon) {
      values['days.' +
        selectedForm +
        '.customIcon'] = changedFormData.icon
      updateValues.customIcon = changedFormData.icon
    }
    if (changedFormData.contentIcon) {
      values['days.' +
        selectedForm +
        '.customIconContent'] = changedFormData.contentIcon
      updateValues.customIconContent = changedFormData.contentIcon
    }
    if (changedFormData.richContent) {
      values['days.' +
        selectedForm +
        '.customRichIconContent'] = changedFormData.richContent
      updateValues.customRichIconContent = changedFormData.richContent
    }
    if (changedFormData.contentType) {
      values['days.' +
        selectedForm +
        '.contentType'] = changedFormData.contentType
      updateValues.contentType = changedFormData.contentType
    }
    db.doc(
      'companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/settings/grid'
    )
      .update(values)
      .then(res => {
        props.updateGridData && props.updateGridData((prev) => ({ ...prev, [selectedForm]: {
          ...prev[selectedForm],
          ...updateValues
        }}))
        // props.handleLoadFromGrid()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const loadDataFromDB = formId => {
    if (formId == null) {
      formId = selectedForm
    }
    db.doc(
      'companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/days/' +
      formId
    )
      .get()
      .then(async querySnapshot => {
        let data = querySnapshot.data()

        // Replace data with translations
        data = await read(
          activeCompany,
          activeCalendar,
          calendarLangPrimary,
          calendarLang,
          'days/' + formId,
          data,
          'days/x'
        )
        data['formId'] = formId
        if (data.start) {
          data['start'] = moment(data.start.toDate()).format('YYYY-MM-DD')
        } else {
          data['start'] = null
        }
        if (data.stop && data.stop.seconds > 0) {
          data['stop'] = moment(data.stop.toDate()).format('YYYY-MM-DD')
        } else {
          data['stop'] = null
        }
        setDayData(data)
        const updatedData = data.data.length !== 0 ? JSON.parse(data.data) : []
        setInitialData(updatedData)
        modalData(updatedData)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  return (
    <div>
      <Prompt when={!saved} message={PROMPT_MESSAGE} />
      <ButtonGroup aria-label='Basic example'>
        {(calendarType !== 'memory-game' || (selectedForm && selectedForm.toString().includes('winning'))) && (<button
          style={{ margin: '0 0px 20px 0px' }}
          className={
            contentEditor === 'builder'
              ? 'btn btn-info selected'
              : 'btn btn-info'
          }
          onClick={() => handleChangeType('builder')}
        >
          {t('calDays.formBuilder.content')}
        </button>)}
        <button
          style={{ margin: '0 0px 20px -2px' }}
          className={
            contentEditor === 'settings'
              ? 'btn btn-info selected'
              : 'btn btn-info'
          }
          onClick={() => handleChangeType('settings')}
        >
          {t('calDays.formBuilder.settings')}
        </button>
      </ButtonGroup>
      <button
        onClick={saveToDB}
        style={{ margin: '0 0px 20px 10px' }}
        className={loading ? 'btn btn-disabled' : saved ? 'btn btn-success' : 'btn btn-warning'}
        disabled={loading}
      >
        {loading ? "Saving..." : saved ? 'Saved' : 'Save changes'}
      </button>
      {/* {contentEditor && contentEditor === 'settings' ? (
        <Settings
          contentEditor={contentEditor}
          gridData={props.gridData}
          daysGridDataColors={props.daysGridDataColors}
          daysGridData={props.daysGridData}
          calendarType={calendarType}
          selectedForm={props.selectedForm}
          handleChangeSaved={setSaved}
          handleChangeValues={setChangedFormData}
          dayData={dayData}
          notificationOpen={props.notificationOpen}
        />
      ) : (
        <Builder
          daysGridDataColors={props.daysGridDataColors}
          dayData={dayData}
          handleChangeSaved={setSaved}
          builderData={setChangedBuilderData}
          initialData={initialData}
          notificationOpen={props.notificationOpen}
        />
      )} */}
      {contentEditor && contentEditor === 'builder' && (calendarType !== 'memory-game' || (selectedForm && selectedForm.toString().includes('winning'))) && (
        <Builder
          contentEditor={contentEditor}
          daysGridDataColors={props.daysGridDataColors}
          dayData={dayData}
          handleChangeSaved={setSaved}
          builderData={setChangedBuilderData}
          initialData={initialData}
          notificationOpen={props.notificationOpen}
        /> 
      )}
      {(contentEditor && contentEditor === 'settings') && (
        <Settings
          contentEditor={contentEditor}
          gridData={props.gridData}
          daysGridDataColors={props.daysGridDataColors}
          daysGridData={props.daysGridData}
          calendarType={calendarType}
          selectedForm={props.selectedForm}
          handleChangeSaved={setSaved}
          handleChangeValues={setChangedFormData}
          dayData={dayData}
          notificationOpen={props.notificationOpen}
          setError={setFormError}
        />
      )}
    </div>
  )
}

export default translate('translations')(FormBuilder)
