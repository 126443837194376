import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from './../../components/Card/Card'
import Loading from '../../components/Loading/Loading'
import { translate } from 'react-i18next'
import { firebaseDb as db, storageRef as storage } from '../../firebase'
import { connect } from 'react-redux'
class CalImplementation extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    this.setState(
      {
        ...this.state,
        activeCompany: this.props.activeCompany,
        activeCalendar: this.props.activeCalendar
      },
      this.loadFavicon
    )
  }

  loadFavicon = () => {
    this.state.activeCompany &&
      this.state.activeCalendar &&
      db
        .doc(
          'companies/' +
          this.state.activeCompany +
          '/calendars/' +
          this.state.activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(res => {
          const data = res.data()
          let faviconUrl = data.faviconStorage || data.faviconUrl
          if (faviconUrl === 'removed') {
            faviconUrl = ''
          }
          this.setState({
            ...this.state,
            calendarName: data.calendarName,
            ogTitle: data.ogTitle,
            ogDescription: data.ogDescription,
            ogUrl: data.ogUrl,
            ogType: data.ogType,
            ogImage: data.ogImage,
            favicon: faviconUrl,
            loading: false
          })
        })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState(
        {
          ...this.state,
          activeCompany: nextProps.activeCompany,
          activeCalendar: nextProps.activeCalendar
        },
        this.loadFavicon
      )
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className='content'>
        <Grid fluid>
          <Row
            className='show-grid'
            float='center'
            style={
              this.state.loading ? { display: 'block' } : { display: 'none' }
            }
          >
            <Col xs={12} xsOffset={6} valign='middle'>
              <Loading type='spin' color='green' />
            </Col>
          </Row>
          <Row
            style={
              !this.state.loading
                ? { visibility: 'visible' }
                : { visibility: 'hidden' }
            }
          >
            <Col md={12}>
              <Card
                title={t('calImplementation.calendarImplementation')}
                category={t('calImplementation.toInstallCalendar')}
                content={
                  <div>
                    <div>
                      <p>{t('calImplementation.embedCode')}</p>
                    </div>
                    <div>
                      <p className='category' />
                      <p>
                        <pre>
                          <code>
                            {'<!DOCTYPE html>'}
                            <br />
                            {'<html>'}
                            <br />
                            {'<head>'}
                            {this.state &&
                              this.state.calendarName &&
                              '\n\t<title>' +
                              this.state.calendarName +
                              '</title>'}
                            {
                              '\n\t<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">'
                            }
                            {
                              '\n\t<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">'
                            }
                            {/*
                              //'\n\t<script src="hhttps://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js"></script>'
                            */}

                            {this.state &&
                              this.state.ogTitle &&
                              '\n\t<meta property="og:title" content="' +
                              this.state.ogTitle +
                              '" />'}
                            {this.state &&
                              this.state.ogDescription &&
                              '\n\t<meta property="og:description" content="' +
                              this.state.ogDescription +
                              '" />'}
                            {this.state &&
                              this.state.ogType &&
                              '\n\t<meta property="og:type" content="' +
                              this.state.ogType +
                              '" />'}
                            {this.state &&
                              this.state.ogUrl &&
                              '\n\t<meta property="og:url" content="' +
                              this.state.ogUrl +
                              '" />'}
                            {this.state &&
                              this.state.ogImage &&
                              '\n\t<meta property="og:image" content="' +
                              this.state.ogImage +
                              '" />'}

                            {this.state &&
                              this.state.favicon &&
                              '\n\t<link rel="shortcut icon" type="image/png" href="' +
                              this.state.favicon +
                              '"/>'}   
                            <br />
                            {'\t<script>window.adventCalendarSettings = { companyId: "' +
                              this.props.activeCompany +
                              '", calendarId: "' +
                              this.props.activeCalendar +
                              '" }</script>'}
                            <br />
                            {'</head>'}
                            <br />
                            {'<body>'}
                            <br />
                            {'\t<!-- Start of Calendar Embed Code -->'}
                            <br />
                            {'\t<div id="root"></div>'}
                            <br />
                            {
                              '\t<script src="https://w4-calendar.firebaseapp.com/load.js"></script>'
                            }
                            <br />
                            {'\t<!-- End of Calendar Embed Code -->'}
                            <br />
                            {'</body>'}
                            <br />
                            {'</html>'}
                          </code>
                        </pre>
                      </p>
                    </div>

                    <div>
                      <p className='category'>
                        {t('calImplementation.futherAssistance')}
                      </p>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

export default connect(
  mapStateToProps,
  null
)(translate('translations')(CalImplementation))
