import React, { Component } from 'react'
import { connect } from 'react-redux'

class RouteToRedux extends Component {
  componentDidMount () {
    this.props.changeActiveCompany(this.props.match.params.companyId)
    this.props.changeActiveCalendar(this.props.match.params.calendarId)
  }
  render () {
    return <div />
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeActiveCompany: activeCompany => dispatch({ type: 'CHANGE_ACTIVE_COMPANY', value: activeCompany }),
    changeActiveCalendar: activeCalendar => dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: activeCalendar })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteToRedux)
