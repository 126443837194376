import React, { useState, useEffect } from 'react'
import { firebaseDb as db } from '../../firebase/firebase'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { translate } from 'react-i18next'

const Templates = props => {
  const { t, calendarType } = props
  const [options, setOptions] = useState([])
  useEffect(() => {
    db.doc('settings/templates')
      .get()
      .then(querySnapshot => {
        const templates = querySnapshot.data()
        // display templates only for selected calendar type
        let dataOptions = []
        templates.calendars.forEach(calendar => {
          if (calendar.type === calendarType) {
            dataOptions.push(calendar)
          }
        })
        setOptions(dataOptions)
      })
  }, [props.calendarType])

  if (!options || options.length === 0) {
    return false
  }
  if (options && options.length > 0) {
    return (
      <>
        <FormGroup controlId='formBasicText'>
          <ControlLabel>{t('createNewCalendar.selectTemplate')}</ControlLabel>
          <FormControl componentClass='select' name='template' placeholder=''>
            <option key='empty' value='empty'>
              {t('createNewCalendar.withoutTemplate')}
            </option>
            {options.map((item, index) => {
              return (
                <option key={index} value={index}>
                  {item.name}
                </option>
              )
            })}
          </FormControl>
        </FormGroup>
      </>
    )
  }
}

export default translate('translations')(Templates)
