import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import { Grid, Row, Col, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Card } from '../../components/Card/Card'
import { StatsCard } from '../../components/StatsCard/StatsCard'
import { firebaseDb as db } from '../../firebase'
import { translate } from 'react-i18next'
import { responsiveBar } from '../../variables/Variables'
import _ from 'lodash'

class Dashboard extends Component {
  state = {
    totalClicks: '...'
  }

  componentDidMount () {
    this.setState(
      {
        ...this.state,
        activeCompany: this.props.activeCompany,
        activeCalendar: this.props.activeCalendar
      },
      this.loadFromDb
    )
  }

  createLegend (json) {
    var legend = []
    for (var i = 0; i < json['names'].length; i++) {
      var type = 'fa fa-circle text-' + json['types'][i]
      legend.push(<i className={type} key={i} />)
      legend.push(' ')
      legend.push(json['names'][i])
    }
    return legend
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps !== this.props) {
      this.setState(
        {
          ...this.state,
          activeCompany: nextProps.activeCompany,
          activeCalendar: nextProps.activeCalendar
        },
        this.loadFromDb
      )
    }
  }

  loadFromDb = () => {
    if (this.state.activeCompany && this.state.activeCalendar) {
      // Get total clicks from database
      db.doc(
        'companies/' +
          this.state.activeCompany +
          '/calendars/' +
          this.state.activeCalendar +
          '/clicks/total'
      )
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.data()
          data &&
            this.setState({
              ...this.state,
              totalClicks: data.clicks,
              totalClicksDays: data.daysClicks
            })
        })
      // Get days from settings
      db.doc(
        'companies/' +
          this.state.activeCompany +
          '/calendars/' +
          this.state.activeCalendar +
          '/settings/grid'
      )
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.data()
          this.setState({ ...this.state, days: data.days })
        })
    }
  }

  resetStatistics = () => {
    if (window.confirm('This action will delete all collected clicks')) {
      // Reset Total clicks and remove daysClicks
      db.doc(
        'companies/' +
          this.state.activeCompany +
          '/calendars/' +
          this.state.activeCalendar +
          '/clicks/total'
      )
        .update({ clicks: 0, daysClicks: null })
        .then(() => {
          this.loadFromDb()
        })
    }
  }

  reloadStatistics = () => {
    this.loadFromDb()
  }

  render () {
    const { t } = this.props
    let days = []

    let series = []
    _.forEach(this.state.days, (day, index) => {
      if (index && !index.toString().includes('winning')) {
        days.push(index)
        if (this.state.totalClicksDays && this.state.totalClicksDays[index]) {
          series.push(this.state.totalClicksDays[index])
        } else {
          series.push(0)
        }
      }
    })
    // Data for Bar Chart
    var dataBar = {
      labels: days,
      series: [series]
    }

    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-graph1 text-success' />}
                statsText={t('calStats.doorClicks')}
                statsValue={this.state.totalClicks}
                statsIcon={<i className='fa fa-clock-o' />}
                statsIconText={t('calStats.totalAmountOfDoorClicks')}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-junk text-danger' />}
                statsText={t('calStats.purgeStatistics')}
                statsValue='Restart'
                statsIconText='&nbsp;'
                statsIcon={
                  <div>
                    <button
                      onClick={this.resetStatistics}
                      className='btn btn-xs btn-success'
                      style={{ marginLeft: '5px' }}
                    >
                      {t('calStats.reset')}
                    </button>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                id='chartActivity'
                title={t('calStats.doorClicks')}
                category={t('calStats.eachDoorClick')}
                stats={
                  <div>
                    {t('calStats.thisDataAreNot')}
                    <button
                      onClick={this.reloadStatistics}
                      className='btn btn-xs btn-success'
                      style={{ marginLeft: '5px' }}
                    >
                      {t('calStats.reloadStatistics')}
                    </button>{' '}
                  </div>
                }
                statsIcon='pe-7s-info'
                content={
                  <div className='ct-chart'>
                    <ChartistGraph
                      data={dataBar}
                      type='Bar'
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                id='chartActivity'
                title={t('calStats.doorClicks')}
                category={t('calStats.eachDoorClick')}
                stats={
                  <div>
                    {t('calStats.thisDataAreNot')}
                    <button
                      onClick={this.reloadStatistics}
                      className='btn btn-xs btn-success'
                      style={{ marginLeft: '5px' }}
                    >
                      {t('calStats.reloadStatistics')}
                    </button>{' '}
                  </div>
                }
                statsIcon='pe-7s-info'
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th style={{ width: '50px' }}>Day</th>
                        <th>Clicks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataBar &&
                        _.map(dataBar.labels, (day, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: '50px' }}>{day}.</td>
                              <td>{dataBar.series[0][index]}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(Dashboard))
