import React, { Component, useEffect } from 'react'
import { Form, Input, Button } from 'antd'
import { translate } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

let id = 0

class DynamicFieldSet extends Component {
  state = {
    defaultColors: null
  }
  DynamicFieldSet(props) {
    if (props) {
      this.setState({ ...this.state, defaultColors: this.props.defaultColors })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.defaultColors !== prevState.defaultColors) {
      const values = nextProps.defaultColors || {}
      const onlyKeys = nextProps.defaultColors ? Object.keys(nextProps.defaultColors) : []
      const initialValues = {}
      if (values) {
        onlyKeys.forEach(key => {
          initialValues[key] = values[key] ? values[key] : '#FFFFFF'
        })
      }
      nextProps.form.setFieldsValue(initialValues)
      return { defaultColors: nextProps.defaultColors }
    }
    return null
  }


  remove = async k => {
    const { form } = this.props
    // can use data-binding to get
    const values = form.getFieldsValue()
    // We need at least one passenger
    if (!values || Object.keys(values).length === 0) {
      return
    }

    // can use data-binding to set
    const newValues = Object.fromEntries(Object.entries(values).filter(([key]) => key !== k));
    form.setFieldsValue(newValues)
    this.props.handleChangeColors(newValues)
  }

  add = () => {
    const { form } = this.props
    // can use data-binding to get
    const values = form.getFieldsValue()
    const obj = Object.keys(values)
    id = parseInt(Object.values(obj)[obj.length - 1])
    let newId = id + 1
    if (isNaN(id)) {
      newId = '0'
    }

    const newValues = { ...values, [newId]: '' }
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue(newValues)
    this.props.handleChangeColors(newValues)
  }

  handleChangeColorsChild = (newValues) => {
    this.props.form.validateFields().then((values, err) => {
      if (!err) {
        this.props.handleChangeColors(values)
      }
    })
  }

  render() {
    const { t, form } = this.props
    let values, formItems
    let onlyKeys = []
    if (this.state.defaultColors) {
      values = this.state.defaultColors
      onlyKeys = Object.keys(this.state.defaultColors)
    }
    const keys = onlyKeys
    if (values) {
      formItems = keys && keys.map((k, index) => (
        <Form.Item style={{ marginBottom: '10px' }} key={k}>
          <Form.Item noStyle required={false} name={k}
            rules={
              [
                {
                  required: true,
                  whitespace: true,
                  message: t('calStyle.colorsPredefiner.pleaseSelectCorrectColor')
                }
              ]
            }
          >
            <Input type='color' style={{ width: '60%', marginRight: 8 }} />
          </Form.Item>
          {keys.length > 0 ? (
            <MinusCircleOutlined
              className='dynamic-delete-button'
              disabled={keys.length === 1}
              onClick={() => this.remove(k)}
            />
          ) : null}
        </Form.Item>
      ))
    }

    return (
      <Form onChange={this.handleChangeColorsChild} id='colors' name='dynamic_form_item' form={form}>
        {formItems}
        <Form.Item>
          <Button type='dashed' onClick={this.add} style={{ width: '60%' }}>
            <PlusOutlined /> {t('calStyle.colorsPredefiner.addColor')}
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

const DynamicFieldSetWrapper = (props) => {
  const [form] = Form.useForm();

  return (
    <>
      {<DynamicFieldSet {...props} form={form} />}
    </>
  )
}
export default translate('translations')(DynamicFieldSetWrapper)
