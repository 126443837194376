import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { firebaseAuth } from '../../firebase'
import { translate } from 'react-i18next'
import Message from './Parts/Message.js'
import { connect } from 'react-redux'

class Logout extends Component {
  state = {
    isLoggedOut: false,
    errorGlobal: null
  }

  componentDidMount () {
    firebaseAuth
      .signOut()
      .then(() => {
        this.setState({ ...this.state, isLoggedOut: true })
        this.props.changeActiveCalendar(null)
        this.props.changeActiveCompany(null)
        this.props.changeCompaniesData(null)
      })
      .catch(error => {
        // An error happened.
        this.setState({ ...this.state, errorGlobal: error })
      })
  }

  render () {
    const { t } = this.props

    return (
      <div className='content'>
        <Grid>
          <Row>
            <Col xs={0} sm={2} md={4} />
            <Col xs={12} sm={6} md={4}>
              {this.state.isLoggedOut && (
                <Message
                  icon={this.state.errorGlobal ? 'pe-7s-close' : 'pe-7s-paper-plane'}
                  title={
                    this.state.errorGlobal
                      ? t('auth.logout.error') + this.state.errorGlobal
                      : t('auth.logout.youHaveBeenLoggedOut')
                  }
                  text=''
                />
              )}
              {!this.state.isLoggedOut && (
                <Message icon='pe-7s-refresh-2' title={t('auth.logout.loggingOut')} text='' withoutbutton />
              )}
            </Col>
            <Col xs={0} sm={2} md={4} />
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeActiveCompany: activeCompany => dispatch({ type: 'CHANGE_ACTIVE_COMPANY', value: activeCompany }),
    changeActiveCalendar: activeCalendar => dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: activeCalendar }),
    changeCompaniesData: companiesData => dispatch({ type: 'CHANGE_COMPANIES_DATA', value: companiesData })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(Logout))
