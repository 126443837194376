import React, { useState, useEffect } from 'react'

import FormBuilder from './Parts/FormBuilder'
import { translate } from 'react-i18next'
import { firebaseDb as db } from '../../firebase'
import { Pagination, Row, Col, Grid } from 'react-bootstrap'
import Button from '../../components/CustomButton/CustomButton'
import Loading from '../../components/Loading/Loading'
import Modal from './Parts/Modal'
import _ from 'lodash'
import 'react-form-builder2/dist/app.css'
import './CalBoxes.css'

// Redux
import { useSelector } from 'react-redux'

const CalBoxes = props => {
  const { t } = props

  const [formsList, setFormsList] = useState()
  const [selectedForm, setSelectedForm] = useState()
  const [daysGridData, setDaysGridData] = useState()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [daysGridDataColors, setDaysGridDataColors] = useState()
  const [calendarType, setCalendarType] = useState()
  const [, setModalData] = useState([])
  const [reloadForm] = useState(false)

  //update
  const [gridData, setGridData] = useState()

  const { activeCalendar, activeCompany, calendarLang } = useSelector(
    state => state
  )

  useEffect(() => {
    if ((activeCalendar && activeCompany) || reloadForm) {
      loadFormsFromDb()
    }
  }, [activeCalendar, activeCompany, reloadForm, calendarLang])

  useEffect(() => {
    if (daysGridData && calendarType && daysGridDataColors) {
      setLoading(false)
    }
  }, [daysGridData, calendarType, daysGridDataColors])

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'Not saved! Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  const loadFormsFromDb = () => {
    activeCalendar &&
      db
        .collection(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/days'
        )
        .get()
        .then(async querySnapshot => {
          const data = querySnapshot.docs.map(documentSnapshot => {
            return documentSnapshot.data()
          })

          if (data) {
            let forms = {}
            let lastId = 0
            let winningExists = false
            if (data) {
              _.forEach(querySnapshot.docs, documentSnapshot => {
                const dataForm = documentSnapshot.data()
                let idOfForm
                if (documentSnapshot.id && documentSnapshot.id.toString().includes('winning')) {
                  idOfForm = documentSnapshot.id
                  winningExists = true
                } else {
                  idOfForm = parseInt(documentSnapshot.id)
                }
                forms[idOfForm] = { id: idOfForm, image: dataForm.image }
                lastId = idOfForm > lastId ? idOfForm : lastId
                if (winningExists) {
                  lastId = 'winning'
                }
              })
              setFormsList(forms)
              setSelectedForm(lastId)

              setGridData({
                formList: forms,
                selectedForm: lastId
              })

              loadFromGrid()
            }
          }
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
  }

  const loadFromGrid = () => {
    activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          const data = querySnapshot.data()
          if (data.days) {
            setDaysGridData(data.days)
            setCalendarType(data.type)
            setDaysGridDataColors(data.colors)
          }
        })
        .catch(error => {
          console.log('error', error)
        })
  }
  const removeForm = () => {
    // UPDATE DAYS GRID DB
    let newDayGrid = daysGridData
    delete newDayGrid[selectedForm]

    if(calendarType === "memory-game") {
      delete newDayGrid[`pair_${selectedForm}`]
    }

    db.doc(
      'companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/settings/grid'
    )
      .update({ days: newDayGrid })
      .then(res => {
        // UPDATE FORMS DB
        db.doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/days/' +
          selectedForm
        )
          .delete()
          .then(() => {
            loadFormsFromDb()
          })
          .catch(error => {
            console.error('Error removing document: ', error)
          })
        // Ask for publish
        db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
          .update({ changeToPublish: true })
          .then(res => { })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const addWinningDayForm = () => {
    const lastWinningId = getLastWinningFormId();
    const newWinningId = lastWinningId + 1;
    let days = daysGridData
    let newGridObj, newFormObj

    if (!days) {
      days = {}
    }

    if(calendarType === "memory-game") {
      newGridObj = {
        bgPos: '',
        color: '',
        coords: {
          0: 0,
          1: 0,
          2: newWinningId + 1,
          3: 0
        }
      };
      newFormObj = {
        data: '',
        formSubmitClicks: 'false'
      }
    } else {
      newGridObj = {
        color: 1,
        bgPos: 'center center',
        coords: {
          0: 0,
          1: 0,
          2: 1,
          3: 1
        },
        start: new Date(),
        stop: new Date(),
        externalPageEnable: false
      };
      newFormObj = {
        data: '[]',
        formSubmitClicks: 'false',
        afterFormMessage: '',
        start: new Date(),
        hubspotFormId: null
      }
    }

    return addNewForm(
      newGridObj,
      newFormObj,
      "winning" + newWinningId
    )
  }

  const addMemoryDayForm = () => {
    const lastId = getLastFormId();
    const newId = lastId + 1;

    const newDayObj = {
      bgPos: '',
      color: '',
      coords: {
        0: lastId % 4,
        1: Math.floor(lastId / 4),
        2: 1,
        3: 1
      }
    }
    const newForm = {
      data: '',
      formSubmitClicks: 'false'
    }
    return addNewForm(
      newDayObj,
      newForm,
      newId
    )
  }

  const getLastFormId = () => {
    let obj = formsList
    Object.keys(obj).forEach((key) => {
      if(key.toString().includes('winning')) {
        delete obj[key]
      }

    })
    const last = parseInt(Object.keys(obj)[Object.keys(obj).length - 1])
    let lastElementId
    if (!isNaN(last)) {
      lastElementId = last
    } else {
      lastElementId = 0
    }
    return lastElementId
  }

  const getLastWinningFormId = () => {
    let obj = formsList
    let lastId = 0;
    Object.keys(obj).forEach((key) => {
      if(!key.toString().includes('winning')) {
        delete obj[key]
      } else {
        const currentKey = key.toString().split("winning")[1];
        if (!isNaN(currentKey) && currentKey !== "") {
          lastId = Math.max(lastId, Number(currentKey))
        }
      }
    })
    return lastId
  }

  const addNewForm = (newDayObj, newFormObj, newDayId) => {
    // UPDATE DAYS GRID DB
    let days = daysGridData

    if (!days) {
      days = {}
    }
    days[newDayId] = newDayObj

    if(calendarType === "memory-game") {
      const lastId = getLastFormId();
      const newId = lastId * 2;
      days[`pair_${newDayId}`] = {
        bgPos: '',
        color: '',
        coords: {
          0: newId % 4,
          1: Math.floor(newId / 4),
          2: 1,
          3: 1
        }
      }
    }
    db.doc(
      'companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/settings/grid'
    )
      .update({ days })
      .then(res => {
        db.doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/days/' +
          newDayId
        )
          .set(newFormObj)     // UPDATE FORMS DB
          .then(() => {
            setSelectedForm(newDayId)
            loadFormsFromDb()
          })
          .catch(error => {
            console.error('Error adding document: ', error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const addForm = () => {
    const newId = getLastFormId() + 1

    const newDayObj = {
      color: 1,
      bgPos: 'center center',
      coords: {
        0: 0,
        1: 0,
        2: 1,
        3: 1
      },
      start: new Date(),
      stop: new Date(),
      externalPageEnable: false
    }
    // UPDATE FORMS DB
    const newForm = {
      data: '[]',
      formSubmitClicks: 'false',
      afterFormMessage: '',
      start: new Date(),
      hubspotFormId: null
    }
    return addNewForm(
      newDayObj, 
      newForm, 
      newId
    )
  }

  const changeSelectedFormHandler = selectedForm => {
    setSelectedForm(selectedForm)
  }

  // modal
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const copyTranslations = async (newId) => {
    const dataGrid = (
      await db
        .doc(
          '/companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
    ).data()

    const languages = dataGrid && dataGrid.languages
    for (const language in languages) {
      const ref = `companies/${activeCompany}/calendars/${activeCalendar}/translation/${language}/days`
      const ts = db.collection(ref)
      const snapshot = await ts.get()
      snapshot.forEach(async doc => {
        if (doc.id === `${selectedForm}`) {
          const dayTranslation = doc.data()
          await db.doc(ref + `/${newId}`)
            .set(dayTranslation)
            .then(() => {

            })
            .catch(error => {
              console.error('Error adding document: ', error)
            })
        }
      })
    }

  }

  const copyBox = () => {
    const newId = getLastFormId() + 1

    db.doc(
      'companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/days/' +
      selectedForm
    )
      .get()
      .then(async querySnapshot => {
        // Get original Box data
        let originalDayData = querySnapshot.data()

        let newDayGrid = daysGridData
        newDayGrid[newId] = daysGridData[selectedForm]

        // Update database
        db.doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
          .update({ days: newDayGrid })
          .then(res => {
            db.doc(
              'companies/' +
              activeCompany +
              '/calendars/' +
              activeCalendar +
              '/days/' +
              newId
            )
              .set(originalDayData)
              .then(async () => {
                await copyTranslations(newId)
                setSelectedForm(newId)
                loadFormsFromDb(newId)
              })
              .catch(error => {
                console.error('Error adding document: ', error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  return (
    <div className='content'>
      {loading ? (
        <Row className='show-grid' float='center'>
          <Col xs={12} xsOffset={6}>
            <Loading type='spin' color='green' />
          </Col>
        </Row>
      ) : (
        <div>
          {_.size(formsList) === 0 ? (
            <div style={{ textAlign: 'center' }}>
              <Grid>
                <Row>
                  <Col xs={0} sm={2} md={4} />
                  <Col xs={12} sm={6} md={4}>
                    <div className='logo' style={{ textAlign: 'center' }}>
                      <i
                        className='pe-7s-date'
                        style={{
                          fontSize: '150px',
                          marginBottom: '20px',
                          color: 'green'
                        }}
                      />
                      <h3>{t('calDays.startWithFirstDay')}</h3>
                    </div>
                    <Button fill bsStyle='success' onClick={addForm}>
                      {t('calDays.addDay')}
                    </Button>
                  </Col>
                  <Col xs={0} sm={2} md={4} />
                </Row>
              </Grid>
            </div>
          ) : (
            <div>
              <Modal
                show={showModal}
                closeCallback={toggleModal}
                customClass='modal'
              >
                {/* <React.Fragment>
                  <h2>
                    {t('calDays.previewFormNumber')}
                    {selectedForm}
                  </h2>
                  <ReactFormGenerator data={modalData} hide_actions={true} />
                  <form id='fb-render' />
                </React.Fragment> */}
              </Modal>
              <Row>
                <Col sm={12}>
                  <Pagination style={{ margin: '0 0 15px 0' }}>
                    <Pagination.Item>{t('calDays.days')}</Pagination.Item>
                    {formsList &&
                      _.map(formsList, (element, index) => {
                        return (
                          !isNaN(element.id) && (
                            <Pagination.Item
                              key={index}
                              active={selectedForm === element.id}
                              onClick={() =>
                                changeSelectedFormHandler(element.id)
                              }
                            >
                              {element.id}
                            </Pagination.Item>
                          )
                        )
                      })}
                    {(calendarType === 'hide-and-seek' || calendarType === 'memory-game') && (
                      formsList &&
                      _.map(formsList, (element, index) => {
                        return (
                          element && element.id && element.id.toString().includes('winning') && (
                            <Pagination.Item
                              key={element.id}
                              active={selectedForm === element.id}
                              onClick={() =>
                                changeSelectedFormHandler(element.id)
                              }
                            >
                              {t('calDays.winningBox')} {element.id.toString().split("winning")[1] }
                            </Pagination.Item>
                          )
                        )
                      })
                    )}
                  </Pagination>
                </Col>
              </Row>
              {selectedForm && !selectedForm.toString().includes('winning') && (
                <button
                  style={{ margin: '0 0px 20px 0px' }}
                  onClick={removeForm}
                  className='btn btn-danger pull-right btn-md'
                >
                  {t('calDays.removeDay')}
                </button>
              )}

              {calendarType !== 'memory-game' &&
                <button
                  style={{ margin: '0 10px 20px 0px' }}
                  onClick={addForm}
                  className='btn btn-success pull-right btn-md'
                >
                  {t('calDays.addNewDay')}
                </button>
              }
              {calendarType === 'memory-game' &&
                <button
                  style={{ margin: '0 10px 20px 0px' }}
                  onClick={addMemoryDayForm}
                  className='btn btn-success pull-right btn-md'
                >
                  {t('calDays.addNewDay')}
                </button>
              }
              {((calendarType === 'hide-and-seek' || calendarType === 'memory-game') && selectedForm && selectedForm.toString().includes('winning')) && (
                <button
                  style={{ margin: '0 10px 20px 0px' }}
                  onClick={addWinningDayForm}
                  className='btn btn-success pull-right btn-md'
                >
                  {t('calDays.addWinningDay')}
                </button>
              )}
              {((calendarType === 'hide-and-seek' || calendarType === 'memory-game') && selectedForm && selectedForm !== 'winning' && selectedForm.toString().includes('winning')) && (
                <button
                  style={{ margin: '0 10px 20px 0px' }}
                  onClick={removeForm}
                  className='btn btn-danger pull-right btn-md'
                >
                  {t('calDays.removeWinningDay')}
                </button>
              )}
              
              {/* <button
                style={{ margin: '0 10px 20px 0px' }}
                onClick={toggleModal}
                className='btn btn-info pull-right btn-md'
              >
                {t('calDays.preview')}
              </button> */}
              {/** COPY FUNCTION DISABLED BECAUSE TRANSLATION NOT COPIED */}
              {( selectedForm && !selectedForm.toString().includes('winning') && calendarType !== 'memory-game') && (
                <button
                  style={{ margin: '0 10px 20px 0px' }}
                  onClick={copyBox}
                  className='btn btn-warning pull-right btn-md'
                >
                  {t('calDays.copy')}
                </button>
              )}
              <FormBuilder
                gridData={gridData}
                daysGridDataColors={daysGridDataColors}
                daysGridData={daysGridData}
                updateGridData={setDaysGridData}
                calendarType={calendarType}
                selectedForm={selectedForm}
                modalData={setModalData}
                notificationOpen={notificationOpen}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default translate('translations')(CalBoxes)
