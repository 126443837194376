import React, { Component } from 'react'
import { Upload, Modal } from 'antd'
import { firebaseDb as db, storageRef as storage } from '../../firebase'
import { Progress } from 'antd'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const storageRef = storage.ref()

/**
 * Returns a single image upload component.
 *
 * @param fileName new filename, keeping suffix of new file, don't add suffix, if empty origin name
 * @param pathToUload path in storage to save new file
 * @param dbPath dbPath for storing new name of a file
 * @param dbFieldInArray object of property inside document
 * @param dbFieldArrayIndex key of an object to be unpdated
 * @param dbField field in firestore to save new filename
 * @param placeholderLabel label for upload request, if empty simple label
 * @param placeholderImage url of image
 * @param maxMbToUpload maximum size of a file, in Mb, if empty 1Mb
 * @param widthImage image with, if empty 300px
 *
 */
class UploadSingleImage extends Component {
  state = {
    loading: false,
    uploadingToStoreProgress: 'wait',
    imageUrl: '',
    backgroundImage: '',
    readyToUpload: false,
    uploadRestrictionsError: null,
    modalVisible: false
  }

  componentDidMount() {
    this.loadImage()
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dbPath !== this.props.dbPath ||
      nextProps.dbFieldArrayIndex !== this.props.dbFieldArrayIndex ||
      nextProps.activeCalendar !== this.props.activeCalendar
    ) {
      this.props = nextProps
      this.setState(
        {
          ...this.state,
          imageUrl: null,
          loading: false,
          uploadRestrictionsError: null,
          uploadingToStoreProgress: 'wait'
        },
        this.loadImage
      )
    }
  }

  loadImage = () => {
    this.props.activeCalendar &&
      db
        .doc(
          'companies/' + this.props.activeCompany + '/calendars/' + this.props.activeCalendar + '/' + this.props.dbPath
        )
        .get()
        .then(querySnapshot => {
          let data = querySnapshot.data()
          if (data) {
            let imageName
            if (this.props.dbFieldInArray) {
              if (
                data[this.props.dbFieldInArray] &&
                data[this.props.dbFieldInArray] &&
                data[this.props.dbFieldInArray][this.props.dbFieldArrayIndex]
              ) {
                imageName = data[this.props.dbFieldInArray][this.props.dbFieldArrayIndex][this.props.dbField]
              }
            } else {
              imageName = data[this.props.dbField]
            }
            const newState = { ...this.state, backgroundImage: imageName }
            this.setState(newState)

            if (this.state.backgroundImage) {
              storageRef
                .child(
                  'company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/' +
                  this.props.pathToUload +
                  this.state.backgroundImage
                )
                .getDownloadURL()
                .then(url => {
                  const newState = { ...this.state, imageUrl: url }
                  if (this.props.setImageUrl && url) {
                    this.props.setImageUrl(url)
                  }
                  this.setState(newState)
                })
                .catch(error => {
                  error.code !== 'storage/object-not-found' && console.log('error', error)
                  this.displayPlaceholderImage()
                })
            } else {
              this.displayPlaceholderImage()
            }
          } else {
            this.displayPlaceholderImage()
          }
        })
  }

  displayPlaceholderImage = () => {
    if (this.props.placeholderImage) {
      storageRef
        .child(this.props.placeholderImage)
        .getDownloadURL()
        .then(url => {
          this.setState({ ...this.state, imageUrl: url })
        })
        .catch(error => {
          error.code !== 'storage/object-not-found' && console.log(error)
        })
    } else {
      this.setState({ ...this.state, imageUrl: null })
    }
  }

  getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  beforeUpload = file => {
    const isJPG = file.type === 'image/jpeg'
    const isPNG = file.type === 'image/png'
    const isSVG = file.type === 'image/svg+xml'
    let typeIsOk = true

    if (!isJPG && !isPNG && !isSVG) {
      const message = 'You can only upload JPG, PNG or SVG file! Please use something like https://tinypng.com/'
      this.notificationOpen('error', message)
      typeIsOk = false
      const newState = {
        ...this.state,
        uploadRestrictionsError: message,
        loading: false,
        uploadingToStoreProgress: 'wait'
      }
      this.setState(newState)
    }
    let maxMbToUpload
    if (this.props.maxMbToUpload) {
      maxMbToUpload = this.props.maxMbToUpload
    } else {
      maxMbToUpload = '1'
    }
    const isLarge = file.size / 1024 / 1024 < maxMbToUpload
    if (!isLarge) {
      const message = 'Image must smaller than ' + maxMbToUpload + 'MB! Please use something like https://tinypng.com/'
      this.notificationOpen('error', message)
      const newState = {
        ...this.state,
        uploadRestrictionsError: message,
        loading: false,
        uploadingToStoreProgress: 'wait'
      }
      this.setState(newState)
    }
    return typeIsOk && isLarge
  }

  deleteBackgroundImageAndUpload = (backgroundImageToDelete, newFileName, file) => {
    // Create a reference to the file to delete
    var desertRef = storageRef.child(
      'company/' +
      this.props.activeCompany +
      '/calendar/' +
      this.props.activeCalendar +
      '/' +
      this.props.pathToUload +
      backgroundImageToDelete
    )

    this.props.activeCalendar &&
      // Delete the file
      desertRef
        .delete()
        .then(() => {
          // File deleted successfully
          this.uploadImageToStore(newFileName, file)
        })
        .catch(error => {
          // Uh-oh, an error occurred!
          console.log('error', error)
          this.uploadImageToStore(newFileName, file)
        })
  }

  uploadImageToStore = (newFileName, file) => {
    const uploadTask = storageRef
      .child(
        'company/' +
        this.props.activeCompany +
        '/calendar/' +
        this.props.activeCalendar +
        '/' +
        this.props.pathToUload +
        newFileName
      )
      .put(file)

    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        progress = progress.toFixed(0)
        const newState = { ...this.state, uploadingToStoreProgress: progress }
        this.setState(newState)
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        //   console.log('File available at', downloadURL)
        // })
      }
    )
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      const newState = { ...this.state, loading: true, uploadRestrictionsError: null }
      this.setState(newState)
      return
    }
    if (info.file.status === 'done') {
      this.getBase64(info.file.originFileObj, imageUrl => {
        const newState = { ...this.state, imageUrl, loading: false, uploadRestrictionsError: null }
        this.setState(newState)
      })
    }
  }
  notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      this.props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'Error: ' + errorMessage
      this.props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  uploadToFirebase = ({ onSuccess, onError, file }) => {
    const backgroundImageToDelete = this.state.backgroundImage
    let newFileName
    if (this.props.fileName) {
      newFileName = this.props.fileName + '.' + file.name.split('.').pop()
    } else {
      newFileName = file.name
    }

    let udpateContent

    if (this.props.dbFieldInArray) {
      // GET ARRAY FROM DB
      db.doc(
        'companies/' + this.props.activeCompany + '/calendars/' + this.props.activeCalendar + '/' + this.props.dbPath
      )
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.data()
          let daysGridData
          if (data.days) {
            daysGridData = data.days

            let newDayGrid = daysGridData
            newDayGrid[this.props.dbFieldArrayIndex][this.props.dbField] = newFileName

            udpateContent = {
              [this.props.dbFieldInArray]: newDayGrid
            }
            db.doc(
              'companies/' +
              this.props.activeCompany +
              '/calendars/' +
              this.props.activeCalendar +
              '/' +
              this.props.dbPath
            )
              .update(udpateContent)
              .then(() => {
                db.doc('companies/' + this.props.activeCompany + '/calendars/' + this.props.activeCalendar)
                  .update({ changeToPublish: true })
                  .then(res => { })
                  .catch(error => {
                    console.log(error)
                  })
                this.deleteBackgroundImageAndUpload(backgroundImageToDelete, newFileName, file)

                const newState = { ...this.state, backgroundImage: newFileName, uploadingToStoreProgress: 100 }
                this.setState(newState)

                this.notificationOpen('success')
                onSuccess(null, file)
              })
              .catch(error => {
                console.log(error)
                this.notificationOpen('error', error)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      udpateContent = {
        [this.props.dbField]: newFileName
      }
      db.doc(
        'companies/' + this.props.activeCompany + '/calendars/' + this.props.activeCalendar + '/' + this.props.dbPath
      )
        .update(udpateContent)
        .then(() => {
          db.doc('companies/' + this.props.activeCompany + '/calendars/' + this.props.activeCalendar)
            .update({ changeToPublish: true })
            .then(res => { })
            .catch(error => {
              console.log(error)
            })
          this.deleteBackgroundImageAndUpload(backgroundImageToDelete, newFileName, file)

          const newState = { ...this.state, backgroundImage: newFileName, uploadingToStoreProgress: 100 }
          this.setState(newState)

          this.notificationOpen('success')
          onSuccess(null, file)
        })
        .catch(error => {
          console.log(error)
          this.notificationOpen('error', error)
        })
    }
  }

  toggle = () => this.setState({ modalVisible: !this.state.modalVisible });

  handleRemove = () => {
    // console.log(this.uploadElement, this.uploadElement.onRemove)
    // this.uploadElement.handleRemove({
    //   uid: '-1',
    // })
    this.toggle()
  }

  onRemoveImage = () => {
    this.toggle()
    // Create a reference to the file to delete
    var desertRef = storageRef.child(
      'company/' +
      this.props.activeCompany +
      '/calendar/' +
      this.props.activeCalendar +
      '/' +
      this.props.pathToUload +
      this.state.backgroundImage
    )

    this.props.activeCalendar &&
      // Delete the file
      desertRef
        .delete()
        .then(() => {
          this.setState({ imageUrl: null, backgroundImage: null })
        })
        .catch(error => {
          // Uh-oh, an error occurred!
          console.log('error', error)
        })
  }

  render() {
    const uploadButton = (
      <div className="upload-button">
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div>{this.props.placeholderLabel ? this.props.placeholderLabel : 'Upload background image'}</div>
      </div>
    )
    const imageUrl = this.state.imageUrl
    let progressBar
    let uploadErrorMessage
    if (this.state.uploadingToStoreProgress !== 'wait') {
      progressBar = (
        <Row>
          <Col md={8} lg={6} sm={12}>
            <Progress percent={Number(this.state.uploadingToStoreProgress)} />
          </Col>
        </Row>
      )
    }
    if (this.state.uploadRestrictionsError) {
      const styleOfErrorMessage = {
        color: 'red'
      }
      uploadErrorMessage = (
        <Row>
          <Col md={12} lg={12} sm={12}>
            <div style={styleOfErrorMessage}>{this.state.uploadRestrictionsError}</div>
          </Col>
        </Row>
      )
    }
    const widthImage = this.props.widthImage ? this.props.widthImage : '300px'
    const containerImage = {
      width: widthImage
    }

    return (
      <div>
        <Modal
          okText="Yes"
          cancelText="No"
          centered
          visible={this.state.modalVisible}
          onCancel={this.toggle}
          onOk={this.onRemoveImage}>
          Are you sure delete this image?
        </Modal>
        <Row>
          <Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              style={{ backgroundColor: this.props.bgcolor }}
              showUploadList={false}
              customRequest={this.uploadToFirebase}
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
              ref={element => this.uploadElement = element}
            >
              {imageUrl ? <img src={imageUrl} style={containerImage} alt='backgroundImage' /> : uploadButton}
            </Upload>
            {imageUrl && <a onClick={this.handleRemove} style={{ marginLeft: '5px' }}>
              <FontAwesomeIcon icon={faTrashCan} />
            </a>}
          </Col>
        </Row>
        {progressBar}
        {uploadErrorMessage}
      </div>
    )
  }
}

export default UploadSingleImage
