import React from 'react'
import { FileBrowser } from './Parts/FileBrowser'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'


export const FileStorage = (props) => {
  return (
    <div><FileBrowser activeCompany={props.activeCompany}/></div>
  )
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(FileStorage))
