import {
  FontManager
} from "@samuelmeuli/font-manager";
import React, { useEffect, useState } from "react";
import {
  FormGroup,
  DropdownButton,
  MenuItem
} from 'react-bootstrap'
import { Helmet } from "react-helmet";

import './FontPicker.css'

const FontPicker = (props) => {

  const [fonts, setFonts] = useState()
  const [fontManager, setFontManager] = useState()

  const {
    apiKey,
    activeFontFamily,
    pickerId,
    families,
    categories,
    scripts,
    variants,
    filter,
    limit,
    sort,
    onChange,
    extraFonts
  } = props;

  const options = {
    pickerId,
    families,
    categories,
    scripts,
    variants,
    filter,
    limit,
    sort,
  };

  useEffect(() => {
    const fontManager = new FontManager(apiKey, activeFontFamily, options, onChange);

    extraFonts.forEach(element => {
      fontManager.addFont(element)
    });
    fontManager
      .init()
      .then(() => {
        const googleFonts = Array.from(fontManager.getFonts().values());

        googleFonts.sort((font1, font2) => font1.family.localeCompare(font2.family));
        setFonts(googleFonts)
        setFontManager(fontManager)
      })
      .catch((err) => {
        console.log('error', err)
      });
  }, [])

  const onSelection = (e) => {
    const target = e.target
    const activeFontFamily = target.textContent;
    if (!activeFontFamily) {
      throw Error(`Missing font family in clicked font button`);
    }
    fontManager.setActiveFont(activeFontFamily);
    // Custom fonts throws error, it is a workaround
    fontManager.setActiveFont(activeFontFamily);
  };

  return (
    <FormGroup>
      <DropdownButton
        id="document-type"
        title={activeFontFamily || ''}
        style={{ fontFamily: activeFontFamily }}
      >
        {fonts && fonts.map((font, i) => {
          return (
            <MenuItem key={i} eventKey={i} onClick={onSelection}>
              {font.category &&
                <Helmet>
                  <link rel="stylesheet" href={`https://fonts.googleapis.com/css2?family=${font.family}`} />
                </Helmet>
              }
              <div style={{ fontFamily: font.family }}>
                {font.family}
              </div>
            </MenuItem>
          )
        })}
      </DropdownButton>
    </FormGroup>
  )
}

export default FontPicker