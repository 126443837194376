import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Input, Select, Spin } from 'antd'
import { firebaseDb as db } from '../../firebase'
const { Option } = Select;

export const UserForm = (props) => {
  const { values, form, onSubmit } = props
  const [loading, setLoading] = useState(true)
  const [companies, setCompanies] = useState([])

  const updateFields = async (values) => {
    const fetchedCompanies = []
    await db.collection('companies/')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(doc => {
          fetchedCompanies.push({ ...doc.data(), id: doc.id })
        })
      })
    setCompanies(fetchedCompanies)
    form.setFieldsValue(values)
    setLoading(false)
  }

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      updateFields(values)
    }
  }, [values])

  const onFinish = (values) => {
    values.admin = !values.admin ? false : true
    if (onSubmit) {
      onSubmit(values)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form
        {...props}
        requiredMark={false}
        labelCol={{ flex: '130px' }}
        labelAlign='right'
        colon={false}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name='email'
          rules={[{ required: true, message: '', type: 'email' }]}
          label={'Email'}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='emailVerified'
          label='Email Verified'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name='displayName'
          rules={[{ required: true, message: '' }]}
          label='Display Name'
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='disabled'
          label='Disabled'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name='admin'
          label='Admin'
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name='companies'
          label='Companies'
        >
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select"
          >
            {companies && companies.map((company) => <Option key={company.id}>{`${company.id}: ${company.name}`}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          name='uid'
          noStyle
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Spin>
  )
}
