import Dashboard from '../views/Dashboard/Dashboard'
import DashboardCompany from '../views/Dashboard/Company'
import DashboardUser from '../views/Dashboard/User'
import UserProfile from '../views/UserProfile/UserProfile'
import Typography from '../views/Typography/Typography'
import Notifications from '../views/Notifications/Notifications'
import Upgrade from '../views/Upgrade/Upgrade'
import CalHeader from '../views/CalHeader'
import CalSettings from '../views/CalSettings/CalSettings'
import CalInfoPages from '../views/CalInfoPages/CalInfoPages'
import CalStyle from '../views/CalStyle/CalStyle'
import CalSubmits from '../views/CalSubmits/CalSubmits'
import CalBoxes from '../views/CalBoxes/CalBoxes'
import CalInfoPage from '../views/CalInfoPages/CalInfoPage/CalInfoPage'
import CalGrid from '../views/CalGrid/CalGrid'
import Account from '../views/Accounts/Account/Account'
import CreateNewCalendar from '../views/Calendars/CreateNewCalendar'
import DuplicateCalendar from '../views/Calendars/DuplicateCalendar'
import CalPublish from '../views/CalPublish/CalPublish'
import CalGamePopups from '../views/CalGamePopups/CalGamePopups'
import CalImplementation from '../views/CalImplementation/CalImplementation'
import CalStats from '../views/CalStats/CalStats'
import FileStorage from '../views/FileStorage/FileStorage'
import AdminTable from '../views/Admin/AdminTable'
import Backup from '../views/Backup/Backup'

const dashboardRoutes = [
  {
    path: '/company/:companyId/calendar/:calendarId/calheader',
    name: 'Header',
    component: CalHeader,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calstats',
    name: 'Statistics',
    component: CalStats,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calsubmits',
    name: 'Submits',
    component: CalSubmits,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calgame-popups',
    name: 'Cal Counter And Newsletter',
    component: CalGamePopups,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calstyle',
    name: 'Style',
    component: CalStyle,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/create-new-calendar',
    name: 'Create new Calendar',
    component: CreateNewCalendar,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/duplicate-calendar',
    name: 'Create new Calendar',
    component: DuplicateCalendar,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/dashboard',
    name: 'Calendar Day',
    component: Dashboard,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calinfopage/:pageId',
    name: 'Info Page Edit',
    component: CalInfoPage,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/dashboard',
    name: 'DashBoard for a company',
    component: DashboardCompany,
    withNotifications: true,
    showOnCalenderDisabled: true
  },
  {
    path: '/dashboard',
    name: 'Dashboard for a user',
    component: DashboardUser,
    withNotifications: true,
    showOnCalenderDisabled: true
  },
  {
    path: '/company/:companyId/account',
    name: 'Account Page',
    component: Account,
    withNotifications: true,
    showOnCalenderDisabled: true
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calboxes',
    name: 'CalBoxes',
    component: CalBoxes,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calinfopages',
    name: 'Info pages',
    component: CalInfoPages,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calgrid',
    name: 'Grid',
    component: CalGrid,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/#',
    name: 'Submits',
    component: Dashboard,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/#',
    name: 'Statistics',
    component: Dashboard,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calpublish',
    name: 'Generator',
    component: CalPublish,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calsettings',
    name: 'Settings',
    component: CalSettings,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/calimplementation',
    name: 'Implementation',
    component: CalImplementation,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/filestorage',
    name: 'File Storage',
    component: FileStorage,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/company/:companyId/calendar/:calendarId/backup',
    name: 'Backup',
    component: Backup,
    withNotifications: true,
    showOnCalenderDisabled: false
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminTable,
    withNotifications: true,
    showOnCalenderDisabled: true
  },
  {
    path: '/company/:companyId/calendar/:calendarId/dashboard',
    name: '//Dashboard',
    component: Dashboard,
    showOnCalenderDisabled: true
  },
  {
    path: '/user',
    name: '//User Profile',
    component: UserProfile,
    showOnCalenderDisabled: true
  },
  {
    path: '/typography',
    name: '//Typography',
    component: Typography,
    showOnCalenderDisabled: true
  },
  {
    path: '/notifications',
    name: '//Notifications',
    component: Notifications,
    withNotifications: true,
    showOnCalenderDisabled: true
  },
  {
    upgrade: true,
    path: '/upgrade',
    name: 'Upgrade to PRO',
    showOnCalenderDisabled: true,
    component: Upgrade
  }
]

export default dashboardRoutes
