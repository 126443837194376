import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Sidebar from '../../components/Sidebar/Sidebar'
import dashboardRoutes from '../../routes/dashboard'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import DataFromDb from '../../helpers/DataFromDb'
import RouteToRedux from '../../helpers/RouteToRedux'

import { style } from '../../variables/Variables'
import { firebaseAuth } from '../../firebase'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.handleNotificationClick = this.handleNotificationClick.bind(this)
    this.state = {
      _notificationSystem: null
    }
  }
  handleNotificationClick(position, icon, type, text) {
    this.state._notificationSystem.addNotification({
      title: <span data-notify='icon' className={icon} />,
      message: <div>{text}</div>,
      level: type,
      position: 'bc',
      autoDismiss: 3
    })
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem })
    firebaseAuth.currentUser
      .getIdTokenResult()
      .then(idTokenResult => { this.setState({ admin: idTokenResult.claims.admin }) })
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainPanel.scrollTop = 0
    }
  }

  render() {
    return (
      <div className='wrapper'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <DataFromDb />
        {[
          '/dashboard',
          '/admin',
          '/company/:companyId/calendar/:calendarId',
          '/company/:companyId/dashboard',
          '/company/:companyId/account',
          '/company/:companyId/create-new-calendar'
        ].map((path, index) => (
          <Route
            path={path}
            render={routeProps => <Sidebar {...routeProps} notificationOpen={this.handleNotificationClick} />}
            key={index}
          />
        ))}
        {[
          '/company/:companyId/calendar/:calendarId',
          '/company/:companyId/dashboard',
          '/company/:companyId/',
          '/company/:companyId/account',
          '/company/:companyId/create-new-calendar',
          '/company/:companyId/calendar/:calendarId/*'
        ].map((path, index) => (
          <Route
            path={path}
            title='dadas'
            render={routeProps => <RouteToRedux {...routeProps} />}
            key={index}
          />
        ))}
        <div id='main-panel' className='main-panel' ref='mainPanel'>
          <Header {...this.props} type='dashboard' notificationOpen={this.handleNotificationClick} />
          <Switch>
            {dashboardRoutes.filter(prop => {
              if (prop.name === 'Admin' && !this.state.admin) {
                return false
              }
              return true
            }).map((prop, key) => {
              if (prop.withNotifications) {
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    render={routeProps => (
                      <prop.component
                        {...routeProps}
                        notificationOpen={this.handleNotificationClick}
                      />
                    )}
                  />
                )
              }
              return (
                <Route path={prop.path} component={props => <prop.component {...props} />} key={key} />
              )
            })}
            <Redirect from='/' componentClass={NavLink} to='/auth/login' />
          </Switch>
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
