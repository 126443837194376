import Dashboard from './../layouts/Dashboard/Dashboard'
import Auth from '../layouts/Auth/Auth'

const indexRoutes = [
  {
    path: '/auth/login',
    component: Auth,
    onlyAuthentificated: false
  },
  {
    path: '/auth/logout',
    component: Auth,
    onlyAuthentificated: false
  },
  {
    path: '/auth/reset-password',
    component: Auth,
    onlyAuthentificated: false
  },
  {
    path: '/auth/register',
    component: Auth,
    onlyAuthentificated: false
  },
  {
    path: '/',
    component: Dashboard,
    onlyAuthentificated: true
  }
]

export default indexRoutes
