import React from 'react'

import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'
import HeaderButtonStyling from './HeaderButtonStyling'

const CalHeader = ({ notificationOpen }) => (
  <div className='content'>
    <HeaderButtonStyling notificationOpen={notificationOpen} />
    <HeaderTop notificationOpen={notificationOpen} />
    <HeaderBottom notificationOpen={notificationOpen} />
  </div>
)

export default CalHeader
