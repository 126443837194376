import axios from 'axios'

const firebaseProject = process.env.REACT_APP_FIREBASE_PROJECT
const cloudFunctionsApi = `https://us-central1-${firebaseProject}.cloudfunctions.net/api`

// const cloudFunctionsApi = `http://localhost:5038/w4-calendar-builder/us-central1/api`

export const Method = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  HEAD: 'head'
}

const axiosRequest = (cloudFunctionsUrl, method, postData) => {
  switch (method) {
    case Method.POST:
      return axios.post(cloudFunctionsUrl, postData)

    case Method.GET:
      return axios.get(cloudFunctionsUrl, postData)

    case Method.PUT:
      return axios.put(cloudFunctionsUrl, postData)

    case Method.DELETE:
      return axios.delete(cloudFunctionsUrl)

    case Method.HEAD:
      return axios.head(cloudFunctionsUrl)

    default:
      return axios.get(cloudFunctionsUrl)
  }
}

export const cloudFunctionRequest = (route, method, postData) => {
  return new Promise((resolve, reject) => {
    const cloudFunctionsUrl = `${cloudFunctionsApi}/${route}`

    axiosRequest(cloudFunctionsUrl, method, postData)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        // add message - used for validation errors shown to the user
        // error.response.message = error.response.data
        reject(error.response)
      })
  })
}

export default cloudFunctionRequest
