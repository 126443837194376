import React, { Component } from 'react'
import Loading from '../../components/Loading/Loading'
import { Grid, Row, Col } from 'react-bootstrap'
import Button from '../../components/CustomButton/CustomButton'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { firebaseDb as db, storageRef as storage } from '../../firebase'
import { connect } from 'react-redux'
import moment from 'moment'

const storageRef = storage.ref()
let listenGenerated

class CalPublish extends Component {
  state = {
    loading: true,
    lastGeneration: '-',
    generating: false,
    generated: false,
    disabled: false,
  }

  componentDidMount() {
    if (this.props.activeCalendar) {
      const activeCompany = this.props.activeCompany
      const activeCalendar = this.props.activeCalendar
      const companiesData = this.props.companiesData

      if(!companiesData[activeCompany].calendars[activeCalendar]) {
        this.setState({
        ...this.state,
        disabled: true
      })
       return;}

      const generated = this.props.demo ? 'generatedDemo' : 'generated'

      let date = this.props.companiesData[activeCompany].calendars[activeCalendar][generated]
      let disabled = !!this.props.companiesData && this.props.companiesData[activeCompany].calendars[activeCalendar].disabled

      if (date) {
        date = date.toDate()
        date = moment(date).format('DD.MM.YYYY HH:mm:ss')
      } else {
        date = '-'
      }

      this.setState({
        ...this.state,
        lastGeneration: date,
        loading: false,
        activeCompany: activeCompany,
        activeCalendar: activeCalendar,
        companiesData: companiesData,
        disabled
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.companiesData !== nextProps.companiesData) {
      const activeCompany = nextProps.activeCompany
      const activeCalendar = nextProps.activeCalendar
      const companiesData = nextProps.companiesData
      if(!companiesData[activeCompany].calendars[activeCalendar]) {
        this.setState({
        ...this.state,
        disabled: true
      })
       return;
      }
      const generated = this.props.demo ? 'generatedDemo' : 'generated'

      let date = nextProps.companiesData[activeCompany].calendars[activeCalendar][generated]
      let disabled = !!nextProps.companiesData && nextProps.companiesData[activeCompany].calendars[activeCalendar].disabled
      if (date) {
        date = date.toDate()
        date = moment(date).format('DD.MM.YYYY HH:mm:ss')
      } else {
        date = '-'
      }

      this.setState({
        ...this.state,
        lastGeneration: date,
        loading: false,
        activeCompany: activeCompany,
        activeCalendar: activeCalendar,
        companiesData: companiesData,
        disabled
      })
    }
  }

  startGeneratorListener(activeCompany, activeCalendar) {
    if(this.state.companiesData && this.state.companiesData[activeCompany].calendars[activeCalendar] && (!this.state.companiesData[activeCompany].calendars[activeCalendar] || this.state.companiesData[activeCompany].calendars[activeCalendar].disabled)) {
      this.setState({
        ...this.state,
        disabled: true
      })
        return;
      }
    listenGenerated = db
      .doc('/companies/' + activeCompany + '/calendars/' + activeCalendar)
      .onSnapshot(
        change => {
          const dateFromDb = this.props.demo ? change.data().generatedDemo : change.data().generated
          if (dateFromDb) {
            const dateHumanFormat = moment(dateFromDb.toDate()).format(
              'DD.MM.YYYY HH:mm:ss'
            )
            if (this.state.lastGeneration !== dateHumanFormat) {
              let newCompaniesData = { ...this.state.companiesData }

              newCompaniesData[activeCompany].calendars[
                activeCalendar
              ].generated = dateFromDb
              this.props.changeCompaniesData(newCompaniesData)
              this.setState({
                ...this.state,
                generated: true,
                generating: false,
                lastGeneration: dateHumanFormat
              })
              listenGenerated()
            }
          }
        },
        error => {
          console.log(error)
        }
      )
  }

  // Ask for file path in storage to generate URL
  getImageUrl(path, file) {
    storageRef
      .child(path + file)
      .getDownloadURL()
      .then(url => {
        // console.log(url)
        return url
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  generateCalendar() {
    // Update field in DB to trigger a json generator
    this.setState({ ...this.state, generating: true, generated: false })

    db.doc(
      '/companies/' +
      this.state.activeCompany +
      '/calendars/' +
      this.state.activeCalendar +
      '/generation/control'
    ).update({
      action: this.props.demo ? 'generateDemo' : 'generate',
      dateAction: new Date()
    })
    this.startGeneratorListener(
      this.state.activeCompany,
      this.state.activeCalendar
    )
  }

  render() {
    const { t, buttonView, demo } = this.props

    let licenceExpires = true

    const currentDate = new Date()
    const dateExpiration =
      this.state.companiesData &&
      this.state.companiesData[this.state.activeCompany].validUntil.toDate()

    if (dateExpiration && dateExpiration < currentDate) {
      licenceExpires = true
    } else {
      licenceExpires = false
    }

    const publishButtonName = demo ? t('calPublish.publishDemo') : t('calPublish.publishBtn')

    if (buttonView) {
      return (
        <>
          {!licenceExpires && (
            <Button
              fill
              bsStyle={demo ? 'info' : 'success'}
              onClick={() => this.generateCalendar()}
              disabled={(this.state.generating && !this.state.generated) || !!this.state.disabled}
              title={`${t('calPublish.lastPublish')} ${this.state.lastGeneration
                }`}
            >
              {this.state.generating && !this.state.generated
                ? t('calPublish.publishing')
                : this.state.generated
                  ? t('calPublish.published')
                  : publishButtonName}
            </Button>
          )}
        </>
      )
    }

    return (
      <div className='content'>
        <Row
          className='show-grid'
          float='center'
          style={
            this.state.loading ? { display: 'block' } : { display: 'none' }
          }
        >
          <Col xs={12} xsOffset={6} valign='middle'>
            <Loading type='spin' color='green' />
          </Col>
        </Row>
        <Grid
          fluid
          style={
            !this.state.loading
              ? { visibility: 'visible' }
              : { visibility: 'hidden' }
          }
        >
          <div style={{ textAlign: 'center' }}>
            <Row>
              <Col xs={0} sm={2} md={3} />
              <Col xs={12} sm={6} md={6} valign='middle'>
                <div className='logo' style={{ textAlign: 'center' }}>
                  <i
                    className='pe-7s-exapnd2'
                    style={{
                      fontSize: '150px',
                      marginBottom: '20px',
                      color: licenceExpires ? 'red' : '#1DC7E7'
                    }}
                  />
                  <h3>{t('calPublish.publish')}</h3>
                  {!licenceExpires ? (
                    <p>{t('calPublish.after')}</p>
                  ) : (
                    <p>{t('calPublish.yourLicenceExpired')}</p>
                  )}
                  <p>
                    {t('calPublish.lastPublish')}{' '}
                    <span>{this.state.lastGeneration}</span>
                  </p>
                </div>

                {!licenceExpires && (
                  <Button
                    fill
                    bsStyle='success'
                    onClick={() => this.generateCalendar()}
                    disabled={(this.state.generating && !this.state.generated) || !!this.state.disabled}
                  >
                    {this.state.generating && !this.state.generated
                      ? t('calPublish.publishing')
                      : this.state.generated
                        ? t('calPublish.published')
                        : t('calPublish.publishBtn')}
                  </Button>
                )}
              </Col>
              <Col xs={0} sm={2} md={3} />
            </Row>
          </div>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeCompaniesData: companiesData =>
      dispatch({ type: 'CHANGE_COMPANIES_DATA', value: companiesData })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate('translations')(CalPublish)))
