import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../../../../components/Card/Card'
import { translate } from 'react-i18next'

import image_0_1 from '../../../../../assets/img/tutorial/0_1.png'
import image_2_2 from '../../../../../assets/img/tutorial/2_2.png'
import image_4_1 from '../../../../../assets/img/tutorial/4_1.png'
import image_4_2 from '../../../../../assets/img/tutorial/4_2.png'
import image_5_4 from '../../../../../assets/img/tutorial/5_4.png'
import image_7_1 from '../../../../../assets/img/tutorial/7_1.png'
import image_8_2 from '../../../../../assets/img/tutorial/8_2.png'
import image_8_3 from '../../../../../assets/img/tutorial/8_3.png'
import image_9_2 from '../../../../../assets/img/tutorial/9_2.png'

class Tutorial extends Component {
  render() {
    const { t } = this.props

    const styleImage = { border: '1px solid #1AC0E7', margin: '5px 0 5px 0', maxWidth: '90%' }
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title='How to set up you Hide And Seek game'
                category=''
                bigTitle
                content={
                  <div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>0. Step</p><h4>What is good to know ?{' '}</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Please note that all images should have a size less than <b>1Mb</b> (background image could have
                        2Mb) and be in <b>PNG or JPG</b> format. Smaller images are the better user experience is.
                        <br />
                        You can start wherever you want with content filling and setting ... but! ... When you go step
                        by step guided by this tutorial you can avoid double work.
                        <br />
                        When you change the content of a game it won't display immediately you have to Publish to see
                        current changes.
                        <br />
                        <br />
                        When you are editing content please use <b>save button</b>. Only images are uploaded without any
                        confirmation and Grid being saved automatically.
                        <br />
                        <img src={image_0_1} alt='Tutorial 0-1' style={styleImage} />
                        <br />
                        <b>Predefined colors</b> are helpful to keep the style consistent - could be set under style
                        settings.
                        <br />
                        <br />
                        Changes could be viewed when the calendar is published under Live preview link at the bottom of
                        the sidebar.
                        <br />
                        <br />
                        <b>Calendar blocking</b> is manageable via settings under Calendar blocking tab.
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>1. Step</p><h4>Edit style</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Start with style setting of your calendar to <b>predefine colors</b> which can be used later in
                        boxes and text editors (extra colors in the toolbar).
                        <br />
                        When editing style you could also upload <b>logo</b>, <b>background image</b> and decide if day
                        number should be on each box.
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>2. Step</p><h4>Set up header</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        The header can be displayed or hidden.
                        <br />
                        <br />
                        When changing the text of header it will adjust the height of the element automatically (default
                        CSS setting).
                        <br />
                        It is better to <b>start with text</b> and then upload the right size of an image.
                        <br />
                        <br />
                        To hide header button just let Link label empty.
                        <br />
                        <img src={image_2_2} alt='Tutorial 2-2' style={styleImage} />
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>3. Step</p><h4>Counter and Newsletter</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>You can edit counter short text and manage newsletter's visibility, short text, and links.</p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>4. Step</p><h4>Game modals</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        In game modal setting you can change the behavior of opening modals if <b>random content</b>{' '}
                        from boxes will pop up or content per clicked box.
                        <br />
                        <img src={image_4_1} alt='Tutorial 4-1' style={styleImage} />
                        <br />
                        <br />
                        Static content for every losing window can be changed in this tab also (if function enabled).
                        <br />
                        <img src={image_4_2} alt='Tutorial 4-2' style={styleImage} />
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>5. Step</p><h4>Boxes</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        You can set as many boxes as you want. In this tab, you only manage the content and style of
                        each box.
                        <br />
                        Each box has two subtabs - one is for pure content (paragraphs and form elements) second is for
                        a setting like an icon, a color of a box and Article image (this Article image will be at the
                        top of a modal content).
                        <br />
                        <br />
                        When creating a form to be sent to Hubspot please fill HUBSPOT FORM ID and add a button with
                        type "submit" to make possible form to be submitted. You can also add hidden field "clicks" with
                        the number of clicks to be sent to the Hubspot.
                        <br />
                        <img src={image_5_4} alt='Tutorial 5-4' style={styleImage} />
                        <br />
                        <br />
                        The composition of boxes is being set under the Grid setting.
                        <br />
                        For default style please remove field "Class" under box properties.
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>6. Step</p><h4>Grid</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Please change grid <b>after all boxes are added</b>.
                        <br />
                        <br />
                        The grid can be changed by drag and dropping boxes. Size of a box can be also changed very
                        easily. When you change position or size it is <b>automatically saved</b> to the database.
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>7. Step</p><h4>Info pages</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Info pages are linked at the bottom of a page, you can add as many info pages as you want. There
                        are two types of info page - <b>external link</b> or <b>pages</b> which is a pop up modal.
                        <br />
                        <img src={image_7_1} alt='Tutorial 7-1' style={styleImage} />
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>8. Step</p><h4>Settings</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Under settings, you will find other options for example title of a calendar, timezone, etc.
                        <br />
                        When you need to add social buttons just put down a link and icon will be displayed at the game
                        page.
                        <br />
                        It is highly recommended to use third-party services like <b>Google Analytics</b> and HubSpot to
                        track visitors and manage data.
                        <br />
                        <br />
                        <b>Hubspot</b> tracking ID will also open a possibility to create cookie banner.
                        <br />
                        <img src={image_8_2} alt='Tutorial 8-2' style={styleImage} />
                        <br />
                        <br />
                        With <b>Google Analytics</b>, you will be able to track the opening of modals.
                        <br />
                        <img src={image_8_3} alt='Tutorial 8-3' style={styleImage} />
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>9. Step</p><h4>Publish a implementation</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        To send current changes of the game page just publish your data via <b>publish button</b>. If it
                        is orange it means that current changes aren't published yet.
                        <br />
                        <img src={image_9_2} alt='Tutorial 9-2' style={styleImage} />
                        <br />
                        <br />
                        Under <b>Implementation</b> tab, you can find embed code to be inserted on your hosting.
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>10. Step</p><h4>Check published content and adjust</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        To see live preview hit the button at the bottom "LIVE PREVIEW". You can make any changes to
                        adjust the content of your game page but please note that without publishing changes you won't
                        see any in the game page.
                      </p>
                    </div>
                  </div>
                }
              />
              <Card
                title='How to duplicate the calendar ?'
                category=''
                content={
                  <div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>0. Step</p><h4>What is good to know ?{' '}</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Every data will be replicated from source calendar except submits. Coping can take up to 30
                        seconds, please be patience.
                      </p>
                    </div>
                    <div className='typo-line'>
                      <h5>
                        <p className='category'>1. Step</p><h4>Select name and copy !</h4>
                      </h5>
                    </div>
                    <div className='typo-line'>
                      <p>
                        Visit settings page and hit "{t('calSettings.duplicateButton')}" button. Select name for your
                        new calendar and copy calendar with button "{t('duplicateCalendar.duplicateButton')}"
                      </p>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default translate('translations')(Tutorial)
