import React, { useState, useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import { Card } from '../../../components/Card/Card'
import ReactQuill from 'react-quill'
import { firebaseDb as db } from '../../../firebase'
import { translate } from 'react-i18next'
import Loading from '../../../components/Loading/Loading'
import Button from '../../../components/CustomButton/CustomButton'
import ReactTooltip from 'react-tooltip'

// Redux
import { useSelector } from 'react-redux'

// Translation model
import { read } from '../../../helpers/translationModel/read'
import { write } from '../../../helpers/translationModel/write'

const CalInfoPage = props => {
  const { t } = props

  const [type, setType] = useState('')
  const [visibility, setVisibility] = useState('')
  const [label, setLabel] = useState('')
  const [text, setText] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const [modules, setModules] = useState('')
  const [loading, setLoading] = useState(true)
  const [dataPages, setDataPages] = useState()

  // Redux
  const {
    activeCompany,
    activeCalendar,
    calendarLang,
    calendarLangPrimary
  } = useSelector(state => state)

  useEffect(() => {
    loadFromDb()
  }, [activeCompany, activeCalendar, calendarLang])

  const loadFromDb = () => {
    activeCompany &&
      activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          // Loaded from translations
          // Replace data with translations
          data = await read(
            activeCompany,
            activeCalendar,
            calendarLangPrimary,
            calendarLang,
            'settings/grid',
            data
          )

          const dataPages = data
          const dataSinglePage = (data.infoModalsList && data.infoModalsList[calendarLang] && data.infoModalsList[calendarLang][props.match.params.pageId]) || {}

          const modules = {
            toolbar: [
              [{ header: [1, 2, false] }],
              [{ align: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
              ],
              ['link', 'image'],
              [{ color: Object.values(data.colors) }, { color: [] }],
              ['clean']
            ],
            clipboard: {
              matchVisual: false
            }
          }

          setType(dataSinglePage.type)
          setVisibility(dataSinglePage.visibility.toString())
          setLabel(dataSinglePage.label)
          setText(dataSinglePage.text)
          setLinkUrl(dataSinglePage.linkUrl)
          setDataPages(dataPages)
          setLoading(false)
          setModules(modules)
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.target)

    let updateDataPages = { ...dataPages }

    if (data) {
      const newObj = {
        label: label,
        linkUrl: linkUrl,
        text: text,
        type: type,
        visibility: visibility === 'true'
      };
      if(updateDataPages.infoModalsList && updateDataPages.infoModalsList[calendarLang]) {
        updateDataPages.infoModalsList[calendarLang][props.match.params.pageId] = newObj
      } else {
        updateDataPages.infoModalsList = {
          ...(updateDataPages.infoModalsList || {}),
          [calendarLang]: updateDataPages.infoModalsList[calendarLang] ? {
            ...updateDataPages.infoModalsList[calendarLang],
            [props.match.params.pageId]: newObj
          } : {
            [props.match.params.pageId]: newObj
          }
        }
      }

      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        updateDataPages
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .then(res => { })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
    }
  }

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'not saved! Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  return (
    <div className='content'>
      <Row
        className='show-grid'
        float='center'
        style={loading ? { display: 'block' } : { display: 'none' }}
      >
        <Col xs={12} xsOffset={6} valign='middle'>
          <Loading type='spin' color='green' />
        </Col>
      </Row>
      <Grid
        fluid
        style={!loading ? { visibility: 'visible' } : { visibility: 'hidden' }}
      >
        <Row>
          <Col md={12}>
            <Card
              title={label}
              category={t('calInfoPage.editInfoPage')}
              content={
                <form onSubmit={handleSubmit}>
                  <FormGroup controlId='formControlsSelect'>
                    <i
                      className='pe-7s-help1 text-success'
                      data-tip={t('infoPages.visibility')}
                      style={{
                        position: 'relative',
                        top: '1px',
                        padding: '5px 5px 0 0',
                        fontSize: '15px'
                      }}
                    />
                    <ControlLabel>{t('calInfoPage.visibility')}</ControlLabel>
                    <FormControl
                      componentClass='select'
                      onChange={e => {
                        setVisibility(e.target.value)
                      }}
                      name='visibility'
                      value={visibility}
                      placeholder='select'
                    >
                      <option value='true'>
                        {t('calInfoPage.visibilityYes')}
                      </option>
                      <option value='false'>
                        {t('calInfoPage.visibilityNo')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  <div
                    style={{
                      display: visibility === 'true' ? 'block' : 'none'
                    }}
                  >
                    <FormGroup controlId='formControlsSelect'>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('infoPages.type')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>{t('calInfoPage.type')}</ControlLabel>
                      <FormControl
                        componentClass='select'
                        onChange={e => {
                          setType(e.target.value)
                        }}
                        name='type'
                        value={type}
                        placeholder='select'
                      >
                        <option value='page'>
                          {t('calInfoPage.typePage')}
                        </option>
                        <option value='link'>
                          {t('calInfoPage.typeLink')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('infoPages.label')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>{t('calInfoPage.label')}</ControlLabel>
                      <FormControl
                        type='text'
                        name='label'
                        id='label'
                        value={label}
                        onChange={e => {
                          setLabel(e.target.value)
                        }}
                        placeholder={t('calInfoPage.visibleLabel')}
                      />
                    </FormGroup>

                    <FormGroup
                      controlId='formControlsTextarea'
                      style={{
                        display: type === 'page' ? 'block' : 'none'
                      }}
                    >
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('infoPages.content')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>{t('calInfoPage.content')}</ControlLabel>
                      {modules && (
                        <ReactQuill
                          modules={modules}
                          value={text}
                          name='text'
                          onChange={(value) => setText(value)}
                        />
                      )}
                    </FormGroup>
                    <FormGroup
                      style={{
                        display: type === 'link' ? 'block' : 'none'
                      }}
                    >
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('infoPages.linkToExternalWebsite')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calInfoPage.linkToExternalWebsite')}
                      </ControlLabel>
                      <FormControl
                        type='text'
                        name='linkUrl'
                        id='linkUrl'
                        value={linkUrl}
                        placeholder={t('calInfoPage.linkUrlDesc')}
                        onChange={e => {
                          setLinkUrl(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </div>
                  <Button bsStyle='info' pullRight fill type='submit'>
                    {t('calInfoPage.updatePage')}
                  </Button>
                  <div className='clearfix' />

                  <ReactTooltip place='top' type='dark' effect='solid' />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default translate('translations')(CalInfoPage)
