import Login from '../views/Auth/Login'
import Register from '../views/Auth/Register'
import ResetPassword from '../views/Auth/ResetPassword'
import Logout from '../views/Auth/Logout'

const AuthRoutes = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/auth/reset-password',
    name: 'Reset Password',
    component: ResetPassword
  }
]

export default AuthRoutes
