import React, { useState } from 'react'

import Button from '../../components/CustomButton/CustomButton'
import { Grid, Row, Col, HelpBlock, FormGroup } from 'react-bootstrap'
import { FormInputs } from '../../components/FormInputs/FormInputs'
import { translate } from 'react-i18next'
import { firebaseDb as db } from '../../firebase'
import Loading from '../../components/Loading/Loading'
import DataFromDb from '../../helpers/DataFromDb'

// Redux
import { useSelector, useDispatch } from 'react-redux'

const CreateNewCalendar = props => {
  const dispatch = useDispatch()
  const { history } = props
  const [errorName, setErrorName] = useState(null)
  const [isCreated, setIsCreated] = useState(false)
  const [newCalendarId, setNewCalendarId] = useState(null)
  const [addingCalendar, setAddingCalendar] = useState(false)
  const [copyMessage, setCopyMessage] = useState('data')

  const { activeCompany, companiesData, activeCalendar } = useSelector(
    state => state
  )

  const handleSubmit = event => {
    event.preventDefault()
    const { t } = props

    let activeCalendarType

    if (companiesData && activeCompany && activeCalendar) {
      activeCalendarType =
        companiesData[activeCompany].calendars[activeCalendar].type
    }

    let errorName
    const data = new FormData(event.target)
    const name = data.get('name')

    if (name) {
      setAddingCalendar(true)
      setErrorName('')
      setCopyMessage('data')

      // Create new calendar and then do separated tasks below
      db.collection('companies/' + activeCompany + '/calendars')
        .add({
          name: name,
          type: activeCalendarType,
          changeToPublish: false,
          sourceCalendarId: activeCalendar,
          copyMessage: 'data',
          generated: new Date()
        })
        .then(ref => {
          const calendarId = ref.id

          // Listen for changing in firebase cloud functions
          db.doc(
            'companies/' + activeCompany + '/calendars/' + calendarId
          ).onSnapshot(doc => {
            const data = doc.data()
            if (data.copyMessage === 'done') {
              setIsCreated(true)
              setNewCalendarId(calendarId)
            } else {
              setCopyMessage(data.copyMessage)
            }
          })
        })
    } else {
      if (!name) {
        errorName = t('duplicateCalendar.calendarNameIsRequired')
      }
      // this.setState({ ...this.state, errorName: errorName })
      setErrorName(errorName)
    }
  }
  const redirectToCalendar = (companyId, calendarId, page = 'dashboard') => {
    dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: calendarId })
    history.push(
      '/company/' + companyId + '/calendar/' + calendarId + '/' + page
    )
  }

  let defaultNameDuplicated
  let activeCalendarName

  if (companiesData && activeCompany && activeCalendar) {
    activeCalendarName =
      companiesData[activeCompany].calendars[activeCalendar].name
    defaultNameDuplicated = activeCalendarName + ' (copy)'
  }

  const { t } = props
  return (
    <div className='content'>
      {isCreated ? (
        <div style={{ textAlign: 'center' }}>
          <DataFromDb />
          <i
            className='pe-7s-check'
            style={{
              fontSize: '150px',
              marginBottom: '20px',
              color: 'green'
            }}
          />
          <h3>{t('duplicateCalendar.newCalendarCreated')}</h3>
          <p>{t('duplicateCalendar.letsDoSomethingExtra')}</p>
          {!props.withoutbutton && (
            <Button
              fill
              bsStyle='success'
              onClick={() => redirectToCalendar(activeCompany, newCalendarId)}
            >
              {t('duplicateCalendar.editNewCalendar')}
            </Button>
          )}
        </div>
      ) : companiesData &&
        companiesData[activeCompany].calendarsLimit <=
          companiesData[activeCompany].numCalendars ? (
        <div style={{ textAlign: 'center' }}>
          <i
            className='pe-7s-close'
            style={{ fontSize: '150px', marginBottom: '20px', color: 'red' }}
          />
          <h3>
            {t('duplicateCalendar.limitReached')}{' '}
            {companiesData[activeCompany].calendarsLimit +
              '/' +
              companiesData[activeCompany].calendarsLimit}
          </h3>
          <p>{t('duplicateCalendar.limitReachedPlease')}</p>
        </div>
      ) : (
        <div>
          <Grid>
            <Row>
              <Col xs={0} sm={3} md={4} />
              <Col xs={12} sm={6} md={4}>
                <div className='logo' style={{ textAlign: 'center' }}>
                  <i
                    className='pe-7s-copy-file'
                    style={{
                      fontSize: '150px',
                      marginBottom: '20px',
                      color: 'green'
                    }}
                  />
                  <h3>{t('duplicateCalendar.duplicateCalendar')}</h3>
                  <FormInputs
                    ncols={['col-md-12']}
                    proprieties={[
                      {
                        label: null,
                        type: 'text',
                        id: 'test',
                        name: 'name',
                        disabled: true,
                        bsClass: 'form-control',
                        defaultValue: activeCalendarName,
                        placeholder: ''
                      }
                    ]}
                  />
                  <i
                    className='pe-7s-angle-down'
                    style={{
                      fontSize: '150px',
                      marginBottom: '0px',
                      color: 'green'
                    }}
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    controlId='formBasicText'
                    validationState={errorName ? 'error' : null}
                  >
                    <FormInputs
                      ncols={['col-md-12']}
                      proprieties={[
                        {
                          label: t('duplicateCalendar.name'),
                          type: 'text',
                          id: 'test',
                          name: 'name',
                          bsClass: 'form-control',
                          defaultValue: defaultNameDuplicated,
                          placeholder: '',
                          disabled: addingCalendar
                        }
                      ]}
                    />
                    {errorName && <HelpBlock>{errorName}</HelpBlock>}
                  </FormGroup>
                  {addingCalendar && (
                    <FormGroup controlId='formBasicText'>
                      <HelpBlock>
                        {t('duplicateCalendar.coping')}{' '}
                        {t('duplicateCalendar.copyMessage.' + copyMessage)}
                      </HelpBlock>
                    </FormGroup>
                  )}
                  {addingCalendar ? (
                    <Button fill bsStyle='success'>
                      <Loading
                        type='spin'
                        color='white'
                        height='20'
                        width='20'
                      />
                    </Button>
                  ) : (
                    <Button fill bsStyle='success' type='submit'>
                      {t('duplicateCalendar.duplicateButton')}
                    </Button>
                  )}
                </form>
              </Col>
              <Col xs={0} sm={3} md={4} />
            </Row>
          </Grid>
        </div>
      )}
    </div>
  )
}
export default translate('translations')(CreateNewCalendar)
