import React, { useEffect, useRef, useState } from 'react'
import { Modal, Input } from 'antd';

const ModalInput = (props) => {
  const [text, setText] = useState(props.defaultValue)

  const onOk = () => {
    if (props.onOk) {
      props.onOk(text)
    }
  }
  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onOk()
    }
  }

  const inputRef = useRef()

  useEffect(() => {
    setText(props.defaultValue)
    if (inputRef && inputRef.current) {
      const { input } = inputRef.current
      input.focus()
    }
  }, [props.visible])

  return (
    <Modal
      visible={props.visible}
      onOk={onOk}
      onCancel={onCancel}
      width='30%'
      zIndex={7000}
      centered
      status={props.error && 'error'}
      okButtonProps = {{
        disabled: !!props.disabledOk && !text
      }}
      confirmLoading={props.confirmLoading === true}
    >
      {props.text}
      <Input
        type={props.type || 'text'}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        autoFocus
        status={props.error && 'error'}
      />
    </Modal>
  )
}

export default ModalInput