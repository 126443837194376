import React, { useEffect, useRef, useState } from 'react'
import { FullFileBrowser, ChonkyActions } from 'chonky';
import { notification, Modal, Spin } from 'antd';

import './FileBrowser.css'

import { firebaseAuth } from '../../../firebase'
import {
  getFiles,
  createFolder,
  renameFolder,
  renameFile,
  moveFiles,
  deleteFiles,
  uploadFiles,
  initializeStorage,
  getDownloadURL
} from './Functions'
import ModalInput from '../../../components/ModalInput/ModalInput';
import { DeleteAction, GetURLAction, PreviewFileAction, RenameFileAction, RenameFolderAction } from './Actions';

const fileActions = [
  ChonkyActions.CreateFolder,
  ChonkyActions.UploadFiles,
  ChonkyActions.MoveFiles,
  RenameFolderAction,
  RenameFileAction,
  DeleteAction,
  GetURLAction,
  PreviewFileAction
]

// Remove unnecessary options
ChonkyActions.SortFilesBySize.button = null
ChonkyActions.ToggleHiddenFiles.button = null

export const FileBrowser = (props) => {
  const fileBrowserRef = useRef(null);
  const inputFile = useRef(null)
  const [userToken, setUserToken] = useState()
  const [activeCompany, setActiveCompany] = useState()
  const [files, setFiles] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [renameFileName, setRenameFileName] = useState()
  const [renameFolderName, setRenameFolderName] = useState()
  const [newFolderVisible, setNewFolderVisible] = useState(false)
  const [previewURL, setPreviewURL] = useState()
  const [storageInitialized, setStorageInitialized] = useState(false)
  const [deletedFiles, setDeletedFiles] = useState([])
  const [folderChain, setFolderChain] = useState([])
  const path = 'company/' + props.activeCompany + '/' + folderChain.map(folder => folder.name).join("/")

  useEffect(() => {
    if (activeCompany) {
      setFolderChain([{ id: 'company/' + activeCompany + '/storage', name: 'storage', isDir: true }])
    }
  }, [activeCompany])

  const handleAction = async (data) => {
    if (data.payload && data.id === ChonkyActions.OpenFiles.id) {
      const targetFile = data.payload.targetFile
      // const files = data.payload.files
      if (targetFile.isDir) {
        const fileIndex = folderChain.indexOf(targetFile)
        if (fileIndex != -1) {
          const folderChainTmp = folderChain.slice(0, fileIndex + 1)
          setFolderChain(folderChainTmp)
        } else {
          setFolderChain([...folderChain, targetFile])
        }
      }
    } else if (data.id === 'delete') {
      // Delete the files
      setModalVisible(!modalVisible)
      setDeletedFiles(data.state.selectedFiles)
    } else if (data.id === ChonkyActions.CreateFolder.id) {
      // Create folder
      setNewFolderVisible(true)
    } else if (data.id === 'rename_file' && data.state.selectedFiles.length === 1) {
      // Rename file
      setRenameFileName(data.state.selectedFiles[0])
    } else if (data.id === 'rename_folder' && data.state.selectedFiles.length === 1) {
      // Rename folder
      setRenameFolderName(data.state.selectedFiles[0])
    } else if (data.id === ChonkyActions.MoveFiles.id) {
      // Move files
      const sourceFiles = data.payload.files
      const tmpFiles = [...files]
      sourceFiles.forEach(sourceFile => {
        const tmpIndex = tmpFiles.findIndex(file => file && file.id === sourceFile.id)
        tmpFiles[tmpIndex] = null
      });
      setFiles(tmpFiles)
      await moveFiles(userToken, sourceFiles, data.payload.destination.id)
      const filesFromFirebase = await getFiles(path)
      setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
    } else if (data.id === ChonkyActions.UploadFiles.id) {
      inputFile.current.click();
    } else if (data.id === GetURLAction.id) {
      // Get download URL from firebase
      const url = await getDownloadURL(data.state.selectedFiles[0].id)
      navigator.clipboard.writeText(url)
      openNotificationWithIcon('success', 'URL is copied to clipboard')
    } else if (data.id === ChonkyActions.ChangeSelection.id) {
      const selectedFile = data.state.selectedFiles[0]
      if (props.onSelect) {
        if (!selectedFile) {
          props.onSelect(undefined)
          return
        }
        if (selectedFile.isDir || data.state.selectedFiles.length > 1) {
          props.onSelect(undefined)
        } else {
          props.onSelect(selectedFile)
        }
      }
    } else if (data.id === ChonkyActions.ClearSelection.id) {
      if (props.onSelect) {
        props.onSelect(undefined)
      }
    } else if (data.id === 'preview_file') {
      if (data.state.selectedFiles.length === 1) {
        previewImage(data.state.selectedFiles[0])
      } else {
        openNotificationWithIcon('error', 'Please select only one file')
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (activeCompany) {
        const filesFromFirebase = await getFiles(path)
        setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
      }
    }
    fetchData();
  }, [folderChain, activeCompany])

  useEffect(() => {
    if (props.activeCompany) {
      setActiveCompany(props.activeCompany)
      initializeStorage(props.activeCompany).then(() => setStorageInitialized(true)).catch(() => setStorageInitialized(false))
    }
  }, [props.activeCompany])

  useEffect(() => {
    firebaseAuth.currentUser
      .getIdTokenResult()
      .then(idTokenResult => {
        setUserToken(idTokenResult.token)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const handleUpload = async (e) => {
    const uploadedFiles = Array.from(e.currentTarget.files)
    if (!uploadedFiles)
      return;
    const tmpFiles = Array(uploadedFiles.length).fill(null)
    const filteredFiles = []
    const removedFiles = []
    let removedFilesMessages = 'These files are larger than 5MB:\n'
    uploadedFiles.forEach((file) => {
      const fileLimit = 5 * 1024 * 1024
      if (file.size > fileLimit) {
        removedFiles.push(file)
        removedFilesMessages += `${file.name}\n`
      } else {
        filteredFiles.push(file)
      }
    })
    if (filteredFiles.length > 0) {
      setFiles([...files, ...tmpFiles])
      await uploadFiles(path, filteredFiles)
      const filesFromFirebase = await getFiles(path)
      setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
      setTimeout(async () => {
        const filesFromFirebase = await getFiles(path)
        setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
      }, 3000);
    }
    if (removedFiles.length > 0) {
      openNotificationWithIcon('error', removedFilesMessages)
    }
  }

  const handleDeleteFiles = async () => {
    setModalVisible(!modalVisible)
    const tmpFiles = [...files]
    deletedFiles.forEach(sourceFile => {
      const tmpIndex = tmpFiles.findIndex(file => file && file.id === sourceFile.id)
      tmpFiles[tmpIndex] = null
    });
    setFiles(tmpFiles)
    await deleteFiles(deletedFiles)
    setDeletedFiles([])
    const filesFromFirebase = await getFiles(path)
    setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
  }

  const handleDeleteCancel = () => {
    setModalVisible(!modalVisible)
    setDeletedFiles([])
  }

  const previewImage = (selectedFile) => {
    const validType = selectedFile.metadata.contentType.startsWith('image')
    if (validType) {
      getDownloadURL(selectedFile.id).then((url) => {
        setPreviewURL(url)
      })
    } else {
      openNotificationWithIcon('error', 'Please select a file with valid type')
    }
  }

  const handleNewFolderName = async (name) => {
    setNewFolderVisible(false)
    setFiles([...files, null])
    await createFolder(path, name, openNotificationWithIcon).catch(err => console.log(err))
    const filesFromFirebase = await getFiles(path)
    setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
  }

  const handleChangeFolderName = async (name) => {
    const selectedFile = renameFolderName
    setRenameFolderName(undefined)
    const tmpFiles = [...files]
    const tmpIndex = tmpFiles.findIndex(file => file.id === selectedFile.id)
    tmpFiles[tmpIndex] = null
    setFiles(tmpFiles)
    await renameFolder(userToken, path, selectedFile.name, name)
    const filesFromFirebase = await getFiles(path)
    setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
  }

  const handleChangeFileName = async (name) => {
    const selectedFile = renameFileName
    setRenameFileName(undefined)
    const tmpFiles = [...files]
    const tmpIndex = tmpFiles.findIndex(file => file.id === selectedFile.id)
    tmpFiles[tmpIndex] = null
    setFiles(tmpFiles)
    await renameFile(userToken, path, selectedFile.name, name)
    const filesFromFirebase = await getFiles(path)
    setFiles([...filesFromFirebase.files, ...filesFromFirebase.folders])
  }

  return (
    <div style={{ height: 600 }}>
      <Modal
        okText="Delete"
        cancelText="Cancel"
        centered
        visible={modalVisible}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteFiles}>
        Are you sure to delete this file?
      </Modal>
      <Modal
        centered
        visible={previewURL !== undefined}
        onCancel={() => setPreviewURL(undefined)}
        footer={null}
        zIndex={6000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={previewURL} style={{ maxWidth: '100%' }} />
        </div>
      </Modal>
      <ModalInput
        text='Please enter folder name'
        visible={newFolderVisible}
        onCancel={() => { setNewFolderVisible(false) }}
        onOk={handleNewFolderName}
      />
      <ModalInput
        text='Please enter folder name'
        defaultValue={renameFolderName && renameFolderName.name}
        visible={renameFolderName !== undefined}
        onCancel={() => { setRenameFolderName(undefined) }}
        onOk={handleChangeFolderName}
      />
      <ModalInput
        text='Please enter file name'
        defaultValue={renameFileName && renameFileName.name}
        visible={renameFileName !== undefined}
        onCancel={() => { setRenameFileName(undefined) }}
        onOk={handleChangeFileName}
      />
      {
        storageInitialized && activeCompany ?
          <FullFileBrowser
            files={files}
            ref={fileBrowserRef}
            folderChain={folderChain}
            onFileAction={handleAction}
            fileActions={fileActions}
            disableDragAndDropProvider={props.disableDragAndDropProvider}
          />
          : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Spin tip="Initializing storage..." />
          </div>
      }
      <input type='file' id='file' onChange={handleUpload} ref={inputFile} multiple style={{ display: 'none' }} />
    </div>
  );
}

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: type ==='success' ? 'Success' : 'Error',
    description: message,
    placement: 'bottomRight'
  });
};

