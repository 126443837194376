import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { StatsCard } from './../../components/StatsCard/StatsCard'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class Dashboard extends Component {
  createLegend(json) {
    var legend = []
    for (var i = 0; i < json['names'].length; i++) {
      var type = 'fa fa-circle text-' + json['types'][i]
      legend.push(<i className={type} key={i} />)
      legend.push(' ')
      legend.push(json['names'][i])
    }
    return legend
  }
  redirectToCompany = companyId => {
    this.props.changeActiveCompany(companyId)
    this.props.history.push('/company/' + companyId + '/dashboard')
  }
  redirectToCalendar = (calendarId, page = 'dashboard') => {
    this.props.changeActiveCalendar(calendarId)
    this.props.history.push('/company/' + this.props.activeCompany + '/calendar/' + calendarId + '/' + page)
  }
  render() {
    const { t } = this.props

    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            {this.props.companiesData &&
              this.props.companiesData[this.props.activeCompany] &&
              (this.props.companiesData[this.props.activeCompany].numCalendars > 0 || this.props.companiesData[this.props.activeCompany].numDisabledCalendars > 0) ? (
              _.map({...this.props.companiesData[this.props.activeCompany].calendars, ...(this.props.companiesData[this.props.activeCompany].disabledCalendars || {})}, (calendar, index) => {
                return (
                  <Col lg={4} sm={6} key={index}>
                    <div style={{ cursor: 'pointer' }} onClick={() => this.redirectToCalendar(index)} >
                      <StatsCard
                        bigIcon={calendar.disabled ? <i className='pe-7s-trash text-danger' /> : <i className='pe-7s-date text-info' />}
                        statsText={calendar.type}
                        statsValue={calendar.name}
                        disabled={calendar.disabled}
                        statsIcon={
                          <div>
                            <div
                              className={`btn btn-xs ${calendar.disabled ? "btn-danger" : "btn-success"}`}
                              onClick={() => this.redirectToCalendar(index)}
                              style={{ marginLeft: '5px' }}
                            >
                              {t('dashboard.company.dashboard')}
                            </div>
                            {!calendar.disabled && <div
                              className='btn btn-xs btn-success'
                              onClick={() => this.redirectToCalendar(index, 'calsettings')}
                              style={{ marginLeft: '5px' }}
                            >
                              {t('dashboard.company.settings')}
                            </div>}
                          </div>
                        }
                      />
                    </div>
                  </Col>
                )
              })
            ) : (
              <Col lg={3} sm={6}>
                <Link style={{ color: '#87CB16' }} to={'/company/' + this.props.activeCompany + '/create-new-calendar'}>
                  <StatsCard
                    bigIcon={<i className='pe-7s-plus text-green' />}
                    statsText={t('dashboard.company.createNewCalendar')}
                    statsValue={t('dashboard.company.calendar')}
                  />
                </Link>
              </Col>
            )}
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeActiveCalendar: activeCalendar => dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: activeCalendar })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(Dashboard))
