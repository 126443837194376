import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { notification, Popconfirm, Space, Table } from 'antd'
import { RedoOutlined, DeleteOutlined } from '@ant-design/icons'
import './Backup.css'
import Button from '../../components/CustomButton/CustomButton'
import { firebaseDb as db } from '../../firebase'
import languagesData from '../../data/languages.json'


const createBackup = async (activeCompany, activeCalendar) => {
  const calendarPrefix = `companies/${activeCompany}/calendars/${activeCalendar}`
  const daysRef = await db.collection(`${calendarPrefix}/days`).get()
  const settingsRef = await db.doc(`${calendarPrefix}/settings/grid`).get()
  const days = daysRef.docs.map(doc => { return { [doc.id]: doc.data() } })
  const settings = settingsRef.exists && settingsRef.data()
  const document = await db.collection(`${calendarPrefix}/backups`).add({ createdAt: new Date() })
  await db.collection(`${calendarPrefix}/backups/${document.id}/settings`).doc('grid').set(settings)
  for (let day of days) {
    const dayNumber = Object.keys(day)[0]
    await db.collection(`${calendarPrefix}/backups/${document.id}/days`).doc(`${dayNumber}`).set(day[dayNumber])
  }
  for (const language of Object.entries(languagesData)) {
    const translationPrefix = `${calendarPrefix}/translation/${language[0]}`
    const translatedDaysRef = await db.collection(`${translationPrefix}/days`).get()
    const translatedDays = translatedDaysRef.docs.map(doc => { return { [doc.id]: doc.data() } })
    const translatedSettingsRef = await db.doc(`${translationPrefix}/settings/grid`).get()
    const translatedSettings = translatedSettingsRef.exists && translatedSettingsRef.data()
    if (translatedSettings) {
      await db.collection(`${calendarPrefix}/backups/${document.id}/translations/${language[0]}/settings`).doc('grid').set(translatedSettings)
    }
    if (translatedDays.length) {
      for (let day of translatedDays) {
        const dayNumber = Object.keys(day)[0]
        await db.collection(`${calendarPrefix}/backups/${document.id}/translations/${language[0]}/days`).doc(`${dayNumber}`).set(day[dayNumber])
      }
    }
  }
}


export const Backup = (props) => {
  const { activeCalendar, activeCompany, t } = props
  const calendarPrefix = `companies/${activeCompany}/calendars/${activeCalendar}`
  const [backups, setBackups] = useState([])
  const [loading, setLoading] = useState(true)
  const [backupDisabled, setBackupDisabled] = useState(false)

  const restoreBackup = async (backup) => {
    const { id } = backup
    const calendarPrefix = `companies/${activeCompany}/calendars/${activeCalendar}`
    const backupPrefix = `companies/${activeCompany}/calendars/${activeCalendar}/backups/${id}`
    const buDaysRef = await db.collection(`${backupPrefix}/days`).get()
    const buSettingsRef = await db.doc(`${backupPrefix}/settings/grid`).get()
    const buDays = buDaysRef.docs.map(doc => { return { [doc.id]: doc.data() } })
    const buSettings = buSettingsRef.exists && buSettingsRef.data()
    if (buSettings) {
      await db.collection(`${calendarPrefix}/settings`).doc('grid').set(buSettings)
    }
    // Delete days before restoring the backup
    const daysRef = await db.collection(`${calendarPrefix}/days`).get()
    for (const day of daysRef.docs) {
      await day.ref.delete()
    }
    for (let day of buDays) {
      const dayNumber = Object.keys(day)[0]
      await db.collection(`${calendarPrefix}/days`).doc(`${dayNumber}`).set(day[dayNumber])
    }
    for (const language of Object.entries(languagesData)) {
      const translationPrefix = `${calendarPrefix}/translation/${language[0]}`
      const translatedDaysRef = await db.collection(`${translationPrefix}/days`).get()
      // Delete days before restoring the backup
      for (const day of translatedDaysRef.docs) {
        await day.ref.delete()
      }
      await db.collection(`${translationPrefix}/settings`).doc('grid').delete()

      const buTranslationPrefix = `${calendarPrefix}/backups/${id}/translations/${language[0]}`
      const buTranslatedDaysRef = await db.collection(`${buTranslationPrefix}/days`).get()
      const buTranslatedDays = buTranslatedDaysRef.docs.map(doc => { return { [doc.id]: doc.data() } })
      const buTranslatedSettingsRef = await db.doc(`${buTranslationPrefix}/settings/grid`).get()
      const buTranslatedSettings = buTranslatedSettingsRef.exists && buTranslatedSettingsRef.data()
      if (buTranslatedSettings) {
        await db.collection(`${translationPrefix}/settings`).doc('grid').set(buTranslatedSettings)
      }
      if (buTranslatedDays.length) {
        for (let day of buTranslatedDays) {
          const dayNumber = Object.keys(day)[0]
          await db.collection(`${translationPrefix}/days`).doc(`${dayNumber}`).set(day[dayNumber])
        }
      }
    }
    openNotificationWithIcon('success', t('backupPage.restoreSuccess'))
    // Need to reload the page to read language changes
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  const deleteBackup = async (backup) => {
    const { id } = backup
    const daysRef = await db.collection(`${calendarPrefix}/backups/${id}/days`).get()
    for (const day of daysRef.docs) {
      await day.ref.delete()
    }
    await db.collection(`${calendarPrefix}/backups/${id}/settings`).doc('grid').delete()
    for (const language of Object.entries(languagesData)) {
      const translationPrefix = `${calendarPrefix}/backups/${id}/translations/${language[0]}`
      const translatedDaysRef = await db.collection(`${translationPrefix}/days`).get()
      for (const day of translatedDaysRef.docs) {
        await day.ref.delete()
      }
      await db.collection(`${translationPrefix}/settings`).doc('grid').delete()
    }
    return await db.collection(`${calendarPrefix}/backups`).doc(id).delete().then(() => getBackups())
  }

  const getBackups = async () => {
    try {
      const snapshot = await db.collection(`${calendarPrefix}/backups`).get()
      const backupList = snapshot.docs.map(doc => {
        const data = doc.data()
        const createdAt = data.createdAt
        // const createdAt = data.createdAt.toDate().toLocaleString()
        return {
          id: doc.id,
          key: doc.id,
          date: createdAt
        }
      })
      setBackups(backupList)
      setLoading(false)
    } catch (error) {
      setBackups([])
      setLoading(false)
    }
  }

  useEffect(() => {
    setBackupDisabled(backups && backups.length === 2)
  }, [backups])


  const handleCreateBackup = async () => {
    setBackupDisabled(true)
    setLoading(true)
    await createBackup(activeCompany, activeCalendar)
    setBackupDisabled(false)
    await getBackups()
  }

  useEffect(() => {
    if (activeCompany && activeCalendar) {
      getBackups()
    }
  }, [activeCalendar, activeCompany])

  const columns = [
    {
      title: 'Backup ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <Space size='small'>
          {record.date.toDate().toLocaleString()}
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <Popconfirm
            placement="rightTop"
            title={
              <div style={{ maxWidth: 500 }}>
                {`${t('backupPage.restoreConfirmation')} ${record.date.toDate().toLocaleString()}`}
              </div>
            }
            onConfirm={() => restoreBackup(record)}
            okText="Yes"
            cancelText="No"
          >
            <RedoOutlined />
          </Popconfirm>
          <Popconfirm
            placement="rightTop"
            title={t('backupPage.deleteConfirmation')}
            onConfirm={() => deleteBackup(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div className='backup-container'>
      <Table
        className='backup-table'
        columns={columns}
        dataSource={backups}
        showSorterTooltip={false}
        pagination={false}
        loading={loading}
      />
      <Button
        bsStyle='info'
        pullRight
        fill
        type='submit'
        disabled={backupDisabled}
        onClick={handleCreateBackup}>
        {t('backupPage.backupNow')}
      </Button>
    </div>
  )
}

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: type === 'success' ? 'Success' : 'Error',
    description: message,
    placement: 'bottomRight'
  });
};

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(Backup))
