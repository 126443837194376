import React, { Component } from 'react'
import { Grid } from 'react-bootstrap'
import { translate } from 'react-i18next'

class Footer extends Component {
  render () {
    const { t } = this.props

    return (
      <footer className='footer'>
        <Grid fluid>
          {/* <nav className='pull-left'>
            <ul>
              <li>
                <a href='#pablo'>{t('footer.home')}</a>
              </li>
              <li>
                <a href='#pablo'>{t('footer.company')}</a>
              </li>
              <li>
                <a href='#pablo'>{t('footer.portfolio')}</a>
              </li>
              <li>
                <a href='#pablo'>{t('footer.blog')}</a>
              </li>
            </ul>
          </nav> */}
          <p className='copyright pull-right'>
            &copy; {new Date().getFullYear()} SPIELO.app powered by{' '}
            <a href='https://www.w-4.ch'>W-4</a>, {t('footer.copyright')}
          </p>
        </Grid>
      </footer>
    )
  }
}

export default translate('translations')(Footer)
