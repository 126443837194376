import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firebaseDb as db, firebaseAuth } from '../firebase'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

class DataFromDb extends Component {
  componentDidMount () {
    // CUSTOM CLAIMS
    firebaseAuth.currentUser
      .getIdTokenResult()
      .then(idTokenResult => {
        const companies = idTokenResult.claims.companies
        this.fetchFromDbToStore(companies)
      })
      .catch(error => {
        console.log(error)
      })
    // END CUSTOM CLAIMS
  }
  fetchFromDbToStore = companies => {
    const numCompanies = _.size(companies)
    let numEach = 0
    let companiesData = {}
    let disabledCompanyCalendarsData = {}
    _.forEach(companies, (_, index) => {
      db.doc('companies/' + index)
        .get()
        .then(querySnapshot => {
          let data = querySnapshot.data()
          companiesData[index] = data

          let companyCalendars = {}
          db.collection('companies/' + index + '/calendars')
            .get()
            .then(function (querySnapshot2) {
              querySnapshot2.forEach(doc => {
                const data = doc.data()
                if(data.disabled === false || !data.disabled) {
                  companyCalendars[doc.id] = data
                } else {
                  disabledCompanyCalendarsData[doc.id] = data
                }
              })
            })
            .then(res => {
              companiesData[index].calendars = companyCalendars
              companiesData[index].disabledCalendars = disabledCompanyCalendarsData
              if (Object.getOwnPropertyNames(companyCalendars).length === 0) {
                companiesData[index].numCalendars = 0
              } else {
                companiesData[index].numCalendars = Object.getOwnPropertyNames(companyCalendars).length 
              }
              if (Object.getOwnPropertyNames(disabledCompanyCalendarsData).length === 0) {
                companiesData[index].numDisabledCalendars = 0
              } else {
                companiesData[index].numDisabledCalendars = Object.getOwnPropertyNames(disabledCompanyCalendarsData).length 
              }
              numEach = numEach + 1
              const pathname = this.props.location.pathname;
              const companyId = pathname.split('/')[2];
              const calendarId = pathname.split('/')[4];
              if (numCompanies === numEach) {
                this.props.changeCompaniesData(companiesData)
              }
              if (companyId && calendarId && companiesData && companiesData[companyId] && companiesData[companyId].calendars && !companiesData[companyId].calendars[calendarId] && !this.props.location.pathname.includes('/dashboard')){
                this.props.changeActiveCalendar(null)
                this.props.history.replace('/dashboard')
              }
            })
        })
    })
  }
  render () {
    return <span />
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    changeCompaniesData: companiesData => dispatch({ type: 'CHANGE_COMPANIES_DATA', value: companiesData }),
    changeActiveCalendar: activeCalendar => dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: activeCalendar }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DataFromDb))
