import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AuthRoutes from '../../routes/auth'

import { style } from '../../variables/Variables'

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.handleNotificationClick = this.handleNotificationClick.bind(this)
    this.state = {
      _notificationSystem: null
    }
  }
  handleNotificationClick (position, icon, type, text) {
    this.state._notificationSystem.addNotification({
      title: <span data-notify='icon' className={icon} />,
      message: <div>{text}</div>,
      level: type,
      position: position,

      autoDismiss: 15
    })
  }
  componentDidMount () {
    this.setState({ _notificationSystem: this.refs.notificationSystem })
  }
  componentDidUpdate (e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainPanel.scrollTop = 0
    }
  }
  render () {
    return (
      <div className='wrapper'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <div id='main-panel' className='main-panel-auth' ref='mainPanel'>
          <Header {...this.props} type='auth' />
          <Switch>
            {AuthRoutes.map((prop, key) => {
              if (prop.withNotifications) {
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    render={routeProps => (
                      <prop.component {...routeProps} notificationOpen={this.handleNotificationClick} />
                    )}
                  />
                )
              }
              return <Route path={prop.path} component={prop.component} key={key} />
            })}
          </Switch>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Dashboard
