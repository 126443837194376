// two FB projects for DEV and PROD mode
const firebaseProject = process.env.REACT_APP_FIREBASE_PROJECT
const dev = firebaseProject === 'w4-calendar-builder-dev'

export const firebaseConfig = dev
  ? {
    apiKey: 'AIzaSyDcTiBe9DHyk96dgboArhRkb6c_l6MOWIs',
    authDomain: 'w4-calendar-builder-dev.firebaseapp.com',
    databaseURL: 'https://w4-calendar-builder-dev.firebaseio.com',
    projectId: 'w4-calendar-builder-dev',
    storageBucket: 'w4-calendar-builder-dev.appspot.com',
    messagingSenderId: '662628440898'
  }
  : {
    apiKey: 'AIzaSyCQ84TEWvk4hstVeOUkfh7wvL-bm4Xeo9A',
    authDomain: 'w4-calendar-builder.firebaseapp.com',
    databaseURL: 'https://w4-calendar-builder.firebaseio.com',
    projectId: 'w4-calendar-builder',
    storageBucket: 'w4-calendar-builder.appspot.com',
    messagingSenderId: '1025024772758'
  }
