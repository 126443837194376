import React, { useEffect, useState } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import { translate } from 'react-i18next'
import { useSelector } from 'react-redux'

import { read } from '../../helpers/translationModel/read'
import { write } from '../../helpers/translationModel/write'
import { firebaseDb as db } from '../../firebase'
import { Card } from '../../components/Card/Card'
import Button from '../../components/CustomButton/CustomButton'
import Loading from '../../components/Loading/Loading'
import { Radio } from 'antd';

const HeaderButtonStyling = (props) => {
  const { t } = props

  const {
    activeCompany,
    activeCalendar,
    calendarLangPrimary,
    calendarLang
  } = useSelector(state => state)

  const [loading, setLoading] = useState(true)
  const [style, setStyle] = useState({
    useCustomStyle: false,
    color: '',
    backgroundColor: '',
    borderColor: '',
    invertColorsOnHover: true,
    borderRadius: '',
    bold: '',
    italic: ''
  })


  useEffect(() => {
    if (activeCompany && activeCalendar) {
      loadFromDb()
    }
  }, [activeCompany, activeCalendar, calendarLang])

  const loadFromDb = () => {
    activeCompany &&
      activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          // Replace data with translations
          data = await read(
            activeCompany,
            activeCalendar,
            calendarLangPrimary,
            calendarLang,
            'settings/grid',
            data
          )
          data.headerButtonStyling && setStyle(data.headerButtonStyling)
          setLoading(false)
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'not saved! Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    let updateContent = {
      headerButtonStyling: style
    }
    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        updateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .then(res => { })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  return (
    <>
      <Row
        className='show-grid'
        float='center'
        style={{ display: !loading && 'none' }}
      >
        <Col xs={12} xsOffset={6} valign='middle'>
          <Loading type='spin' color='green' />
        </Col>
      </Row>
      <Grid
        fluid
        style={{ visibility: loading && 'hidden' }}
      >
        <Row>
          <Col md={12}>
            <Card
              title={t('calHeader.buttonStyling')}
              content={
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <ControlLabel>
                          <div className='checkbox'>
                            <input
                              type='checkbox'
                              id='displayButtonStyling'
                              name='displayButtonStyling'
                              checked={style.useCustomStyle}
                              onChange={e => setStyle({ ...style, useCustomStyle: e.target.checked })}
                            />
                            <label htmlFor='displayButtonStyling'>
                              {t('calHeader.buttonStylingCustom')}
                            </label>
                          </div>
                        </ControlLabel>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('calHeader.buttonStyling')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 0 0 5px',
                            fontSize: '15px'
                          }}
                        />
                        <Row style={{ opacity: !style.useCustomStyle && '0.5', pointerEvents: !style.useCustomStyle && 'none' }}>
                          <Col md={6}>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.backgroundColor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calHeader.backgroundColor')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='color'
                                value={style.backgroundColor}
                                onChange={e => setStyle({ ...style, backgroundColor: e.target.value })}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.color')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calHeader.color')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='color'
                                value={style.color}
                                onChange={e => setStyle({ ...style, color: e.target.value })}
                              />
                            </FormGroup>
                            <Col md={3}>
                              <ControlLabel>
                                <div className='checkbox'>
                                  <input
                                    type='checkbox'
                                    name='bold'
                                    checked={style.bold}
                                    onChange={e => setStyle({ ...style, bold: e.target.checked })}
                                    id='bold'
                                  />
                                  <label htmlFor='bold'>
                                    {t('calHeader.bold')}
                                  </label>
                                </div>
                              </ControlLabel>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.bold')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 0 0 5px',
                                  fontSize: '15px'
                                }}
                              />
                            </Col>
                            <Col md={3}>
                              <ControlLabel>
                                <div className='checkbox'>
                                  <input
                                    type='checkbox'
                                    name='italic'
                                    checked={style.italic}
                                    onChange={e => setStyle({ ...style, italic: e.target.checked })}
                                    id='italic'
                                  />
                                  <label htmlFor='italic'>
                                    {t('calHeader.italic')}
                                  </label>
                                </div>
                              </ControlLabel>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.italic')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 0 0 5px',
                                  fontSize: '15px'
                                }}
                              />
                            </Col>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.borderColor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calHeader.borderColor')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='borderColor'
                                value={style.borderColor}
                                onChange={e => setStyle({ ...style, borderColor: e.target.value })}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.borderRadius')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calHeader.borderRadius')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='borderRadius'
                                value={style.borderRadius}
                                onChange={e => setStyle({ ...style, borderRadius: e.target.value })}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calHeader.hoverColor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calHeader.hoverColor')}</ControlLabel>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Radio.Group
                                  value={style.invertColorsOnHover}
                                  size="small"
                                  style={{ marginTop: 5 }}
                                  onChange={(e) => { setStyle({ ...style, invertColorsOnHover: e.target.value }) }}>
                                  <Radio.Button value={true}>Invert</Radio.Button>
                                  <Radio.Button value={false}>Same</Radio.Button>
                                </Radio.Group>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      <Button bsStyle='info' pullRight fill type='submit'>
                        {t('calHeader.updateStyling')}
                      </Button>
                    </Col>
                  </Row>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default translate('translations')(HeaderButtonStyling)