import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import HeaderLinks from '../Header/HeaderLinks'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { firebaseDb as db } from '../../firebase'
import { connect } from 'react-redux'
import _ from 'lodash'

import imagine from '../../assets/img/sidebar-4.jpg'
import logo from '../../assets/img/reactlogo.png'

const PROMPT_MESSAGE =
  'You have unpublished changes, are you sure you want to leave?'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.onUnload = this.onUnload.bind(this)
  }

  state = {
    toPublish: false,
    width: window.innerWidth,
    listeningToChanges: false,
    type: 'hide-and-seek'
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  updateDimensions() {
    const newState = { ...this.state, width: window.innerWidth }
    this.setState(newState)
  }
  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
    this.changeListener(this.props.activeCompany, this.props.activeCalendar)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeCalendar !== this.props.activeCalendar) {
      this.changeListener(
        nextProps.activeCompany,
        nextProps.activeCalendar,
        true
      )
    }
  }

  onUnload = event => {
    event.returnValue = PROMPT_MESSAGE
  }

  changeListener = (
    activeCompany,
    activeCalendar,
    willReceiveProps = false
  ) => {
    if (
      (willReceiveProps || this.state.listeningToChanges === false) &&
      activeCompany &&
      activeCalendar
    ) {
      db.doc(
        '/companies/' + activeCompany + '/calendars/' + activeCalendar + '/'
      ).onSnapshot(doc => {
        const data = doc.data()
        if(!data || !data.changeToPublish) return
        this.setState({
          listeningToChanges: true,
          toPublish: data.changeToPublish,
          type: data.type
        })
        if (data.changeToPublish === true) {
          window.addEventListener('beforeunload', this.onUnload)
        } else {
          window.removeEventListener('beforeunload', this.onUnload)
        }
      })
    }
  }

  render() {
    const { t } = this.props
    const sidebarBackground = {
      backgroundImage: 'url(' + imagine + ')'
    }
    return (
      <div
        id='sidebar'
        className='sidebar'
        data-color='black'
        data-image={imagine}
      >
        <div className='sidebar-background' style={sidebarBackground} />
        <div className='logo'>
          <a
            href='https://www.creative-tim.com'
            className='simple-text logo-mini'
          >
            <div className='logo-img'>
              <img src={logo} alt='logo_image' />
            </div>
          </a>
          <a href='/' className='simple-text logo-normal'>
            Spielo
          </a>
        </div>
        {!this.props.activeCalendar && this.props.companiesData && (
          <div className='sidebar-wrapper'>
            {this.state.width <= 991 ? <HeaderLinks {...this.props} /> : null}
            {_.map(this.props.companiesData, (company, companyIndex) => {
              return (
                <ul className='nav' key={companyIndex}>
                  {_.map(company.calendars, (calendar, calendarIndex) => {
                    return (
                      <li
                        key={calendarIndex}
                        className={this.activeRoute(
                          '/company/' +
                          companyIndex +
                          '/calendar/' +
                          calendarIndex +
                          '/dashboard'
                        )}
                      >
                        <NavLink
                          to={
                            '/company/' +
                            companyIndex +
                            '/calendar/' +
                            calendarIndex +
                            '/dashboard'
                          }
                          className='nav-link'
                          activeClassName='active'
                        >
                          <i className='pe-7s-date text-info' />
                          <p>{calendar.name}</p>
                        </NavLink>
                      </li>
                    )
                  })}
                </ul>
              )
            })}
          </div>
        )}
        {this.props.activeCalendar && ( this.props.companiesData && this.props.companiesData[this.props.activeCompany] && this.props.companiesData[this.props.activeCompany].disabledCalendars && this.props.companiesData[this.props.activeCompany].disabledCalendars[this.props.activeCalendar] && this.props.companiesData[this.props.activeCompany].disabledCalendars[this.props.activeCalendar].disabled === true ? (
          <div className='sidebar-wrapper'>
            <ul className='nav'>
              {this.state.width <= 991 ? <HeaderLinks {...this.props} /> : null}
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/dashboard'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/dashboard'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-home' />
                  <p>{t('sidebar.dashboard')}</p>
                </NavLink>
              </li>
            </ul>
          </div>
        ) :
          <div className='sidebar-wrapper'>
            <ul className='nav'>
              {this.state.width <= 991 ? <HeaderLinks {...this.props} /> : null}
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/dashboard'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/dashboard'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-home' />
                  <p>{t('sidebar.dashboard')}</p>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calstats'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calstats'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-display1' />
                  <p>{t('sidebar.stats')}</p>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calsubmits'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calsubmits'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-download' />
                  <p>{t('sidebar.submits')}</p>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calheader'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calheader'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-browser' />
                  <p>{t('sidebar.header')}</p>
                </NavLink>
              </li>
              {/* <li
                className={this.activeRoute(
                  '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calcounter-and-calnewsletter'
                )}
              >
                {this.state.type === 'hide-and-seek' ? (
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/calcounter-and-calnewsletter'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-mouse' />
                    <p>{t('sidebar.counterAndNewsLetter')}</p>
                  </NavLink>
                ) : (
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/calcounter-and-calnewsletter'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-mail-open-file' />
                    <p>{t('sidebar.newsletter')}</p>
                  </NavLink>
                )}
              </li> */}
              {this.state.type === 'hide-and-seek' && (
                <li
                  className={this.activeRoute(
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calgame-popups'
                  )}
                >
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/calgame-popups'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-joy' />
                    <p>{t('sidebar.gamePopUps')}</p>
                  </NavLink>
                </li>
              )}
              {/* <li
                className={this.activeRoute(
                  '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/caldays'
                )}
              >
                {this.state.type === 'hide-and-seek' ? (
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/caldays'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-keypad' />
                    <p>{t('sidebar.boxes')}</p>
                  </NavLink>
                ) : (
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/caldays'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-date' />
                    <p>{t('sidebar.days')}</p>
                  </NavLink>
                )}
              </li> */}
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calboxes'
                )}
              >
                {this.state.type === 'hide-and-seek' ? (
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/calboxes'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-keypad' />
                    <p>{t('sidebar.boxes')}</p>
                  </NavLink>
                ) : (
                  <NavLink
                    to={
                      '/company/' +
                      this.props.match.params.companyId +
                      '/calendar/' +
                      this.props.match.params.calendarId +
                      '/calboxes'
                    }
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className='pe-7s-date' />
                    <p>{t('sidebar.days')}</p>
                  </NavLink>
                )}
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calgrid'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calgrid'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-photo-gallery' />
                  <p>{t('sidebar.grid')}</p>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calstyle'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calstyle'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-paint-bucket' />
                  <p>{t('sidebar.style')}</p>
                </NavLink>
              </li>
              <li
                className={
                  this.activeRoute(
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calinfopages'
                  ) ||
                  this.activeRoute(
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calinfopage'
                  )
                }
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calinfopages'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-news-paper' />
                  <p>{t('sidebar.infoPages')}</p>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calsettings'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calsettings'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <i className='pe-7s-tools' />
                  <p>{t('sidebar.settings')}</p>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/calimplementation'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calimplementation'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <div>
                    <i className='pe-7s-plugin' />
                    <p>{t('sidebar.implementation')}</p>
                  </div>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/filestorage'
                )}>
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/filestorage'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <div>
                    <i className='pe-7s-folder' />
                    <p>{t('sidebar.storage')}</p>
                  </div>
                </NavLink>
              </li>
              <li
                className={this.activeRoute(
                  '/company/' +
                  this.props.match.params.companyId +
                  '/calendar/' +
                  this.props.match.params.calendarId +
                  '/backup'
                )}>
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/backup'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  <div>
                    <i className='pe-7s-folder' />
                    <p>{t('sidebar.backup')}</p>
                  </div>
                </NavLink>
              </li>
              {/* <li
                className={this.activeRoute(
                  '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calpublish'
                )}
              >
                <NavLink
                  to={
                    '/company/' +
                    this.props.match.params.companyId +
                    '/calendar/' +
                    this.props.match.params.calendarId +
                    '/calpublish'
                  }
                  className='nav-link'
                  activeClassName='active'
                >
                  {this.state.toPublish ? (
                    <div>
                      <i className='pe-7s-exapnd2 text-warning' />
                      <p className='text-warning'>{t('sidebar.publish')}</p>
                    </div>
                  ) : (
                    <div>
                      <i className='pe-7s-exapnd2' />
                      <p>{t('sidebar.publish')}</p>
                    </div>
                  )}
                </NavLink>
              </li>
              <li className='active active-pro'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={
                    'https://w4-calendar.firebaseapp.com/?companyId=' +
                    this.props.match.params.companyId +
                    '&calendarId=' +
                    this.props.match.params.calendarId
                  }
                >
                  <i className='pe-7s-display2' />
                  <p>{t('sidebar.livePreview')}</p>
                </a>
              </li> */}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate('translations')(Sidebar)))
