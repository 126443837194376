// modification of https://codepen.io/RuudBurger/pen/bwjry
import React, { Component } from 'react'

class Fadeout extends Component {
  render () {
    return (
      <div style={{ width: '200px', height: '150px' }}>
        <div className='box-animation-fadeout'>
          <div className='box-animation-fadeout__wrapper'>
            <input type='checkbox' />

            <div className='box-animation-fadeout__panel box-animation-fadeout__panel--cover'>
              <img
                alt='icon'
                src='https://storage.googleapis.com/w4-calendar-builder.appspot.com/company%2FwG7qyo98VjFba1uhUgR3%2Fcalendar%2F7u34IdnQOdXD3fpdKuux%2FdayCustomIcons%2F6.png?GoogleAccessId=firebase-adminsdk-qzplw%40w4-calendar-builder.iam.gserviceaccount.com&Expires=16447017600&Signature=P%2BPDCYth0WFJbI2Sq6HZuuuMm2BzaP6OKQKfh19V4%2FoJqkf%2BQaYmiR3Um2lCkY6lcLMfiq6mY4QkG78SO9Cu8cHmvX7e%2FqsF7qgXvMa46qlHlzI1x0mjpQQmchAbyWOUGrDXXTN7%2F1oQzdD2%2Fe8u2O84HYSGhBp8HVGo2PZJi4oS2pvtiPeM1StgNyG38oWTLpmyuar07vFJzGOw3A9%2B5bGFQzu%2FSs1VjOqXUPsxNadc2d4%2F2YQ5VKq2m%2F4eiHvEjxWZsi1wKU573XX5y0tm89Zy0JrhUbRBi1X3Wa5odlhbopTLbZOCeHNpqE5eMkG3IoH9HcfSqhaOVrMG6wjhIg%3D%3D'
              />
            </div>

            <div className='box-animation-fadeout__content'>
              <img
                alt='icon'
                src='https://storage.googleapis.com/w4-calendar-builder.appspot.com/company%2FwG7qyo98VjFba1uhUgR3%2Fcalendar%2F7u34IdnQOdXD3fpdKuux%2FdayCustomIcons%2F6.png?GoogleAccessId=firebase-adminsdk-qzplw%40w4-calendar-builder.iam.gserviceaccount.com&Expires=16447017600&Signature=P%2BPDCYth0WFJbI2Sq6HZuuuMm2BzaP6OKQKfh19V4%2FoJqkf%2BQaYmiR3Um2lCkY6lcLMfiq6mY4QkG78SO9Cu8cHmvX7e%2FqsF7qgXvMa46qlHlzI1x0mjpQQmchAbyWOUGrDXXTN7%2F1oQzdD2%2Fe8u2O84HYSGhBp8HVGo2PZJi4oS2pvtiPeM1StgNyG38oWTLpmyuar07vFJzGOw3A9%2B5bGFQzu%2FSs1VjOqXUPsxNadc2d4%2F2YQ5VKq2m%2F4eiHvEjxWZsi1wKU573XX5y0tm89Zy0JrhUbRBi1X3Wa5odlhbopTLbZOCeHNpqE5eMkG3IoH9HcfSqhaOVrMG6wjhIg%3D%3D'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Fadeout
