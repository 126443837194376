import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const en = require('./locales/en.json')
const de = require('./locales/de.json')

i18next.use(LanguageDetector).init({
  whitelist: ['en', 'de'],
  resources: {
    en,
    de
  },
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false
  },
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 0,
  cookieDomain: 'localhost'
})

export default i18next
