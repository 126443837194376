import { firebaseDb as db } from '../../firebase'
import { map } from 'lodash'

// Data
import translationFields from '../../data/translationFields.json'

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const write = async (
  activeCompany,
  activeCalendar,
  primary,
  lang,
  collection,
  originalData,
  rule = collection
) => {
  return new Promise(async resolve => {
    if (!isValidDate(originalData.stop)) {
      delete originalData.stop
    }
    let dataPrimary = { ...originalData }
    let dataLanguage = {}

    if (lang && lang !== primary && typeof primary !== 'undefined') {
      map(translationFields[rule], transField => {
        delete dataPrimary[transField]
        if (originalData[transField]) {
          dataLanguage[transField] = originalData[transField]
        }
      })

      //Update language translation in DB
      dataLanguage &&
        (await db
          .doc(
            'companies/' +
              activeCompany +
              '/calendars/' +
              activeCalendar +
              '/translation/' +
              lang +
              '/' +
              collection
          )
          .set(dataLanguage, { merge: true }))
    }

    // Update primary language
    await db
      .doc(
        'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/' +
          collection
      )
      .update(dataPrimary)

    return resolve('')
  })
}
