import {} from 'lodash';

/**
 * @param {Array} missingDayPairList - array of missing days eg ['1', '2']
 * @param {Object} days - object of days (Grid days)
 * @returns {Object} - object of days with missing days pairs
 * @description - generates pairs of missing days for memory game
*/

export const generateMemoryGamePair = (missingDayPairList, days) => {
  const dayObjCoord =  _.map(days, day => day.coords);
  const lastCell = _.reduce(dayObjCoord, (acc, el) => {
    if(el[1] >= acc[1]) {
      return [el[0], el[1]];
    }
    return acc;
  }, [0, 0])
  const newRowLetter = lastCell[0] < 3 ? lastCell[1] : lastCell[1] + 1;
  const newColLetter = lastCell[0] + 1;
  const newDayObj = missingDayPairList.reduce((acc, dayLetter, i) => {
    acc[`pair_${dayLetter}`] = {
      ...days[dayLetter],
      coords: {
        0: (newColLetter + i) % 4,
        1: newRowLetter + Math.floor(i / 4),
        2: 1,
        3: 1
      }
    };
    return acc
  }, days);  
  return newDayObj;
}