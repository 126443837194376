import React, { Component } from 'react'
import { Grid, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import StatsCard from '../../components/StatsCard/StatsCard'
import { Link, NavLink } from 'react-router-dom'
import { translate } from 'react-i18next'
import i18next from 'i18next'
// EN tutorial
import ENTutorialHideAndSeek from './Parts/Tutorials/en/TutorialHideAndSeek'
import ENTutorialAdvent from './Parts/Tutorials/en/TutorialAdvent'
import ENTutorialMemoryGame from './Parts/Tutorials/en/TutorialMemoryGame'

class Dashboard extends Component {
  createLegend (json) {
    var legend = []
    for (var i = 0; i < json['names'].length; i++) {
      var type = 'fa fa-circle text-' + json['types'][i]
      legend.push(<i className={type} key={i} />)
      legend.push(' ')
      legend.push(json['names'][i])
    }
    return legend
  }
  getTutorial(calendarType) {
    if (calendarType === 'hide-and-seek') {
      return <ENTutorialHideAndSeek />
    } else if (calendarType === 'advent') {
      return <ENTutorialAdvent />
    } else {
      return <ENTutorialMemoryGame />
    }
  }
  getTutorialByLanguage(language, calendarType) {
    if (language === 'en') {
      return this.getTutorial(calendarType)
    } else {
      return <ENTutorialMemoryGame />
    }
  }
  render () {
    const { t } = this.props

    return (
      <div className='content'>
        <Grid fluid>
          { this.props.companiesData &&
            this.props.companiesData[this.props.activeCompany] && this.props.companiesData[this.props.activeCompany].calendars[
              this.props.activeCalendar
            ] && <Row>
            <Col lg={3} sm={6}>
              <NavLink
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calheader'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-home text-info' />}
                  statsValue={t('dashboard.dashboard.header')}
                />
              </NavLink>
            </Col>
            <Col lg={3} sm={6}>
              <Link
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calgrid'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-browser text-info' />}
                  statsValue={t('dashboard.dashboard.grid')}
                />
              </Link>
            </Col>
            <Col lg={3} sm={6}>
              <Link
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calboxes'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-photo-gallery text-info' />}
                  statsValue={t('dashboard.dashboard.days')}
                />
              </Link>
            </Col>
            <Col lg={3} sm={6}>
              <Link
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calstyle'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-paint-bucket text-info' />}
                  statsValue={t('dashboard.dashboard.style')}
                />
              </Link>
            </Col>
            <Col lg={3} sm={6}>
              <Link
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calinfopages'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-news-paper text-info' />}
                  statsValue={t('dashboard.dashboard.infoPages')}
                />
              </Link>
            </Col>
            <Col lg={3} sm={6}>
              <Link
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calsettings'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-tools text-info' />}
                  statsValue={t('dashboard.dashboard.settings')}
                />
              </Link>
            </Col>
            <Col lg={3} sm={6}>
              <Link
                style={{ color: 'inherit' }}
                to={
                  '/company/' +
                  this.props.activeCompany +
                  '/calendar/' +
                  this.props.activeCalendar +
                  '/calpublish'
                }
              >
                <StatsCard
                  bigIcon={<i className='pe-7s-exapnd2 text-info' />}
                  statsValue={t('dashboard.dashboard.publish')}
                />
              </Link>
            </Col>
          </Row>}
          <Row>
            {this.props.companiesData &&
              this.props.companiesData[this.props.activeCompany] &&
              this.props.companiesData[this.props.activeCompany].calendars[
                this.props.activeCalendar
              ] &&(this.getTutorialByLanguage(i18next.language, this.props.companiesData[this.props.activeCompany].calendars[this.props.activeCalendar].type)
           ) }
           {  this.props.companiesData &&
              this.props.companiesData[this.props.activeCompany] &&
              this.props.companiesData[this.props.activeCompany].disabledCalendars[
                this.props.activeCalendar
              ] && (
              <Col lg={12} sm={12}>
                <div>
                  <h2 style={{ textAlign: 'center' }}>Calendar is not available.</h2>
                  <h5 style={{ textAlign: 'center' }}>
                    This calendar has been deleted. You can restore it by clicking on the Restore button above.
                  </h5>
              </div>
              </Col>
              )}
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

export default connect(
  mapStateToProps,
  null
)(translate('translations')(Dashboard))
