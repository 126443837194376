import React, { Component } from 'react'
import { Grid, Row, Col, HelpBlock, FormGroup } from 'react-bootstrap'
import Button from '../../components/CustomButton/CustomButton'
import { FormInputs } from '../../components/FormInputs/FormInputs'
import { firebaseAuth } from '../../firebase'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom'
import Message from './Parts/Message.js'

import logo from '../../assets/img/reactlogo.png'

const errorMessages = {
  invalidCredentials: 'Invalid Credentials',
  invalidEmailFormat: 'Invalid e-mail format',
  tooManyRequests: 'Too many login requests, please try to login later',
  userNotFound: 'User not found',
  emailRequired: 'E-mail is required'
}

class Login extends Component {
  state = {
    isSent: false,
    errorEmail: null,
    errorPass: null,
    errorGlobal: null
  }

  handleSubmit = event => {
    event.preventDefault()
    let errorEmail = ''
    const data = new FormData(event.target)
    const email = data.get('email')
    if (email) {
      this.sendPasswordRecovery(email)
    } else {
      if (!email) {
        errorEmail = errorMessages.emailRequired
      }
    }
    this.setState({ ...this.state, errorEmail: errorEmail, errorGlobal: null })
  }

  sendPasswordRecovery = email => {
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(request => {
        this.setState({ ...this.state, isSent: true })
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/wrong-password':
            this.setState({ ...this.state, errorGlobal: errorMessages.invalidCredentials })
            break
          case 'auth/user-not-found':
            this.setState({ ...this.state, errorGlobal: errorMessages.userNotFound })
            break
          case 'auth/invalid-email':
            this.setState({ ...this.state, errorEmail: errorMessages.invalidEmailFormat, errorGlobal: null })
            break
          case 'auth/too-many-requests':
            this.setState({ ...this.state, errorGlobal: errorMessages.tooManyRequests })
            break
          default:
            this.setState({ ...this.state, errorGlobal: error })
        }
      })
  }

  render () {
    const { t } = this.props

    return (
      <div className='content'>
        <Grid>
          <Row>
            <Col xs={0} sm={2} md={4} />
            <Col xs={12} sm={6} md={4}>
              {this.state.isSent && (
                <Message
                  icon='pe-7s-paper-plane'
                  title={t('auth.reset.recoverySent')}
                  text={t('auth.reset.pleaseCheckYourEmail')}
                />
              )}
              {!this.state.isSent && (
                <div>
                  <div className='logo' style={{ textAlign: 'center' }}>
                    <a href='https://www.creative-tim.com' className='logo-mini'>
                      <div className='logo-img'>
                        <img src={logo} alt='logo_image' />
                      </div>
                    </a>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId='formBasicText' validationState={this.state.errorEmail ? 'error' : null}>
                      <FormInputs
                        ncols={['col-md-12']}
                        value=''
                        proprieties={[
                          {
                            label: t('auth.reset.email'),
                            type: 'text',
                            id: 'email',
                            name: 'email',
                            bsClass: 'form-control',
                            defaultValue: '',
                            placeholder: t('auth.reset.pleaseEnterEmail'),
                            disabled: false
                          }
                        ]}
                      />
                      {this.state.errorEmail && <HelpBlock>{this.state.errorEmail}</HelpBlock>}
                    </FormGroup>
                    {this.state.errorGlobal && (
                      <FormGroup controlId='formBasicText' validationState='error'>
                        <HelpBlock>{this.state.errorGlobal}</HelpBlock>
                      </FormGroup>
                    )}
                    <Link to='/auth/login'>
                      <Button simple>{t('auth.reset.login')}</Button>
                    </Link>
                    <Button bsStyle='success' pullRight fill type='submit'>
                      {t('auth.reset.sendLink')}
                    </Button>
                    <div className='clearfix' />
                  </form>
                </div>
              )}
            </Col>
            <Col xs={0} sm={2} md={4} />
          </Row>
        </Grid>
      </div>
    )
  }
}

export default translate('translations')(Login)
