import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import indexRoutes from './routes/index'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/i18n'
import { createStore } from 'redux'
import Loading from './components/Loading/Loading'
import { Row, Col } from 'react-bootstrap'
import { Provider } from 'react-redux'
import reducer from './store/reducer'
import { firebaseAuth } from './firebase'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import _ from 'lodash'
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/sass/light-bootstrap-dashboard.css?v=1.2.0'
import './assets/css/demo.css'
import './assets/css/pe-icon-7-stroke.css'

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f
)
Sentry.init({
  dsn:
    'https://849eedb8abdf43ce9e52b41f3a06c168@o406152.ingest.sentry.io/5506948',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

class App extends Component {
  state = {
    loading: true,
    isAuthentificated: true
  }

  componentDidMount () {
    setChonkyDefaults({ iconComponent: ChonkyIconFA });
    // the setTimeout just simulates an async action, after which the component will render the content
    // setTimeout(() => this.setState({ loading: false }), 1500)
    firebaseAuth.onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        firebaseAuth.currentUser
          .getIdTokenResult()
          .then(idTokenResult => {
            const companies = idTokenResult.claims.companies
            this.setState({
              ...this.state,
              loading: false,
              isAuthentificated: true,
              companies: companies
            })
          })
          .catch(error => {
            console.log('error', error)
            // error catch
          })
      } else {
        if (this.state.isAuthentificated) {
          this.setState({
            ...this.state,
            loading: false,
            isAuthentificated: false
          })
        }
      }
    })
  }

  render () {
    const { loading } = this.state

    if (loading) {
      // if your component doesn't have to wait for an async action, remove this block
      return (
        <Row className='show-grid' float='center'>
          <Col xs={12} xsOffset={6} valign='middle'>
            <Loading type='spin' color='green' />
            <span>Loading...</span>
          </Col>
        </Row>
      ) // render null when app is not ready
    }
    return (
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <Switch>
              {_.map(indexRoutes, (prop, key) => {
                if (
                  (prop.onlyAuthentificated && this.state.isAuthentificated) ||
                  !prop.onlyAuthentificated
                ) {
                  return (
                    <Route
                      path={prop.path}
                      component={props => (
                        <prop.component
                          {...this.props}
                          {...props}
                          companies={this.state.companies}
                        />
                      )}
                      key={key}
                      companies={this.state.companies}
                    />
                  )
                }
              })}
              <Redirect path='/' to='/auth/login' />
            </Switch>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
