/**
 * <ReactFormBuilder />
 */

import React from 'react'
import { DndProvider, createDndContext } from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import Preview from './preview'
import Toolbar from './toolbar'
import ReactFormGenerator from './form'
import store from './stores/store'
import { isEqual } from 'lodash'

const RNDContext = createDndContext(HTML5Backend);

class ReactFormBuilder extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      editMode: false,
      editElement: null,
      data: [],
      counter: null,
      manager: RNDContext
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.data) {
      this.state.data !== nextProps.data && this.setState({ data: nextProps.data })
      if (this.props.handleChangeData) {
        this.setState({ counter: null })
      }
      if (!isEqual(this.state.data, nextProps.data)) {
        this.props.handleChangeSaved(true)
        this.setState({ counter: null })
      }
    }
  }
  componentDidMount () {
    if (this.props.data) {
      this.setState({ data: this.props.data })
    }
  }
  handleChangeData = updatedData => {
    if (!isEqual(this.state.data, updatedData)) {
      this.setState({ data: updatedData.task_data }, this.props.handleChangeData(updatedData.task_data))
      this.setState({ counter: this.state.counter + 1 })
      if (this.state.counter > 0) {
        this.props.handleChangeSaved(false)
      }
    }
  }

  editModeOn (data, e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.editMode) {
      this.setState({ editMode: !this.state.editMode, editElement: null })
    } else {
      this.setState({ editMode: !this.state.editMode, editElement: data })
    }
  }

  manualEditModeOff () {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        editElement: null
      })
    }
  }

  render () {
    const toolbarProps = {
      showDescription: this.props.show_description,
      notificationOpen: this.props.notificationOpen
    }
    if (this.props.toolbarItems) {
      toolbarProps.items = this.props.toolbarItems
    }
    return (
      <DndProvider manager={this.state.manager.dragDropManager} key={1}>
        <div>
          <div className='react-form-builder clearfix'>
            <div>
              <Preview
                files={this.props.files}
                manualEditModeOff={this.manualEditModeOff.bind(this)}
                showCorrectColumn={this.props.showCorrectColumn}
                parent={this}
                data={this.state.data}
                url={this.props.url}
                saveUrl={this.props.saveUrl}
                onLoad={this.props.onLoad}
                onPost={e => this.handleChangeData(e)}
                editModeOn={this.editModeOn}
                editMode={this.state.editMode}
                variables={this.props.variables}
                editElement={this.state.editElement}
              />
              <Toolbar {...toolbarProps} />
            </div>
          </div>
        </div>
      </DndProvider>
    )
  }
}

const FormBuilders = {}
FormBuilders.ReactFormBuilder = ReactFormBuilder
FormBuilders.ReactFormGenerator = ReactFormGenerator
FormBuilders.ElementStore = store

export default FormBuilders

export { ReactFormBuilder, ReactFormGenerator, store as ElementStore }
