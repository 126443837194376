import React, { Component } from 'react'
import { Grid, Row, Col, Table, FormGroup, ControlLabel, FormControl, ButtonGroup } from 'react-bootstrap'
import Card from './../../components/Card/Card'
import { firebaseDb as db } from '../../firebase'
import { translate } from 'react-i18next'
import moment from 'moment'
import Modal from './Parts/Modal'
import { connect } from 'react-redux'
import CsvDownload from 'react-json-to-csv'
import _ from 'lodash'

class CalSubmit extends Component {
  state = {
    dbItems: [],
    itemsPerPage: 5,
    lastVisible: 0,
    noMore: false,
    showModal: false,
    loading: true,
    noSubmits: true,
    showResetModal: false,
    selectedResetOption: "all",
    isGeneratingSubmitsCsv: false,
    dateRange: {
      dateFrom: "",
      dateTo: "",
    },
    resetFormSubmitted: false,
  }

  // Did Mount.
  componentDidMount() {
    this.setState(
      {
        ...this.state,
        activeCompany: this.props.activeCompany,
        activeCalendar: this.props.activeCalendar,
        calendarType: this.props.calendarType
      },
      () => this.getItems(false)
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        ...this.state,
        activeCompany: nextProps.activeCompany,
        activeCalendar: nextProps.activeCalendar,
        calendarType: nextProps.calendarType
      })
    }
  }

  iconType = pageType => {
    switch (pageType) {
      case 'page':
        return (
          <span>
            <i className='pe-7s-menu' /> Page
          </span>
        )
      case 'link':
        return (
          <span>
            <i className='pe-7s-link' /> Link
          </span>
        )
      case 'no':
        return null
      case 'yes':
        return (
          <span>
            <i className='pe-7s-look' /> visible
          </span>
        )
      default:
        return pageType
    }
  }

  // Get Items
  getItems = (isNewQuery) => {
    const {
      itemsPerPage,
      lastVisible,
      activeCompany,
      activeCalendar
    } = this.state

    if (lastVisible !== 0 && !isNewQuery) {
      db.collection(
        '/companies/' +
        activeCompany +
        '/calendars/' +
        activeCalendar +
        '/submits'
      )
        .orderBy('added', 'desc')
        .startAfter(lastVisible)
        .limit(itemsPerPage)
        .get()
        .then(snapshot => {
          let noMore
          let items = snapshot.docs
          items = this.state.dbItems.concat(items)
          const lastVisible = snapshot.docs[snapshot.docs.length - 1]
          snapshot.docs.length < itemsPerPage
            ? (noMore = true)
            : (noMore = false)
          this.setState({
            ...this.state,
            dbItems: items,
            lastVisible: lastVisible,
            noMore: noMore,
            loading: false
          })
        })
    } else {
      db.collection(
        '/companies/' +
        activeCompany +
        '/calendars/' +
        activeCalendar +
        '/submits'
      )
        .orderBy('added', 'desc')
        .limit(itemsPerPage)
        .get()
        .then(snapshot => {
          let noMore, noSubmits
          const items = snapshot.docs
          const lastVisible = snapshot.docs[snapshot.docs.length - 1]
          snapshot.docs.length < itemsPerPage
            ? (noMore = true)
            : (noMore = false)
          snapshot.docs.length === 0 ? (noSubmits = true) : (noSubmits = false)
          this.setState({
            ...this.state,
            dbItems: items,
            lastVisible: lastVisible,
            noMore: noMore,
            noSubmits: noSubmits,
            loading: false
          })
        })
    }
  }

  deleteQueryBatch = async (db, snapshot, resolve) => {
    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }
    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
    return;
  }

  handleResetModalSubmit = (type) => {
    this.setState({ resetFormSubmitted: true })
    const { activeCompany, activeCalendar } = this.state
    const query = db.collection(
      'companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/submits'
    )
    switch (type) {
      case "all": {
        query.get().then((snapshot) => {
          if (window.confirm('This action will delete all ' + snapshot.size + ' submits')) {
            return new Promise((resolve, reject) => {
              this.deleteQueryBatch(db, snapshot, resolve).then(() => {
                this.toggleResetModal()
                this.getItems(true)
                this.props.notificationOpen('tr', 'pe-7s-check', 'success', 'all records deleted successfully')
              }).catch(reject);
            }).catch(error => {
              console.error('Error removing document: ', error)
            })
          }
        })
        return;
      }
      case "dateRange": {
        const { dateRange } = this.state;
        if (!dateRange.dateTo || !dateRange.dateFrom) {
          return;
        }
        const text = dateRange.dateFrom === dateRange.dateTo ? ' on ' + dateRange.dateTo : ' from ' + dateRange.dateFrom + ' to ' + dateRange.dateTo;
        const startDate = new Date(dateRange.dateFrom);
        const endDate = new Date(new Date(dateRange.dateTo).getTime() + (1000 * 60 * 59.9999 * 23)); // gets last minute of current day. i.e 23:59:59
        query
          .where('added', '>=', startDate)
          .where('added', '<=', endDate)
          .get()
          .then((snapshot) => {
            if (window.confirm('This action will delete all submits' + text + ' (' + snapshot.size + ' records found)')) {
              return new Promise((resolve, reject) => {
                this.deleteQueryBatch(db, snapshot, resolve).then(() => {
                  this.toggleResetModal()
                  this.getItems(true)
                  this.props.notificationOpen('tr', 'pe-7s-check', 'success', 'records deleted successfully')
                }).catch(reject);
              }).catch(error => {
                console.error('Error removing document: ', error)
              })
            }
          }).catch(error => {
            console.error('Error removing document: ', error)
          })
        return;
      }
      default:
        return;
    }
  }

  handleDateChange = (field, value) => {
    this.setState({
      resetFormSubmitted: false,
      dateRange: {
        ...this.state.dateRange,
        [field]: value
      }
    })
  }
  handleChangeType = selectedResetOption => {
    this.setState({ selectedResetOption, resetFormSubmitted: false })
  }

  toggleResetModal = () => {
    this.setState(this.state.showResetModal ? {
      resetFormSubmitted: false,
      showResetModal: !this.state.showResetModal,
      dateRange: {
        dateFrom: "",
        dateTo: ""
      },
      selectedResetOption: "all",
      isGeneratingSubmitsCsv: false,
    } : {
      resetFormSubmitted: false,
      showResetModal: !this.state.showResetModal,
    })
  }

  toggleModal = (itemData = null) => {

    const added = itemData.added
    let data = itemData

    data = Object.assign({}, itemData, itemData.form)
    // Legacy
    data = Object.assign({}, itemData, itemData.formData)

    delete data.form
    delete data.formData
    delete data.calendarId
    delete data.companyId
    delete data.companyId
    delete data.added
    delete data.LEGAL_CONSENT

    this.setState({
      ...this.state,
      showModal: !this.state.showModal,
      modalData: { form: data, added: added }
    })
  }

  generateCsv = () => {
    const { activeCompany, activeCalendar } = this.state

    db.collection(
      '/companies/' +
      activeCompany +
      '/calendars/' +
      activeCalendar +
      '/submits'
    )
      .orderBy('added', 'desc')
      .get()
      .then(snapshot => {
        let data = snapshot.docs.map(doc => doc.data())
        const columns = []
        const dataCsv = _.map(data, (item, index) => {
          const row = { ...item, ...item.form }
          // data[index].text = 'dsads'
          row.added = moment(data[index].added.toDate()).format(
            'DD.MM.YYYY HH:mm'
          )
          delete row.form
          if (this.state.calendarType === 'advent') {
            delete row.winnig
          }
          columns.push(...Object.keys(row))
          return row
        })
        const headers = [...new Set(columns)]
        dataCsv.forEach(row => {
          headers.forEach(header => {
            if (!row[header]) {
              row[header] = ''
            }
          })
        })
        this.setState({ ...this.state, dataCsv: dataCsv, isGeneratingSubmitsCsv: false })
      })
  }

  render() {
    const { t } = this.props
    const { noMore, noSubmits, loading, selectedResetOption, isGeneratingSubmitsCsv, dateRange, resetFormSubmitted } = this.state
    return (
      <div className='content'>
        <Grid fluid>
          <Modal
            show={this.state.showResetModal}
            closeCallback={this.toggleResetModal}
            customClass='modal'
          >
            <Row>
              <Col xs={12} md={6}>
                <ButtonGroup>
                  <button
                    style={{ margin: '0 0px 20px 0px' }}
                    className={
                      selectedResetOption === 'all'
                        ? 'btn btn-info btn-sm selected'
                        : 'btn btn-info btn-sm'
                    }
                    onClick={() => this.handleChangeType('all')}
                  >
                    {t('calSubmits.resetButtonAll')}
                  </button>
                  <button
                    style={{ margin: '0 0px 20px -2px' }}
                    className={
                      selectedResetOption === 'dateRange'
                        ? 'btn btn-info btn-sm selected'
                        : 'btn btn-info btn-sm'
                    }
                    onClick={() => this.handleChangeType('dateRange')}
                  >
                    {t('calSubmits.resetButtonDateRange')}
                  </button>
                </ButtonGroup>
              </Col>
            </Row>
            {selectedResetOption === 'all' && <div className='reset-text'>
              <h5 className='uppercase'>{t('calSubmits.resetAllText')}</h5>
            </div>}
            {selectedResetOption === 'dateRange' && <form onSubmit={this.handleResetModalSubmit} style={{ marginBottom: 0 }}>
              <div>
                <h6>{t('calSubmits.resetDateRangeText')}</h6>
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup className={resetFormSubmitted && !dateRange.dateFrom ? "has-error" : ""}>
                    <ControlLabel>
                      {t('calSubmits.resetDateFrom')}
                    </ControlLabel>
                    <FormControl
                      type='date'
                      name='dateFrom'
                      id='dateFrom'
                      value={dateRange.dateFrom}
                      placeholder=''
                      max={dateRange.dateTo ? dateRange.dateTo : new Date().toISOString().split("T")[0]}
                      onChange={(e) => this.handleDateChange("dateFrom", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup className={resetFormSubmitted && !dateRange.dateTo ? "has-error" : ""}>
                    <ControlLabel>
                      {t('calSubmits.resetDateTo')}
                    </ControlLabel>
                    <FormControl
                      type='date'
                      name='dateTo'
                      id='dateTo'
                      value={dateRange.dateTo}
                      placeholder=''
                      min={dateRange.dateFrom ? dateRange.dateFrom : undefined}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => this.handleDateChange("dateTo", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </form>}
            <footer style={{ marginTop: "14px" }}>
              <div className='button-container'>
                {this.state.dataCsv ? (
                  <CsvDownload
                    className='btn btn-sm btn-success btn-fill'
                    filename='submits.csv'
                    data={this.state.dataCsv}
                  >
                    <i className='pe-7s-download' />{' '}
                    {t('calSubmits.downloadSubmits')}
                  </CsvDownload>
                ) : (
                  <button
                    style={{ marginLeft: '5px' }}
                    className='btn btn-sm btn-warning btn-fill'
                    onClick={() => { this.setState({ isGeneratingSubmitsCsv: true }); this.generateCsv() }}
                  >
                    {t(isGeneratingSubmitsCsv ? 'calSubmits.isGenerateCsvBackup' : 'calSubmits.backupCsv')}
                  </button>
                )}
                &emsp;
                <button className='btn btn-danger btn-fill btn-sm' onClick={() => this.handleResetModalSubmit(selectedResetOption)}>{t('calSubmits.resetConfirmButton')}</button>
              </div>
            </footer>

          </Modal>
          <Modal
            show={this.state.showModal}
            closeCallback={this.toggleModal}
            customClass='modal'
          >
            {this.state.showModal && (
              <React.Fragment>
                <h2>{t('calSubmits.preview')}</h2>
                <p>
                  {t('calSubmits.submitedAt')}{' '}
                  {this.state.modalData &&
                    moment(this.state.modalData.added.toDate()).format(
                      'DD.MM.YYYY HH:mm'
                    )}
                </p>
                <Table striped hover center>
                  {Object.keys(this.state.modalData.form).map((key, index) => {
                    return (
                      <tr key={index}>
                        {!!this.state.modalData.form[key] && (
                          <td>
                            {key}:<br />
                            <b>
                              {this.state.modalData.form[key] === true &&
                                t('calSubmits.yes')}
                            </b>
                            <b>
                              {this.state.modalData.form[key] === false &&
                                t('calSubmits.no')}
                            </b>
                            <b>
                              {this.state.modalData.form[key]
                                ? this.state.modalData.form[key]
                                : '-'}
                            </b>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </Table>
              </React.Fragment>
            )}
          </Modal>
          <Row>
            <Col md={12}>
              {!this.state.noSubmits && (
                <Row>
                  <Col
                    style={{
                      textAlign: 'right',
                      paddingRight: '14px',
                      paddingBottom: '20px'
                    }}
                  >
                    {this.state.dataCsv ? (
                      <CsvDownload
                        className='btn btn-xs btn-success'
                        filename='submits.csv'
                        data={this.state.dataCsv}
                      >
                        <i className='pe-7s-download' />{' '}
                        {t('calSubmits.downloadSubmits')}
                      </CsvDownload>
                    ) : (
                      <button
                        style={{ marginLeft: '5px' }}
                        className='btn btn-xs btn-info'
                        onClick={() => this.generateCsv()}
                      >
                        {t('calSubmits.generateCsv')}
                      </button>
                    )}
                    <button
                      style={{ marginLeft: '5px' }}
                      className='btn btn-xs btn-info'
                      onClick={() => this.toggleResetModal()}
                    >
                      {t('calSubmits.reset')}
                    </button>
                  </Col>
                </Row>
              )}
              <Card
                title={t('calSubmits.title')}
                category={t('calSubmits.subtitle')}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>{t('calSubmits.date')}</th>
                        <th>{t('calSubmits.boxOpened')}</th>
                        <th>{t('calSubmits.clicks')}</th>
                        <th>{t('calSubmits.name')}</th>
                        <th>{t('calSubmits.e-mail')}</th>
                        <th>{t('calSubmits.language')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dbItems.map((item, index) => {
                        let itemData = item.data()

                        itemData = Object.assign({}, itemData, itemData.form)
                        // Legacy
                        itemData = Object.assign(
                          {},
                          itemData,
                          itemData.formData
                        )

                        const dateToFormat = itemData.added.toDate()
                        const added = moment(dateToFormat).format(
                          'DD.MM.YYYY HH:mm'
                        )
                        return (
                          <tr key={index}>
                            <td style={{ width: '170px' }}>
                              <i className='pe-7s-date' /> {added}
                            </td>
                            <td style={{ maxWidth: '25px' }}>
                              {!itemData.dayNumber && '-'}
                              {itemData.dayNumber}
                              {itemData.dayNumber &&
                                (itemData && itemData.dayNumber && !itemData.dayNumber.toString().includes('winning')
                                  ? '. box'
                                  : ' box')}
                            </td>
                            <td style={{ maxWidth: '25px' }}>
                              {itemData.clicks}x
                            </td>
                            <td>
                              {(itemData && itemData['Vorname']) ||
                                itemData['firstname'] ||
                                ''}
                              {(itemData && itemData['Nachname']) ||
                                ' ' + itemData['lastname'] ||
                                ''}
                            </td>
                            <td>
                              {(itemData && itemData['E-Mail']) ||
                                itemData['email'] ||
                                itemData['e-mail'] ||
                                ''}
                            </td>
                            <td>
                              {(itemData && itemData['language']) || ''}
                            </td>
                            <td style={{ maxWidth: '50px' }}>
                              <button
                                style={{ marginLeft: '5px' }}
                                className='btn btn-xs btn-info'
                                onClick={() => this.toggleModal(itemData)}
                              >
                                {t('calSubmits.view')}
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                      {!loading ? (
                        !noMore ? (
                          <tr>
                            <td colSpan='7' style={{ textAlign: 'center' }}>
                              <button
                                onClick={() => this.getItems(false)}
                                className='btn btn-sm btn-success'
                              >
                                {t('calSubmits.loadMore')}
                              </button>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td
                              colSpan='7'
                              style={{ textAlign: 'center', color: 'grey' }}
                            >
                              {noSubmits
                                ? t('calSubmits.noSubmits')
                                : t('calSubmits.noMoreSubmits')}
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td
                            colSpan='7'
                            style={{ textAlign: 'center', color: 'grey' }}
                          >
                            {t('calSubmits.loading')}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {activeCalendar, activeCompany, companiesData } = state
  let calendarType = ''
  if (activeCompany && activeCalendar && companiesData && companiesData[activeCompany] && companiesData[activeCompany].calendars) {
    if (companiesData[activeCompany].calendars && companiesData[activeCompany].calendars[activeCalendar]) {
      calendarType = companiesData[activeCompany].calendars[activeCalendar].type
    }
  }
  return {
    activeCalendar,
    activeCompany,
    calendarType
  }
}

export default connect(
  mapStateToProps,
  null
)(translate('translations')(CalSubmit))
