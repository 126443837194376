import { firebaseDb as db } from '../../firebase'
import { map } from 'lodash'

// Data
import translationFields from '../../data/translationFields.json'

export const read = async (
  activeCompany,
  activeCalendar,
  calendarLangPrimary,
  lang,
  collection,
  originalData,
  rule = collection
) => {
  return new Promise(resolve => {
    let dataModified = { ...originalData }

    if (
      !lang ||
      typeof calendarLangPrimary === 'undefined' ||
      lang === calendarLangPrimary
    ) {
      return resolve(dataModified)
    }

    // Get translated document
    db.doc(
      'companies/' +
        activeCompany +
        '/calendars/' +
        activeCalendar +
        '/translation/' +
        lang +
        '/' +
        collection
    )
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data()

        //Replace fields
        data &&
          map(translationFields[rule], transField => {
            if (typeof data[transField] !== 'undefined') {
              dataModified[transField] = data[transField]
            }
          })

        return resolve(dataModified)
      })
  })
}
