import React, { useState, useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import { Card } from '../../components/Card/Card'
import Button from '../../components/CustomButton/CustomButton'
import Loading from '../../components/Loading/Loading'
import ReactQuill from 'react-quill'
import { firebaseDb as db } from '../../firebase'
import ReactTooltip from 'react-tooltip'
import { translate } from 'react-i18next'

// Redux
import { useSelector } from 'react-redux'

// Translation model
import { read } from '../../helpers/translationModel/read'
import { write } from '../../helpers/translationModel/write'

const CalGamePopups = props => {
  const {
    activeCompany,
    activeCalendar,
    calendarLang,
    calendarLangPrimary
  } = useSelector(state => state)

  const [display, setDisplay] = useState()
  const [behavior, setBehavior] = useState('box')
  const [text, setText] = useState('Loading, please wait ....')
  const [loading, setLoading] = useState(true)
  const [modules, setModules] = useState()

  useEffect(() => {
    activeCompany && activeCalendar && loadFromDb()
  }, [activeCompany, activeCalendar, calendarLang])

  const loadFromDb = () => {
    activeCompany &&
      activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          // Replace data with translations
          data = await read(
            activeCompany,
            activeCalendar,
            calendarLangPrimary,
            calendarLang,
            'settings/grid',
            data
          )

          const modules = {
            toolbar: [
              [{ header: [1, 2, false] }],
              [{ align: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
              ],
              ['link', 'image'],
              [{ color: Object.values(data.colors) }, { color: [] }],
              ['clean']
            ]
          }

          setDisplay(data.losingModal.display)
          setText(data.losingModal.text)
          setBehavior(data.losingModal.behavior)
          setModules(modules)
          setLoading(false)
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'not saved! Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const quillEditor = document.querySelector('#text .ql-editor').innerHTML

    let udpateContent = {
      losingModal: {
        text: quillEditor,
        behavior: behavior,
        display: display === 'true' || display === true
      }
    }
    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        udpateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const handleChangeQuill = (e) => { setText(e) }

  const { t } = props

  return (
    <div className='content'>
      <Row
        className='show-grid'
        float='center'
        style={loading ? { display: 'block' } : { display: 'none' }}
      >
        <Col xs={12} xsOffset={6} valign='middle'>
          <Loading type='spin' color='green' />
        </Col>
      </Row>
      <Grid
        fluid
        style={!loading ? { visibility: 'visible' } : { visibility: 'hidden' }}
      >
        <Row>
          <Col md={12}>
            <Card
              title={t('calGamePopups.modalsBehaviorAndOptions')}
              content={
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('gamePopups.behavior')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calGamePopups.behaviorOfOpeningModals')}
                        </ControlLabel>
                        <FormControl
                          componentClass='select'
                          name='behavior'
                          value={behavior}
                          onChange={e => {
                            setBehavior(e.target.value)
                          }}
                        >
                          <option value='random'>
                            {t('calGamePopups.randomly')}
                          </option>
                          <option value='box'>
                            {t('calGamePopups.contentFromClickedBox')}
                          </option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('gamePopups.display')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calGamePopups.displayLosingModal')}
                        </ControlLabel>
                        <FormControl
                          componentClass='select'
                          name='display'
                          value={display && display.toString()}
                          onChange={e => {
                            setDisplay(e.target.value === 'true')
                          }}
                        >
                          <option value='true'>
                            {t('calGamePopups.displayLosingModalYes')}
                          </option>
                          <option value='false'>
                            {t('calGamePopups.displayLosingModalNo')}{' '}
                          </option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div style={{ display: display === true ? 'block' : 'none' }}>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('gamePopups.text')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calGamePopups.contentOfLosingModal')}
                      </ControlLabel>
                      {modules && (
                        <ReactQuill
                          value={text}
                          modules={modules}
                          id='text'
                          name='text'
                          onChange={handleChangeQuill}
                        />
                      )}
                    </FormGroup>
                  </div>

                  <Button bsStyle='info' pullRight fill type='submit'>
                    {t('calGamePopups.updateLosingModal')}
                  </Button>
                  <div className='clearfix' />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
      <ReactTooltip place='top' type='dark' effect='solid' />
    </div>
  )
}

export default translate('translations')(CalGamePopups)
