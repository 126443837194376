import React, { useState, useEffect } from 'react'
import { ReactFormBuilder } from '../../../../components/W4FormBuilder2'
import { translate } from 'react-i18next'
import { isEqual } from 'lodash'

const Builder = props => {
  var toolbarItems = [
    {
      key: 'ButtonLink',
      name: 'Button',
      icon: 'fas fa-dot-circle',
      static: true,
      content: 'Button Text...'
    },
    {
      key: 'Button',
      name: 'Form Submit Button',
      icon: 'fas fa-dot-circle',
      static: true,
      content: 'Button Text...'
    },
    {
      key: 'Header',
      name: 'Header Text',
      icon: 'fas fa-heading',
      static: true,
      content: 'Placeholder Text...'
    },
    {
      key: 'Paragraph',
      name: 'Paragraph',
      static: true,
      icon: 'fas fa-paragraph',
      content: 'Placeholder Text...'
    },
    {
      key: 'Dropdown',
      canHaveAnswer: true,
      name: 'Dropdown',
      icon: 'far fa-caret-square-down',
      label: 'Placeholder Label',
      field_name: 'dropdown_',
      options: []
    },
    {
      key: 'Checkboxes',
      canHaveAnswer: true,
      name: 'Checkboxes',
      icon: 'far fa-check-square',
      label: 'Placeholder Label',
      field_name: 'checkboxes_',
      options: []
    },
    {
      key: 'RadioButtons',
      canHaveAnswer: true,
      canHavePenalty: true,
      name: 'Radio Select',
      icon: 'far fa-dot-circle',
      label: 'Placeholder Label',
      field_name: 'radiobuttons_',
      options: []
    },
    {
      key: 'TextInput',
      canHaveAnswer: true,
      name: 'Text Input',
      label: 'Placeholder Label',
      icon: 'fas fa-font',
      field_name: 'text_input_'
    },
    {
      key: 'EmailInput',
      canHaveAnswer: true,
      name: 'Email Input',
      label: 'Placeholder Label',
      icon: 'fas fa-at',
      field_name: 'email_input_'
    },
    {
      key: 'NumberInput',
      canHaveAnswer: true,
      name: 'Number Input',
      label: 'Placeholder Label',
      icon: 'fas fa-plus',
      field_name: 'number_input_'
    },
    {
      key: 'TextArea',
      canHaveAnswer: true,
      name: 'Multi-line Input',
      label: 'Placeholder Label',
      icon: 'fas fa-text-height',
      field_name: 'text_area_'
    },
    {
      key: 'Image',
      name: 'Image',
      label: '',
      icon: 'far fa-image',
      field_name: 'image_',
      src: ''
    },
    {
      key: 'HtmlCode',
      name: 'Html Code',
      icon: 'fas fa-code',
      static: true,
      content: 'Html Code'
    },
    {
      key: 'TypeForm',
      name: 'TypeForm',
      icon: 'fas fa-remove-format',
      static: true,
      content: 'TypeForm'
    },
    {
      key: 'MockupForm',
      name: 'MockupForm',
      icon: 'fab fa-wpforms',
      static: true,
      content: ['TextInput', 'Button'] // Elements that will be included to MockupForm
    },
  ]

  const {
    builderData,
    initialData,
    handleChangeSaved,
    daysGridDataColors,
    notificationOpen,
    contentEditor
  } = props
  const [newData, setNewData] = useState()

  const handleChangeData = updatedData => {
    if (!isEqual(newData, updatedData)) {
      setNewData(updatedData)
    }
  }

  useEffect(() => {
    builderData(newData)
  }, [newData])

  return (
    <div style={{
      display: contentEditor === 'builder' ? 'block' : 'none'
    }}>
      {initialData && (
        <ReactFormBuilder
          toolbarItems={toolbarItems}
          data={initialData}
          handleChangeData={handleChangeData}
          handleChangeSaved={handleChangeSaved}
          colors={daysGridDataColors}
          notificationOpen={notificationOpen}
        />
      )}
    </div>
  )
}

export default translate('translations')(Builder)
