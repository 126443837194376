import React, { Component } from 'react'
import { Grid, Row, Col, HelpBlock, FormGroup } from 'react-bootstrap'
import Button from '../../components/CustomButton/CustomButton'
import { FormInputs } from '../../components/FormInputs/FormInputs'
import { firebaseAuth } from '../../firebase'
import { withRouter, Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'
import Loading from '../../components/Loading/Loading'
import { connect } from 'react-redux'

import logo from '../../assets/img/reactlogo.png'

class Login extends Component {
  state = {
    errorEmail: null,
    errorPass1: null,
    errorPass2: null,
    errorGlobal: null,
    errorMessages: null,
    isAuthentificated: false
  }

  componentDidMount () {
    firebaseAuth.onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        this.setState({ ...this.state, isAuthentificated: true })
      }
    })
  }

  handleSubmit = event => {
    const { t } = this.props

    event.preventDefault()
    let errorEmail, errorPass
    const data = new FormData(event.target)
    const email = data.get('email')
    const password = data.get('password')
    if (email && password) {
      this.loginWithEmail(email, password)
    } else {
      if (!email) {
        errorEmail = t('auth.login.error.emailIsRequired')
      }
      if (!password) {
        errorPass = t('auth.login.error.passwordIsRequired')
      }
      this.setState({ ...this.state, errorPass: errorPass, errorEmail: errorEmail, errorGlobal: null })
    }
  }

  loginWithEmail = (email, password) => {
    const { t } = this.props

    this.setState({ ...this.state, loading: true })
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        const currentUser = firebaseAuth.currentUser
        if (currentUser.emailVerified) {
          // firebaseAuth.onAuthStateChanged(user => {
          //   if (user) {
          //     // user
          //   } else {
          //     firebaseAuth.signOut()
          //   }
          // })
          // this.props.history.push('/')
        } else {
          firebaseAuth.signOut().then(res => {
            this.setState({
              ...this.state,
              errorGlobal: t('auth.login.error.pleaseCheckEmailVerification'),
              loading: false
            })
          })
        }
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/wrong-password':
            this.setState({ ...this.state, errorGlobal: t('auth.login.error.invalidCredentials'), loading: false })
            break
          case 'auth/user-not-found':
            this.setState({ ...this.state, errorGlobal: t('auth.login.error.invalidCredentials'), loading: false })
            break
          case 'auth/invalid-email':
            this.setState({
              ...this.state,
              errorEmail: t('auth.login.error.invalidEmailFormat'),
              errorGlobal: null,
              loading: false
            })
            break
          case 'auth/too-many-requests':
            this.setState({ ...this.state, errorGlobal: t('auth.login.error.tooManyRequests'), loading: false })
            break
          default:
            this.setState({ ...this.state, errorGlobal: error.message, loading: false })
        }
        firebaseAuth.signOut()
      })
  }

  render () {
    const { t } = this.props

    return (
      <div className='content'>
        {this.state.isAuthentificated && <Redirect path='/' to='/dashboard' />}
        <Grid>
          <Row>
            <Col xs={0} sm={2} md={4} />
            <Col xs={12} sm={6} md={4}>
              <div className='logo' style={{ textAlign: 'center' }}>
                <a href='/' className='logo-mini'>
                  <div className='logo-img'>
                    <img src={logo} alt='logo_image' />
                  </div>
                </a>
              </div>
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId='formBasicText' validationState={this.state.errorEmail ? 'error' : null}>
                  <FormInputs
                    ncols={['col-md-12']}
                    value=''
                    proprieties={[
                      {
                        label: t('auth.login.email'),
                        type: 'text',
                        id: 'email',
                        name: 'email',
                        bsClass: 'form-control',
                        defaultValue: '',
                        placeholder: t('auth.login.error.pleaseEnterYourEmail'),
                        disabled: false
                      }
                    ]}
                  />
                  {this.state.errorEmail && <HelpBlock>{this.state.errorEmail}</HelpBlock>}
                </FormGroup>
                <FormGroup controlId='formBasicText' validationState={this.state.errorPass ? 'error' : null}>
                  <FormInputs
                    ncols={['col-md-12']}
                    proprieties={[
                      {
                        label: t('auth.login.password'),
                        type: 'password',
                        id: 'password',
                        name: 'password',
                        bsClass: 'form-control',
                        defaultValue: '',
                        placeholder: t('auth.login.error.pleaseEnterYourPassword'),
                        disabled: false
                      }
                    ]}
                  />
                  {this.state.errorPass && <HelpBlock>{this.state.errorPass}</HelpBlock>}
                </FormGroup>
                {this.state.errorGlobal && (
                  <FormGroup controlId='formBasicText' validationState='error'>
                    <HelpBlock>{this.state.errorGlobal}</HelpBlock>
                  </FormGroup>
                )}
                <Link to='/auth/reset-password'>
                  <Button simple>{t('auth.login.resetPassword')}</Button>
                </Link>
                <Button bsStyle='success' pullRight fill type='submit'>
                  {this.state.loading ? (
                    <Loading type='spin' color='white' height='20' width='20' />
                  ) : (
                    t('auth.login.login')
                  )}
                </Button>
                <div className='clearfix' />
              </form>
            </Col>
            <Col xs={0} sm={2} md={4} />
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeCompaniesData: companiesData => dispatch({ type: 'CHANGE_COMPANIES_DATA', value: companiesData }),
    changeActiveCompany: activeCompany => dispatch({ type: 'CHANGE_ACTIVE_COMPANY', value: activeCompany }),
    changeActiveCalendar: activeCalendar => dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: activeCalendar })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate('translations')(Login)))
