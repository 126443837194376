import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'
import Button from '../../../components/CustomButton/CustomButton'

class Message extends Component {
  render () {
    const { t } = this.props

    return (
      <div style={{ textAlign: 'center' }}>
        <i className={this.props.icon} style={{ fontSize: '150px', marginBottom: '20px', color: 'green' }} />
        <h3>{this.props.title}</h3>
        <p>{this.props.text}</p>
        {!this.props.withoutbutton && (
          <Link to='/auth/login'>
            <Button fill bsStyle='success'>
              {t('auth.message.login')}
            </Button>
          </Link>
        )}
      </div>
    )
  }
}

export default translate('translations')(Message)
