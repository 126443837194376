import React, { useState, useEffect } from 'react'
import { Grid, Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from './../../components/Card/Card'
import { firebaseDb as db } from '../../firebase'
import { translate } from 'react-i18next'
import _ from 'lodash'
import termsConditionsTemplates from './terms_conditions_templates.json'
import privacyPolicyTemplates from './privacy_policy_templates.json'

// Redux
import { useSelector } from 'react-redux'

// Translation model
import { read } from '../../helpers/translationModel/read'
import { write } from '../../helpers/translationModel/write'
import { Modal, Radio } from 'antd'

const thArray = ['Visibility', 'Type', 'Label']

const CalInforPages = props => {
  const [dataPagesList, setDataPagesList] = useState()
  const [modalVisible, setModalVisible] = useState()
  const [templateSelection, setTemplateSelection] = useState()

  // Redux
  const {
    activeCompany,
    activeCalendar,
    calendarLang,
    calendarLangPrimary
  } = useSelector(state => state)

  useEffect(() => {
    loadFromDb()
  }, [activeCompany, activeCalendar, calendarLang])

  const loadFromDb = () => {
    activeCompany &&
      activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          // Loaded from translations
          // Replace data with translations
          data = await read(
            activeCompany,
            activeCalendar,
            calendarLangPrimary,
            calendarLang,
            'settings/grid',
            data
          )

          setDataPagesList(data.infoModalsList)
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'done')
    } else if (type === 'error') {
      const message = 'Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  const addNewPage = async () => {
    setModalVisible(false)
    const templates = {
      'termsAndConditions': termsConditionsTemplates,
      'privacyPolicy': privacyPolicyTemplates,
      'emptyInfoPage': '',
    }
    const templateText = templates[templateSelection] && templates[templateSelection][calendarLang] ?
      await fetch(`/templates/${templates[templateSelection][calendarLang].templateName}`)
        .then(res => res.text()) : ''
    const label = templates[templateSelection] && templates[templateSelection][calendarLang] ?
      templates[templateSelection][calendarLang].title : 'New info page'

    let newId =
      dataPagesList && dataPagesList[calendarLang] &&
      parseInt(Object.keys(dataPagesList[calendarLang])[Object.keys(dataPagesList[calendarLang]).length - 1]) + 1

    if (isNaN(newId)) {
      newId = 1
    }

    let udpateContent = { ...(dataPagesList && dataPagesList[calendarLang] || {}) }
    udpateContent[newId] = {
      label: label,
      linkUrl: '',
      text: templateText,
      type: 'page',
      visibility: true
    }

    const infoModalsList = {
      ...(dataPagesList || {}),
      [calendarLang]: udpateContent
    }

    write(
      activeCompany,
      activeCalendar,
      calendarLangPrimary,
      calendarLang,
      'settings/grid',
      { infoModals: udpateContent, infoModalsList }
    )
      .then(() => {
        db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
          .update({ changeToPublish: true })
          .then(res => {
            props.history.push(
              '/company/' +
              activeCompany +
              '/calendar/' +
              activeCalendar +
              '/calinfopage/' +
              newId
            )
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        notificationOpen('error', error)
      })
  }

  const removePage = pageKey => {
    let udpateContent = { ...dataPagesList[calendarLang] }
    delete udpateContent[pageKey]

    const infoModalsList = {
      ...(dataPagesList || {}),
      [calendarLang]: udpateContent
    }

    setDataPagesList(infoModalsList)

    write(
      activeCompany,
      activeCalendar,
      calendarLangPrimary,
      calendarLang,
      'settings/grid',
      { infoModalsList }
    )
      .then(() => {
        db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
          .update({ changeToPublish: true })
          .then(res => { })
          .catch(error => {
            console.log(error)
          })
        notificationOpen('success')
      })
      .catch(error => {
        notificationOpen('error', error)
      })
  }

  const { t } = props

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={t('calInfoPages.infoPages')}
              category={t('calInfoPages.ussuallyShown')}
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      {thArray.map((prop, key) => {
                        return <th key={key}>{prop}</th>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {dataPagesList && dataPagesList[calendarLang] &&
                      _.map(dataPagesList[calendarLang], (page, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: '100px' }}>
                              {page.visibility ? (
                                <span>
                                  <i className='pe-7s-look' />{' '}
                                  {t('calInfoPages.visible')}
                                </span>
                              ) : (
                                <span>
                                  <i className='pe-7s-less' />{' '}
                                  {t('calInfoPages.hidden')}
                                </span>
                              )}
                            </td>
                            <td style={{ width: '100px' }}>
                              {page.type === 'page' ? (
                                <span>
                                  <i className='pe-7s-menu' />{' '}
                                  {t('calInfoPages.page')}
                                </span>
                              ) : (
                                <span>
                                  <i className='pe-7s-link' />{' '}
                                  {t('calInfoPages.link')}
                                </span>
                              )}
                            </td>
                            <td>{page.label}</td>
                            <td style={{ width: '170px' }}>
                              <Link
                                className=''
                                to={
                                  '/company/' +
                                  props.match.params.companyId +
                                  '/calendar/' +
                                  props.match.params.calendarId +
                                  '/calinfopage/' +
                                  index
                                }
                              >
                                <button className='btn btn-sm btn-info'>
                                  {t('calInfoPages.edit')}
                                </button>
                              </Link>
                              <button
                                onClick={() => removePage(index)}
                                style={{ marginLeft: '5px' }}
                                className='btn btn-sm btn-warning'
                              >
                                {t('calInfoPages.remove')}
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    <tr>
                      <td colSpan='4' style={{ textAlign: 'center' }}>
                        <button
                          onClick={() => setModalVisible(true)}
                          className='btn btn-sm btn-success'
                        >
                          {t('calInfoPages.addNewInfoPage')}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
        <Modal
          style={{ top: '20%' }}
          visible={modalVisible}
          destroyOnClose
          onCancel={() => setModalVisible(false)}
          onOk={() => addNewPage()}
          okButtonProps={{ disabled: !templateSelection }}
          title={t('calInfoPages.selectTemplate')}
        >
          <Radio.Group
            size="small"
            onChange={e => setTemplateSelection(e.target.value)}
            style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
            <Radio value='termsAndConditions'>{t('calInfoPages.termsAndConditions')}</Radio>
            <Radio value={'privacyPolicy'}>{t('calInfoPages.privacyPolicy')}</Radio>
            <Radio value={'emptyInfoPage'}>{t('calInfoPages.emptyInfoPage')}</Radio>
          </Radio.Group>
        </Modal>
      </Grid >
    </div >
  )
}

export default translate('translations')(CalInforPages)
