import { Collapse } from 'antd';
import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../../../../components/Card/Card'
import { translate } from 'react-i18next'

import image_0_1 from '../../../../../assets/img/tutorial/0_1.png'
import image_2_2 from '../../../../../assets/img/tutorial/2_2.png'
import image_5_4 from '../../../../../assets/img/tutorial/5_4.png'
import image_7_1 from '../../../../../assets/img/tutorial/7_1.png'
import image_8_2 from '../../../../../assets/img/tutorial/8_2.png'
import image_8_3 from '../../../../../assets/img/tutorial/8_3.png'
import image_9_2 from '../../../../../assets/img/tutorial/9_2.png'

class Tutorial extends Component {
  render() {
    const { t } = this.props

    const { Panel } = Collapse;

    const text = `
      A dog is a type of domesticated animal.
      Known for its loyalty and faithfulness,
      it can be found as a welcome guest in many households across the world.
    `;

    const styleImage = { border: '1px solid #1AC0E7', margin: '5px 0 5px 0', maxWidth: '90%' }
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={12}>

              <Card
                title='How to set up you Advent Calendar'
                category=''
                bigTitle
                content={
                  <div>
                    <Collapse accordion defaultActiveKey={['1']}>
                    <Panel header="1. What is good to know?" key="1" className='typo-line'>
                      <p>
                        Please note that all images should have a size less than <b>1Mb</b> (background image could have
                        2Mb) and be in <b>PNG or JPG</b> format. Smaller images are the better user experience is.
                        <br />
                        You can start wherever you want with content filling and setting ... but! ... When you go step
                        by step guided by this tutorial you can avoid double work.
                        <br />
                        When you change the content of a calendar it won't display immediately you have to Publish to
                        see current changes.
                        <br />
                        <br />
                        When you are editing content please use <b>save button</b>. Only images are uploaded without any
                        confirmation and Grid being saved automatically.
                        <br />
                        <br />
                        <img src={image_0_1} alt='Tutorial 0-1' style={styleImage} />
                        <br />
                        <br />
                        <b>Predefined colors</b> are helpful to keep the style consistent - could be set under style
                        settings.
                        <br />
                        <br />
                        Changes could be viewed when the calendar is published under Live preview link at the bottom of
                        the sidebar.
                        <br />
                        <br />
                        <b>Calendar blocking</b> is manageable via settings under Calendar blocking tab.
                      </p>
                    </Panel>
                    <Panel header="2. Edit Style" key="2" className='typo-line'>
                      <p>
                      Start with style setting, you will find the following options.
                        <br />
                        <br />
                        <ul>
                          <li>Theme Color (predefine colors which can be used later in boxes and text editors)</li>
                          <li>Box Animation</li>
                          <li>Dislay Day Number in Box</li>
                          <li>Number of Columns in Grid</li>
                          <li>Button Styling</li>
                          <li>Logo</li>
                          <li>Background Image</li>
                          <li>Favicon</li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="3. Set up Header" key="3" className='typo-line'>
                      <p>
                        Under Header, you will find the following sections.
                        <br />
                        <br />
                        <ul>
                          <li>Button Styling</li>
                          <li>Header</li>
                          <li>Reminder</li>
                          <li>Newsletter</li>
                        </ul>
                        <b>Button Styling -</b> Select the checkbox <b>"Use custom style button"</b> to style buttons.
                        <br />
                        <b>Header -</b> The header can be displayed or hidden by check the checkbox <b>"Display Header"</b> Add Header image, title, content, button label and button link.
                        <br />
                        <b>Reminder -</b> The reminder can be displayed or hidden by check the checkbox <b>"Display Reminder"</b>. Add title, content, button label and button link.
                        <br />
                        <b>Newsletter -</b> The newsletter can be displayed or hidden by check the checkbox <b>"Display Newsletter"</b> Add title, content, button label and button link.
                        <br />
                        <br />
                        When changing the text of header it will adjust the height of the element automatically (default
                        CSS setting).
                        <br />
                        It is better to <b>start with text</b> and then upload the right size of an image.
                        <br />
                        <br />
                        To hide header button just let Link label empty.
                        <br />
                        <br />
                        <img src={image_2_2} alt='Tutorial 2-2' style={styleImage} />
                      </p>
                    </Panel>
                    <Panel header="4. Newsletter" key="4" className='typo-line'>
                      <p>You can manage newsletter's visibility, short text, and links.</p>
                    </Panel>
                    <Panel header="5. Days" key="5" className='typo-line'>
                      <p>
                        You can set as many days as you want. In this tab, you only manage the content and style of each
                        day.
                        <br />
                        <br />
                        Each day has two tabs (Content / Settings)
                        <br />
                        <br />
                        <b>Content -</b> you will find the following options.
                        <br />
                        <br />
                        <ul>
                          <li>Buttons</li>
                          <li>Form Submit Button</li>
                          <li>Header Text</li>
                          <li>Paragraph</li>
                          <li>Dropdown</li>
                          <li>Checkboxes</li>
                          <li>Select Radio</li>
                          <li>Text Input</li>
                          <li>Email Input</li>
                          <li>Number Input</li>
                          <li>Multi-line Input</li>
                          <li>Image</li>
                          <li>HTML Code</li>
                          <li>TypeForm</li>
                          <li>Mockup Form</li>
                        </ul>
                        <b>Settings -</b> You can add here Day Number, Link, Start Date, End Date, Icon, Background Color, Articla Image (this Article image will be at the top of a modal content)
                        <br />
                        <br />
                        When creating a form to be sent to Hubspot please fill HUBSPOT FORM ID and add a button with
                        type "submit" to make possible form to be submitted. You can also add hidden field "clicks" with
                        the number of clicks to be sent to the Hubspot.
                        <br />
                        <img src={image_5_4} alt='Tutorial 5-4' style={styleImage} />
                        <br />
                        <br />
                        The composition of boxes is being set under the Grid setting.
                        <br />
                        For default style please remove field "Class" under box properties.
                      </p>
                    </Panel>
                    <Panel header="6. Grid" key="6" className='typo-line'>
                      <p>
                        The grid can be changed by drag and dropping days. Size of a day can be also changed very
                        easily. When you change position or size it is <b>automatically saved</b> to the database.
                        <br />
                        Please change grid <b>after all boxes are added</b>.
                      </p>
                    </Panel>
                    <Panel header="7. Info Pages" key="7" className='typo-line'>
                      <p>
                        Under Info page, Click on "Add new info page" button. Open modal-popup with three templates <b>"Terms and Conditions"</b>, <b>"Privacy Policy"</b> and <b>"Empty info page"</b>. Select Template.
                        <br />
                        You can add as many info pages as you want. 
                        <br />
                        <br />
                        You will find the following options.
                        <br />
                        <br />
                        <ul>
                          <li>Visibilty (Info pages are linked at the bottom of a page or not)</li>
                          <li>Type (There are two types of info page.)
                            <ul>
                              <li>External link to website</li>
                              <li>Pages with a content (which is a pop up modal)</li>
                            </ul>
                          </li>
                          <li>Lable</li>
                          <li>Content</li>
                        </ul>
                        <img src={image_7_1} alt='Tutorial 7-1' style={styleImage} />
                      </p>
                    </Panel>
                    <Panel header="8. Settings" key="8" className='typo-line'>
                      <p>
                        Under settings, you will find the following sections.
                        <br />
                        <br />
                        <ul>
                          <li>General Setting</li>
                          <li>Calendar Language</li>
                          <li>Authentication</li>
                          <li>Social Networs</li>
                          <li>Calendar Blocking</li>
                          <li>API's</li>
                          <li>Translation</li>
                          <li>OG: Tags</li>
                        </ul>
                        <b>General Settings -</b> You can change Calendar Name, Internal Calendar Name, Time Zone and Display previous days or not. 
                        <br />
                        <b>Calendar Language -</b> You can select the Calendar language and add language.
                        <br />
                        <b>Authentication -</b> You can select your calendar is password protected or not. 
                        <br />
                        <b>Social Networks -</b> When you need to add social buttons just put down a link and icon will be displayed at the page. Here is another option if you want to use stock icons then click on checkbox (use stock icons).
                        <br />
                        <b>Calendar Blocking -</b> You can select Calendar Launch Date, ShutDown Date, Modal background color, Display Modals after shutdown date, Design of launch Modal, Modal Info
                        <br />
                        <b>Translation -</b>
                        <br />
                        <b>OG Tags -</b> You can add here SEO stuff. Title, Description, Type, URL and Image
                        <br />
                        <br />
                        <b>API's -</b> It is highly recommended to use third-party services like <b>Google Analytics</b> and HubSpot to
                        track visitors and manage data.
                        <br />
                        <br />
                        <b>Hubspot</b> tracking ID will also open a possibility to create cookie banner.
                        <br />
                        <img src={image_8_2} alt='Tutorial 8-2' style={styleImage} />
                        <br />
                        <br />
                        With <b>Google Analytics</b>, you will be able to track the opening of modals.
                        <br />
                        <img src={image_8_3} alt='Tutorial 8-3' style={styleImage} />
                      </p>
                    </Panel>
                    <Panel header="9. Publish Implementation" key="9" className='typo-line'>
                      <p>
                        To send current changes of the calendar page just publish your data via <b>publish button</b>.
                        If it is orange it means that current changes aren't published yet.
                        <br />
                        <img src={image_9_2} alt='Tutorial 9-2' style={styleImage} />
                        <br />
                        <br />
                        Under <b>Implementation</b> tab, you can find embed code to be inserted on your hosting.
                      </p>
                    </Panel>
                    <Panel header="10. Check published content and adjust" key="10" className='typo-line'>
                      <p>
                        To see live preview hit the button at the bottom "LIVE PREVIEW". You can make any changes to
                        adjust the content of your calendar page but please note that without publishing changes you
                        won't see any in the calendar page.
                      </p>
                    </Panel>
                    </Collapse>
                  </div>
                }
              />
              <Card
                title='How to duplicate the Advent Calendar'
                category=''
                bigTitle
                content={
                  <div>
                    <Collapse accordion>
                    <Panel header="1. What is good to know?" key="1" className='typo-line'>
                      <p>
                        Every data will be replicated from source calendar except submits. Coping can take up to 30
                        seconds, please be patience.
                      </p>
                    </Panel>
                    <Panel header="2. Select name and copy!" key="2" className='typo-line'>
                      <p>
                        Visit settings page and hit "{t('calSettings.duplicateButton')}" button. Select name for your
                        new calendar and copy calendar with button "{t('duplicateCalendar.duplicateButton')}"
                      </p>
                    </Panel>
                    </Collapse>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default translate('translations')(Tutorial)
