import React from 'react'
import { Col } from 'react-bootstrap'
import { StatsCard } from './../../../components/StatsCard/StatsCard'
import { translate } from 'react-i18next'

const AdminTab = (props) => {
  const { history, t } = props

  const redirectToAdminTable = () => {
    history.push('/admin')
  }
  return (
    <Col lg={4} sm={6}>
      <div style={{ cursor: 'pointer' }} onClick={() => redirectToAdminTable()} >
        <StatsCard
          bigIcon={<i className='pe-7s-flag text-info' />}
          statsText='Admin'
          statsValue='Settings'
          statsIcon={
            <div>
              <div className='btn btn-xs btn-success' onClick={() => redirectToAdminTable()}>
                {t('dashboard.companyTab.open')}
              </div>
            </div>
          }
        />
      </div>
    </Col >
  )
}

export default translate('translations')(AdminTab)
