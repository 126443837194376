import { Collapse } from 'antd';
import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import Card from '../../../../../components/Card/Card'
import { translate } from 'react-i18next'

import image1 from '../../../../../assets/img/tutorial/image1.png'
import image2 from '../../../../../assets/img/tutorial/image2.png'
import image3 from '../../../../../assets/img/tutorial/image3.png'
import image4 from '../../../../../assets/img/tutorial/image4.png'
import image5 from '../../../../../assets/img/tutorial/image5.png'
import image6 from '../../../../../assets/img/tutorial/image6.png'
import image7 from '../../../../../assets/img/tutorial/image7.png'
import image8 from '../../../../../assets/img/tutorial/image8.png'
import image9 from '../../../../../assets/img/tutorial/image9.png'
import image10 from '../../../../../assets/img/tutorial/image10.png'
import image11 from '../../../../../assets/img/tutorial/image11.png'
import image12 from '../../../../../assets/img/tutorial/image12.png'
import image13 from '../../../../../assets/img/tutorial/image13.png'
import image14 from '../../../../../assets/img/tutorial/image14.png'
import image15 from '../../../../../assets/img/tutorial/image15.png'
import image16 from '../../../../../assets/img/tutorial/image16.png'
import image17 from '../../../../../assets/img/tutorial/image17.png'
import image18 from '../../../../../assets/img/tutorial/image18.png'
import image19 from '../../../../../assets/img/tutorial/image19.png'
import image20 from '../../../../../assets/img/tutorial/image20.png'
import image21 from '../../../../../assets/img/tutorial/image21.png'
import image22 from '../../../../../assets/img/tutorial/image22.png'

class Tutorial extends Component {
  render() {
    const { t } = this.props

    const { Panel } = Collapse;

    const text = `
      A dog is a type of domesticated animal.
      Known for its loyalty and faithfulness,
      it can be found as a welcome guest in many households across the world.
    `;

    const styleImage = { border: '1px solid #1AC0E7', margin: '5px 0 5px 0', maxWidth: '90%' }
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col md={12}>

              <Card
                title='How to set up you Advent Calendar'
                category={<p>
                  Please note that all images should have a size less than 1Mb (background image could have
                  2Mb) and be in PNG or JPG format. Smaller images are the better user experience is.
                  <br />
                  You can start wherever you want with content filling and setting ... but! ... When you go step
                  by step guided by this tutorial you can avoid double work.
                  <br />
                  <br />
                  When you make any changes to any section, always click the update button in order to save the changes. Only the Grid editing is being saved automatically.
                  <br />
                  <br />
                  When you change the content of a calendar it won't display immediately you have to Publish to
                  see current changes.
                  <br />
                  <br />
                  Changes could be viewed when the calendar is published under Live preview link at the bottom of
                  the sidebar.
                  <br />
                  <br />
                  Calendar blocking is manageable via settings under Calendar blocking tab.
                </p>}
                bigTitle
                centerTitle
                content={
                  <div>
                    <Collapse accordion>
                    <Panel header="1. Set up Header" key="1" className='typo-line'>
                      <p>
                        Under Header, you will find the following sections.
                        <br />
                        <ul>
                          <li>Button Styling</li>
                          <li>Edit Header</li>
                          <li>Reminder</li>
                          <li>Newsletter</li>
                          <li>Sponsers</li>
                        </ul>
                        <b>Button Styling -</b> Select the checkbox "Use custom style button" to style buttons.
                        <br />
                        <img src={image9} alt='Tutorial 1-1' style={styleImage} />
                        <br />
                        <br />
                        <b>Header -</b> The header can be displayed or hidden by check the checkbox "Display Header" Add Header image, title, content, button label and button link.
                        <br />
                        <ul>
                          <li>
                            In the header, a video can also be added instead of an image 
                          </li>
                          <li>
                            We can also separate the header text and image. In this case the mobile will take the full width of calendar and header text will go underneath the header image with same full width 
                          </li>
                          <li>
                            The mobile preferences can also be set 
                          </li>
                          <li style={{listStyle: 'none'}}>
                            <img src={image4} alt='Tutorial 1-2' style={styleImage} />
                          </li>
                        </ul>
                        <br />
                        <b>Reminder -</b> The reminder can be displayed or hidden by check the checkbox "Display Reminder". Add title, content, button label and button link.
                        <br />
                        <br />
                        <b>Newsletter -</b> The newsletter can be displayed or hidden by check the checkbox "Display Newsletter" Add title, content, button label and button link.
                        <br />
                        <br />
                        <b>Sponsors -</b> The Sponsors can be displayed or hidden by checking the checkbox "Display Sponsors" 
                        <br />
                        <ul>
                          <li>Add logos, more than 1 logo can be added in sponsors, in case more than 1 logo it will be a slider respectively for each logo</li>
                          <li>Add content/text</li>
                          <li>Sponsors link label and Sponsors link URL</li>
                          <li style={{listStyle: 'none'}}>
                            <img src={image8} alt='Tutorial 1-3' style={styleImage} />
                          </li>
                        </ul>
                        <br />
                        When changing the text of header it will adjust the height of the element automatically (default
                        CSS setting).
                        <br />
                        It is better to start with text and then upload the right size of an image.
                        <br />
                        <br />
                        <ul>
                          <li>To hide the buttons from the header elements just let Link label empty.</li>
                          <li>When you make any change do not forget to click the update button </li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="2. Days" key="2" className='typo-line'>
                      <p>
                        You can set as many days as you want. In this tab, you only manage the content and style of each
                        day.
                        <br />
                        <br />
                        Each day has two tabs (Content / Settings)
                        <br />
                        <br />
                        <b>Content -</b> you will find the following options.
                        <br />
                        <ul>
                          <li>Buttons</li>
                          <li>Form Submit Button</li>
                          <li>Header Text</li>
                          <li>Paragraph</li>
                          <li>Dropdown</li>
                          <li>Checkboxes</li>
                          <li>Select Radio</li>
                          <li>Text Input</li>
                          <li>Email Input</li>
                          <li>Number Input</li>
                          <li>Multi-line Input</li>
                          <li>Image</li>
                          <li>HTML Code</li>
                          <li>TypeForm</li>
                          <li>Mockup Form</li>
                        </ul>
                        <b>Form Submit button -</b> for the form submission preferably use this button instead if button.
                        <br />
                        <img src={image5} alt='Tutorial 2-1' style={styleImage} />
                        <br />
                        <br />
                        <b>Settings -</b> You can add here 
                        <ul>
                          <li>Day Number</li> 
                          <li>Link</li> 
                          <li>Start Date</li> 
                          <li>End Date</li> 
                          <li>Icon position</li> 
                          <li>Background Color</li> 
                          <li>Articla Image (this Article image will be at the top of a modal content)</li>
                          <li>Icon (which will be displayed on the door)</li>
                          <li>Content icon (which will be displayed on the opened door )</li>
                        </ul>
                        When creating a form to be sent to Hubspot please fill HUBSPOT FORM ID and add a button with
                        type "submit" to make possible form to be submitted. You can also add hidden field "clicks" with
                        the number of clicks to be sent to the Hubspot.
                        <br />
                        <br />
                        <ul>
                          <li>We can add, delete and clone the day.</li>
                          <li>The composition of boxes is being set under the Grid setting.</li>
                          <li>For default style please remove field "Class" under box properties.</li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="3. Grid" key="3" className='typo-line'>
                      <p>
                        The grid can be changed by drag and dropping days. Size of a day can be also changed very
                        easily. When you change position or size it is automatically saved to the database.
                        <ul>
                          <li>Choose the advent calendar grid style</li>
                          <li>Please change the grid layout after adding all the doors</li>
                          <li style={{listStyle: 'none'}}>
                            <img src={image14} alt='Tutorial 1-1' style={styleImage} />
                          </li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="4. Style" key="4" className='typo-line'>
                      <p>
                        Start with style setting, you will find the following options.
                        <ul>
                          <li>Design manual for the images - you can download the design manual for the images</li>
                          <li>Theme Color (predefine colors which can be used later in boxes and text editors) you can add/define more colors</li>
                          <li>Box Animation (animation for the door)</li>
                          <li>Display Day Number in Box</li>
                          <li>Number of Columns in Grid</li>
                          <li>Button Styling</li>
                          <li>
                            Logo - you can define logo position, width etc.
                            <br />
                            <ul>
                              <li>You can also add the language specific logo. Language specific logo has the priority, if you have the uploaded the logo and also uploaded to the language specific then the language specific will be displayed on the FE , if no language specific logo is inserted then the default logo will be displayed for all the languages.</li>
                              <br />
                              <li style={{listStyle: 'none'}}>
                                <img src={image19} alt='Tutorial 4-1' style={styleImage} />
                              </li>
                            </ul>
                            <br />
                          </li>
                          <li>Background Image</li>
                          <li>Favicon</li>
                          <li>Font family - If you want to use any other font than the default one.</li>
                          <li>Custom CSS editor</li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="5. Info Pages" key="5" className='typo-line'>
                      <p>
                        Under Info page, Click on "Add new info page" button. Open modal-popup with three templates <b>"Terms and Conditions"</b>, <b>"Privacy Policy"</b> and <b>"Empty info page"</b>. Select Template.
                        <br />
                        <br />
                        You can add as many info pages as you want. 
                        <br />
                        <img src={image6} alt='Tutorial 5-1' style={styleImage} />
                        <br />
                        <br />
                        You will find the following options.
                        <br />
                        <br />
                        <ul>
                          <li>Visibility (Info pages are linked at the bottom of a page or not)</li>
                          <li>Type (There are two types of info page.)
                            <ul>
                              <li>External link to website</li>
                              <li>Pages with a content (which is a pop up modal)</li>
                            </ul>
                          </li>
                          <li>Label</li>
                          <li>Content</li>
                          <li style={{listStyle: 'none'}}>
                            <img src={image18} alt='Tutorial 5-2' style={styleImage} />
                          </li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="6. Settings" key="6" className='typo-line'>
                      <p>
                        Under settings, you will find the following sections.
                        <br />
                        <br />
                        <ul>
                          <li>General Setting</li>
                          <li>Calendar Languages</li>
                          <li>Auth - required password</li>
                          <li>Social Networks</li>
                          <li>Calendar Blocking</li>
                          <li>API's</li>
                          <li>Translation</li>
                          <li>OG: Tags</li>
                        </ul>
                        <b>General Settings -</b> You can change Calendar Name, Internal Calendar Name, Time Zone and Display previous days or not. 
                        <br />
                        <br />
                        <ul>
                          <li>You can choose the timezone, this will be applied to the calendar blocking, launch and days start and stop date</li>
                        </ul>
                        Calendar can also be duplicated
                        <br />
                        <img src={image13} alt='Tutorial 6-1' style={styleImage} />
                        <br />
                        <br />
                        <b>Calendar Language -</b> You can select the Calendar language and add language. Languages can also be deleted here.
                        <br />
                        <br />
                        You can also find the display/not display language switcher on the FE (on the frontend of the calendar on top right)
                        <br />
                        <img src={image7} alt='Tutorial 6-2' style={styleImage} />
                        <br />
                        <br />
                        <b>Auth - required password -</b> You can select if you want to protect calendar with password or not, settings can be found here for the calendar authentication 
                        <br />
                        <img src={image1} alt='Tutorial 6-3' style={styleImage} />
                        <br />
                        <br />
                        <b>Social Networks -</b> When you need to add social buttons just put down a link and icon will be displayed at the page. Here is another option if you want to use stock icons then click on checkbox (use stock icons).
                        <br />
                        <br />
                        You can also select 
                        <br />
                        <br />
                        <ul>
                          <li>Icon color</li>
                          <li>Icon size</li>
                          <li>Icon shape</li>
                        </ul>
                        <br />
                        <img src={image10} alt='Tutorial 6-4' style={styleImage} />
                        <br />
                        <br />
                        <b>Calendar Blocking -</b> You can select Calendar Launch Date, ShutDown Date, Modal background color, Display Modals after shutdown date, Design of launch Modal, Modal Info.
                        <br />
                        <br />
                        <b>Disable form submissions -</b> besides the launch and shutdown date, you will find an extended feature of form submissions, where you can on/enable the toggle if you want to prevent the visitors to submit the form. Form submissions Disbale date can be set and message can be inserted in the text editor that will be displayed when a visitor will try to submit the form. 
                        <br />
                        <img src={image11} alt='Tutorial 6-5' style={styleImage} />
                        <br />
                        <br />
                        <b>Inactive door colors -</b> you can also see the color picker where you can select the color for the inactive doors/days 
                        <br />
                        <img src={image3} alt='Tutorial 6-6' style={styleImage} />
                        <br />
                        <br />
                        <b>Translation -</b>
                        <br />
                        <br />
                        <b>OG Tags -</b> You can add here SEO stuff. Title, Description, Type, URL and Image
                        <br />
                        <br />
                        <b>APIs:</b> You can add integrate the GA and GTM tracking codes 
                        <br />
                        <br />
                        Hubspot tracking ID will also open a possibility to create cookie banner.
                        <br />
                        <img src={image16} alt='Tutorial 6-7' style={styleImage} />
                        <br />
                        <br />
                        With Google Analytics, you will be able to track the opening of modals.
                        <br />
                        <img src={image12} alt='Tutorial 6-8' style={styleImage} />
                      </p>
                    </Panel>
                    <Panel header="7. Implementation" key="7" className='typo-line'>
                      <p>
                        Under Implementation tab, you can find embed code to be inserted on your hosting.
                        <br />
                        <img src={image2} alt='Tutorial 7-1' style={styleImage} />
                      </p>
                    </Panel>
                    <Panel header="8. File Storage" key="8" className='typo-line'>
                      <p>
                        Under file storage you can upload all the files (images, videos, pdfs etc..) 
                        <br />
                        <br />
                        You can also manage the file storage with the following:
                        <br />
                        <br />
                        <ul>
                          <li>You can create folders</li>
                          <li>You can upload files into file storage and folders </li>
                          <li><b>Actions:</b> you can select files to perform actions, you can clear the selection </li>
                          <li>You can display them in grid view and list view </li>
                          <li><b>Options:</b> you can sort them by name and by date, you can also sort to display the folders first.</li>
                        </ul>
                        <br />
                        <ul>
                          <li>You can also search the files from the file storage with the search field. </li>
                          <li style={{listStyle: 'none'}}>
                            <img src={image20} alt='Tutorial 8-1' style={styleImage} />
                          </li>
                        </ul>
                      </p>

                    </Panel>
                    <Panel header="9. Backup" key="9" className='typo-line'>
                      <p>
                        You will find the Restore functionality. It will delete all the changes is done after the selected backup, then overwrite the settings, day contents and translations.
                        <br />
                        <br />
                        It has the backup ID, date you made the backup and the action to restore and delete them.
                        <br />
                        After clicking yes, it will restore the backup and display a notification 
                        <br />
                        <br />
                        <img src={image15} alt='Tutorial 9-1' style={styleImage} />
                        <br />
                        <img src={image17} alt='Tutorial 9-1' style={styleImage} />
                        <br />
                        <br />
                        <br />
                        <b>Followings we needs to consider:</b>
                        <br />
                        <br />
                        <ul>
                          <li>After 3 seconds, page will be refreshed because the language changes should be read</li>
                          <li>Only these tabs are part of the backups. Submits and File Storage are not included to backups.</li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="10. Statistics" key="10" className='typo-line'>
                      <p>
                        <ul>
                          <li>In this tab, you can see the statistics of your calendar where you can find number of clicks respectively for each door</li>
                          <li>You can also reset the statistics/number of clicks of your calendar doors, so you can find the clean statistics after the making the calendar live.</li>
                          <li style={{listStyle: 'none'}}>
                            <img src={image21} alt='Tutorial 10-1' style={styleImage} />
                          </li>
                        </ul>
                      </p>
                    </Panel>
                    <Panel header="11. Submits" key="11" className='typo-line'>
                      <p>
                        You will find the form submission have been submitted by the calendar visitors when they find and submit form on the door.
                        <ul>
                          <li>You can view the submission</li>
                          <li>You can see the submission date, the door from where the form submitter’s email etc.</li>
                          <li>You can export the submissions as the CSV file</li>
                          <br />
                          <li style={{listStyle: 'none'}}>
                            <img src={image22} alt='Tutorial 11-1' style={styleImage} />
                          </li>
                        </ul>
                      </p>
                    </Panel>
                    </Collapse>
                  </div>
                }
              />
              <Card
                title='How to duplicate the Advent Calendar'
                category=''
                bigTitle
                content={
                  <div>
                    <Collapse accordion>
                    <Panel header="1. What is good to know?" key="1" className='typo-line'>
                      <p>
                        Every data will be replicated from source calendar except submits. Coping can take up to 30
                        seconds, please be patience.
                      </p>
                    </Panel>
                    <Panel header="2. Select name and copy!" key="2" className='typo-line'>
                      <p>
                        Visit settings page and hit "{t('calSettings.duplicateButton')}" button. Select name for your
                        new calendar and copy calendar with button "{t('duplicateCalendar.duplicateButton')}"
                      </p>
                    </Panel>
                    </Collapse>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default translate('translations')(Tutorial)
