import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

const defaultModules = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, false] }],
    [{ align: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image'],
    ['clean']
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
     modules: ['Resize', 'DisplaySize']
  }
}

const ResizableQuill = ({modules, ...props}) => {
  return (
    <ReactQuill modules={modules ? modules : defaultModules} className='react-quill-custom' {...props} />
  )
}

export default ResizableQuill