import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { StatsCard } from './../../../components/StatsCard/StatsCard'
import { translate } from 'react-i18next'
import moment from 'moment'

class CompanyTab extends Component {
  redirectToCompany = companyId => {
    this.props.changeActiveCompany(companyId)
    this.props.history.push('/company/' + companyId + '/dashboard')
  }

  render() {
    const { t } = this.props

    let validUnitil = new Date(this.props.validUntil.toDate())
    validUnitil = moment(validUnitil).format('DD.MM.YYYY')
    return (
      <Col lg={4} sm={6}>
        <div style={{ cursor: 'pointer' }} onClick={() => this.redirectToCompany(this.props.index)} >
          <StatsCard
            bigIcon={<i className='pe-7s-flag text-info' />}
            statsText={this.props.numCalendars + '/' + this.props.calendarsLimit}
            statsValue={this.props.companyName}
            statsIcon={
              <div>
                <div className='btn btn-xs btn-success' onClick={() => this.redirectToCompany(this.props.index)}>
                  {t('dashboard.companyTab.open')}
                </div>
                {/* Hide extend button for now */}
                {/* <div
                className='btn btn-xs btn-default'
                onClick={() => this.redirectToCompany(this.props.index)}
                style={{ marginLeft: '5px' }}
                >
                {t('dashboard.companyTab.extend')}
              </div> */}
              </div>
            }
            statsIconText={
              <div>
                <div>
                  {t('dashboard.companyTab.licenceValid')} {validUnitil}
                </div>
              </div>
            }
          />
        </div>
      </Col >
    )
  }
}

export default translate('translations')(CompanyTab)
