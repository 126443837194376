import React from 'react'
import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Button from '../../../components/CustomButton/CustomButton'
import { translate } from 'react-i18next'
import Starship from '../BoxAnimations/Starship'
import SingleDoor from '../BoxAnimations/SingleDoor'
import Flip180v from '../BoxAnimations/Flip180v'
import Flip180h from '../BoxAnimations/Flip180h'
import Fadeout from '../BoxAnimations/Fadeout'
import Window from '../BoxAnimations/Window'

const Modal = ({ customClass, show, closeCallback, boxAnimation, handleChangeBoxAnimation, t }) => (
  <div className={`modal ${customClass}`} style={{ display: show ? 'block' : 'none' }}>
    <div className='overlay' onClick={closeCallback} />
    <div
      className='modal_content'
      style={{
        width: '80%',
        maxWidth: '1000px',
        maxHeight: '700px',
        height: '80%',
        display: show ? 'block' : 'none'
      }}
    >
      <Row>
        <Col>
          <h2>{t('calStyle.animationGallery.animations.window')}</h2>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>
            <Window />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Window' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Window')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('calStyle.animationGallery.animations.singleRollDoor.singleRollDoor')}</h2>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.singleRollDoor.ToTheLeft')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <SingleDoor animationClass='box-animation-singledoor__panel--left' />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Single door - left' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Single door - left')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.singleRollDoor.ToTheRight')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <SingleDoor animationClass='box-animation-singledoor__panel--right' />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Single door - right' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Single door - right')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.singleRollDoor.ToTheTop')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <SingleDoor animationClass='box-animation-singledoor__panel--top' />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Single door - top' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Single door - top')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>
            {t('calStyle.animationGallery.animations.singleRollDoor.ToTheBottom')}
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <SingleDoor animationClass='box-animation-singledoor__panel--bottom' />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Single door - bottom' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Single door - bottom')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('calStyle.animationGallery.animations.starship')}</h2>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col md={6}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.starship')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <Starship />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Starship' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Starship')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('calStyle.animationGallery.animations.fadeOut')}</h2>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col md={6}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.fadeOut')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <Fadeout />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Fade out' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Fade out')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{t('calStyle.animationGallery.animations.flip.flip')}</h2>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.flip.HorizontalFlip')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <Flip180h />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Flip 180 - horizontal' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Flip 180 - horizontal')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
        <Col md={3}>
          <Row style={{ marginTop: '10px' }}>{t('calStyle.animationGallery.animations.flip.VerticalFlip')}</Row>
          <Row style={{ marginTop: '10px' }}>
            <Flip180v />
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
            {boxAnimation === 'Flip 180 - vertical' ? (
              <Button className='btn btn-xs btn-success'>{t('calStyle.animationGallery.selected')}</Button>
            ) : (
              <Button className='btn btn-xs btn-info' onClick={() => handleChangeBoxAnimation('Flip 180 - vertical')}>
                {t('calStyle.animationGallery.select')}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <button title='Close' className='close_modal' onClick={closeCallback}>
        <i className='pe-7s-close' />
      </button>
    </div>
  </div>
)

Modal.propTypes = {
  children: PropTypes.element,
  customClass: PropTypes.string,
  show: PropTypes.bool,
  closeCallback: PropTypes.func
}

Modal.defaultProps = {
  children: <div>Empty Modal</div>,
  customClass: '',
  show: false,
  closeCallback: () => false
}

export default translate('translations')(Modal)
