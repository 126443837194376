import React, { Component } from 'react'
import Button from '../../components/CustomButton/CustomButton'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { firebaseDb as db, firebaseAuth, firebaseAuthClass } from '../../firebase'
import { connect } from 'react-redux'
import ModalInput from '../ModalInput/ModalInput'


class CalDelete extends Component {
  state = {
    loading: true,
    modalVisible: false,
    passwordError: "",
    formLoading: false,
  }

  componentDidMount() {
    if (this.props.activeCalendar) {
      const activeCompany = this.props.activeCompany
      const activeCalendar = this.props.activeCalendar
      const companiesData = this.props.companiesData
      if(!companiesData) {
        return;
      }
      const activeCalendarData = companiesData[activeCompany].calendars[activeCalendar];

      this.setState({
        ...this.state,
        loading: false,
        activeCompany: activeCompany,
        activeCalendar: activeCalendar,
        companiesData,
        activeCalendarData,
        disabled: activeCalendarData ? activeCalendarData.disabled || false : true
      })
    }
  }

  verifyPassword = (password) => {
    const { t } = this.props
    this.setState({ ...this.state, passwordError: "", formLoading: true})    
    const user = firebaseAuth.currentUser;
    const credential = firebaseAuthClass.EmailAuthProvider.credential(
      firebaseAuth.currentUser.email,
      password
    );
    user.reauthenticateWithCredential(credential).then((data) => {
      // User re-authenticated.
      this.disableCalendar();
    }).catch((error) => {
      // An error happened.
      this.setState({ ...this.state, formLoading: false }) 
      this.setState({ ...this.state, passwordError: t('calHeader.deleteCalendar.wrongPasswordText') })  
      this.props.notificationOpen && this.props.notificationOpen('tr', 'pe-7s-close-circle', 'error', t('calHeader.deleteCalendar.wrongPasswordText'))   
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeCalendar !== nextProps.activeCalendar || this.props.companiesData !== nextProps.companiesData) {
      const activeCompany = nextProps.activeCompany
      const activeCalendar = nextProps.activeCalendar
      const companiesData = nextProps.companiesData

      if(!companiesData) {
        return;
      }
      const activeCalendarData = companiesData[activeCompany].calendars[activeCalendar]

      this.setState({
        ...this.state,
        loading: false,
        activeCompany: activeCompany,
        activeCalendar: activeCalendar,
        companiesData: companiesData,
        activeCalendarData: activeCalendarData,
        disabled: activeCalendarData ? activeCalendarData.disabled || false : true
      })
    }
  }

  disableCalendar() {
    const disabled = !this.state.disabled;
    db.doc(
      '/companies/' +
      this.state.activeCompany +
      '/calendars/' +
      this.state.activeCalendar
    ).update( this.state.disabled ? {
      disabled: false
    } : {
      disabled: true,
      lastDisabledDate: new Date()
    }).then(() => {
      const companiesData = this.state.companiesData
      if (disabled) {
        this.props.changeActiveCalendar(null)
        companiesData[this.state.activeCompany].disabledCalendars = {
          ...companiesData[this.state.activeCompany].disabledCalendars,
          [this.state.activeCalendar]: {
            ...companiesData[this.state.activeCompany].calendars[this.state.activeCalendar],
            disabled: true
          }
        }
        delete companiesData[this.state.activeCompany].calendars[this.state.activeCalendar]
        
      } else {
        companiesData[this.state.activeCompany].calendars = {
          ...companiesData[this.state.activeCompany].calendars,
          [this.state.activeCalendar]: {
            ...companiesData[this.state.activeCompany].disabledCalendars[this.state.activeCalendar],
            disabled: false
          }
        }
        delete companiesData[this.state.activeCompany].disabledCalendars[this.state.activeCalendar] 
      }
      this.props.changeCompaniesData(companiesData)
      this.setState({ ...this.state, disabled, modalVisible: false, formLoading: false, companiesData })
      if(disabled === true) {
        this.props.history.replace('/dashboard')
      } else {
        window.onbeforeunload = null
        window.location.reload()
      }
      this.props.notificationOpen && this.props.notificationOpen('tr',  'pe-7s-check', 'success', `Calendar ${disabled ? "deleted" : "restored"} successfully`) 

    })
  }

  render() {
     const { t } = this.props

    return (
      <>
      {typeof this.state.disabled === "boolean" && <Button
        fill
        bsStyle={this.state.disabled ? 'primary' : 'danger'}
        onClick={() => this.setState({ ...this.state, modalVisible: true })}
        title={`${this.state.disabled ? t('calHeader.deleteCalendar.restoreButton') : t('calHeader.deleteCalendar.deleteButton')}`}
      >
        {this.state.disabled ? t('calHeader.deleteCalendar.restoreButton') : t('calHeader.deleteCalendar.deleteButton')}
      </Button>}
      <ModalInput
        visible={this.state.modalVisible}
        text={this.state.disabled ? t('calHeader.deleteCalendar.restorePasswordText') : t('calHeader.deleteCalendar.deletePasswordText')}
        defaultValue={""}
        onOk={this.verifyPassword}
        onCancel={() => this.setState({ ...this.state, modalVisible: false })}
        type='password'
        error={this.state.passwordError}
        disabledOk={true}
        confirmLoading={this.state.formLoading}
      />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeActiveCalendar: activeCalendar => dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: activeCalendar }),
    changeCompaniesData: companiesData =>
      dispatch({ type: 'CHANGE_COMPANIES_DATA', value: companiesData })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate('translations')(CalDelete)))
