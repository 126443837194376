import React from 'react'
import TextAreaAutosize from 'react-textarea-autosize'
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'

import DynamicOptionList from './dynamic-option-list'
import { get } from './stores/requests'
import ID from './UUID'
import ReactQuill from 'react-quill'
import Delta from "quill-delta";
import { connect } from 'react-redux'
import FileSelector from '../../views/FileStorage/Parts/FileSelector'

const toolbar = {
  options: ['inline', 'list', 'textAlign', 'fontSize', 'link', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    options: ['bold', 'italic', 'underline', 'superscript', 'subscript']
  }
}

class FormElementsEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      element: this.props.element,
      data: this.props.data,
      dirty: false
    }
    this.handleChangeQuill = this.handleChangeQuill.bind(this)
  }

  handleChangeQuill(content) {
    const this_element = this.state.element
    this_element['content'] = content

    this.setState({
      element: this_element,
      dirty: true
    })
  }

  handlePasteQuill(e) {
    const pastedText = e.clipboardData.getData('Text')
    if (!(e.target instanceof HTMLInputElement)) {
      e.preventDefault()
      setTimeout(() => {
        const editor = this.quillRef.getEditor()
        const selection = editor.getSelection()
        editor.updateContents(new Delta()
          .retain(selection.index)
          .insert(pastedText)
        )
      }, 100)
    }
  }

  toggleRequired() {
    // const this_element = this.state.element;
  }

  editElementProp(elemProperty, targProperty, e) {
    // elemProperty could be content or label
    // targProperty could be value or checked
    const this_element = this.state.element
    this_element[elemProperty] = e.target[targProperty]
    this.setState(
      {
        element: this_element,
        dirty: true
      },
      () => {
        if (targProperty === 'checked') {
          this.updateElement()
        }
      }
    )
  }

  onEditorStateChange(index, property, editorContent) {
    // const html = draftToHtml(convertToRaw(editorContent.getCurrentContent())).replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');
    const html = draftToHtml(convertToRaw(editorContent.getCurrentContent()))
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '')
      .replace(/(?:\r\n|\r|\n)/g, ' ')
    const this_element = this.state.element
    this_element[property] = html

    this.setState({
      element: this_element,
      dirty: true
    })
  }

  updateElement() {
    const this_element = this.state.element
    // to prevent ajax calls with no change
    if (this.state.dirty) {
      this.props.updateElement.call(this.props.preview, this_element)
      this.setState({ dirty: false })
    }
  }

  convertFromHTML(content) {
    const newContent = convertFromHTML(content)
    if (!newContent.contentBlocks || !newContent.contentBlocks.length) {
      // to prevent crash when no contents in editor
      return EditorState.createEmpty()
    }
    const contentState = ContentState.createFromBlockArray(newContent)
    return EditorState.createWithContent(contentState)
  }

  addOptions() {
    const optionsApiUrl = document.getElementById('optionsApiUrl').value
    if (optionsApiUrl) {
      get(optionsApiUrl).then(data => {
        this.props.element.options = []
        const { options } = this.props.element
        data.forEach(x => {
          // eslint-disable-next-line no-param-reassign
          x.key = ID.uuid()
          options.push(x)
        })
        const this_element = this.state.element
        this.setState({
          element: this_element,
          dirty: true
        })
      })
    }
  }

  handleStorageUrl(url) {
    if (typeof window !== 'undefined') {
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
      nativeInputValueSetter.call(this.srcInputRef, url);
      const event = new Event('input', { bubbles: true });
      this.srcInputRef.dispatchEvent(event);
    }
  }
  render() {
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        [
          {
            color: Object.values(this.props.preview.props.parent.props.colors)
          },
          { color: [] }
        ],
        ['clean']
      ]
    }

    if (this.state.dirty) {
      this.props.element.dirty = true
    }

    const this_checked = this.props.element.hasOwnProperty('required')
      ? this.props.element.required
      : false
    const this_read_only = this.props.element.hasOwnProperty('readOnly')
      ? this.props.element.readOnly
      : false
    const this_default_today = this.props.element.hasOwnProperty('defaultToday')
      ? this.props.element.defaultToday
      : false
    const this_show_time_select = this.props.element.hasOwnProperty(
      'showTimeSelect'
    )
      ? this.props.element.showTimeSelect
      : false
    const this_show_time_select_only = this.props.element.hasOwnProperty(
      'showTimeSelectOnly'
    )
      ? this.props.element.showTimeSelectOnly
      : false
    const this_checked_inline = this.props.element.hasOwnProperty('inline')
      ? this.props.element.inline
      : false
    const this_checked_bold = this.props.element.hasOwnProperty('bold')
      ? this.props.element.bold
      : false
    const this_checked_italic = this.props.element.hasOwnProperty('italic')
      ? this.props.element.italic
      : false
    const this_checked_center = this.props.element.hasOwnProperty('center')
      ? this.props.element.center
      : false
    const this_checked_newTab = this.props.element.hasOwnProperty('newTab')
      ? this.props.element.newTab
      : false
    const this_checked_page_break = this.props.element.hasOwnProperty(
      'pageBreakBefore'
    )
      ? this.props.element.pageBreakBefore
      : false
    const this_checked_alternate_form = this.props.element.hasOwnProperty(
      'alternateForm'
    )
      ? this.props.element.alternateForm
      : false
    const this_checked_penalty = this.props.element.hasOwnProperty(
      'penalty'
    )
      ? this.props.element.penalty
      : false
    const this_checked_disable_penalty_result = this.props.element.hasOwnProperty(
      'disablePenaltyResult'
    )
      ? this.props.element.disablePenaltyResult
      : false
    const this_checked_apply_on_submit = this.props.element.hasOwnProperty(
      'applyOnSubmit'
    )
      ? this.props.element.applyOnSubmit
      : false

    const {
      canHavePageBreakBefore,
      canHaveAlternateForm,
      canHaveDisplayHorizontal,
      canHaveOptionCorrect,
      canHaveOptionValue,
      canHavePenalty
    } = this.props.element

    const this_files = this.props.files.length ? this.props.files : []
    if (
      this_files.length < 1 ||
      (this_files.length > 0 && this_files[0].id !== '')
    ) {
      this_files.unshift({ id: '', file_name: '' })
    }

    let editorState
    if (this.props.element.hasOwnProperty('content')) {
      editorState = this.props.element.content
    }
    if (this.props.element.hasOwnProperty('label')) {
      editorState = this.convertFromHTML(this.props.element.label)
    }
    return (
      <div>
        <div className='clearfix'>
          <h4 className='float-left'>{this.props.element.text}</h4>
          <i
            className='float-right fas fa-times dismiss-edit'
            onClick={this.props.manualEditModeOff}
          ></i>
        </div>
        <div className='form-group'>
          <label className='control-label' htmlFor='name'>
            Name:
          </label>
          <input
            id='name'
            type='text'
            className='form-control'
            defaultValue={this.props.element.element === 'EmailInput' ? 'email' : this.props.element.name}
            onBlur={this.updateElement.bind(this)}
            disabled={this.props.element.element === 'EmailInput'}
            onChange={this.editElementProp.bind(this, 'name', 'value')}
          />
        </div>
        <div className='form-group'>
          <label className='control-label' htmlFor='class'>
            Class:
          </label>
          <input
            id='class'
            type='text'
            className='form-control'
            placeholder=''
            defaultValue={this.props.element.class}
            onBlur={this.updateElement.bind(this)}
            onChange={this.editElementProp.bind(this, 'class', 'value')}
          />
        </div>
        <div className='form-group'>
          <label className='control-label' htmlFor='placeholder'>
            Placeholder:
          </label>
          <input
            id='placeholder'
            type='text'
            className='form-control'
            placeholder=''
            defaultValue={this.props.element.placeholder}
            onBlur={this.updateElement.bind(this)}
            onChange={this.editElementProp.bind(this, 'placeholder', 'value')}
          />
        </div>
        {this.props.element.element === 'EmailInput' &&
          <div className='form-group'>
            <label className='control-label' htmlFor='emailDomains'>
              Email Domains(Comma seperated list of allowed email domains):
            </label>
            <input
              id='emailDomains'
              type='text'
              className='form-control'
              placeholder=''
              defaultValue={this.props.element.emailDomains}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'emailDomains', 'value')}
            />
            <label className='control-label' htmlFor='emailDomainsError'>
              Email Domains error message:
            </label>
            <input
              id='emailDomainsError'
              type='text'
              className='form-control'
              placeholder='Please use your company email address'
              defaultValue={this.props.element.emailDomainsError}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'emailDomainsError', 'value')}
            />
          </div>
        }
        {this.props.element.hasOwnProperty('content')
          && this.props.element.element !== 'HtmlCode'
          && this.props.element.element !== 'TypeForm'
          && this.props.element.element !== 'Button'
          && this.props.element.element !== 'ButtonLink' && (
            <div className='form-group' onPaste={(e) => this.handlePasteQuill(e)}>
              <label className='control-label'>Text to display:</label>
              <ReactQuill
                ref={(ref) => this.quillRef = ref}
                onChange={this.handleChangeQuill.bind('content')}
                defaultValue={editorState}
                // onChange={this.onEditorStateChange.bind(this, 0, 'content')}
                onBlur={this.updateElement.bind(this)}
                modules={modules}

              />

              {/* <Editor
              toolbar={toolbar}
              defaultEditorState={editorState}
              onBlur={this.updateElement.bind(this)}
              onEditorStateChange={this.onEditorStateChange.bind(
                this,
                0,
                'content'
              )}
              stripPastedStyles={true}
            /> */}
            </div>
          )}
        {this.props.element.hasOwnProperty('content') &&
          (this.props.element.element === 'HtmlCode' || this.props.element.element === 'Button' || this.props.element.element === 'ButtonLink') && (
            <div className='form-group'>
              <label className='control-label'>Content:</label>
              <TextAreaAutosize
                type='text'
                className='form-control'
                id='htmlCode'
                defaultValue={this.props.element.content}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'content', 'value')}
              />
            </div>
          )}
        {this.props.element.hasOwnProperty('content') &&
          this.props.element.element === 'TypeForm' && (
            <div className='form-group'>
              <label className='control-label'>Type Form URL:</label>
              <TextAreaAutosize
                type='text'
                className='form-control'
                id='typeFormURL'
                defaultValue={this.props.element.content}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'content', 'value')}
              />
              {/* <label className='control-label'>Width</label>
            <input
              id='width'
              type='text'
              className='form-control'
              placeholder=''
              defaultValue={this.props.element.width || '100%'}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'width', 'value')}
            />
            <label className='control-label'>Height</label>
            <input
              id='height'
              type='text'
              className='form-control'
              placeholder=''
              defaultValue={this.props.element.height || '500px'}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'height', 'value')}
            /> */}
            </div>
          )}

        {this.props.element.hasOwnProperty('file_path') && (
          <div className='form-group'>
            <label className='control-label' htmlFor='fileSelect'>
              Choose file:
            </label>
            <select
              id='fileSelect'
              className='form-control'
              defaultValue={this.props.element.file_path}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'file_path', 'value')}
            >
              {this_files.map(file => {
                const this_key = `file_${file.id}`
                return (
                  <option value={file.id} key={this_key}>
                    {file.file_name}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        {this.props.element.hasOwnProperty('href') && (
          <div className='form-group'>
            <TextAreaAutosize
              type='text'
              className='form-control'
              defaultValue={this.props.element.href}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'href', 'value')}
            />
          </div>
        )}
        {this.props.element.hasOwnProperty('src') && (
          <div>
            <div className='form-group'>
              <label className='control-label' htmlFor='imagePicker'>
                Select from storage
              </label>
              <FileSelector
                id='imagePicker'
                type='image'
                url={this.props.element.src}
                disableDragAndDropProvider={true}
                activeCompany={this.props.activeCompany}
                onSelect={(file) => { this.handleStorageUrl(file.url) }}
                onModalChange={(visible) =>
                  this.props.onClosingBlocked && this.props.onClosingBlocked(visible)}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='srcInput'>
                Image src:
              </label>
              <input
                id='srcInput'
                type='text'
                className='form-control'
                ref={elem => this.srcInputRef = elem}
                defaultValue={this.props.element.src}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'src', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='srcInput'>
                {"Link to: (starting with 'https://', e.g. 'https://google.com/')"}
              </label>
              <input
                id='imageLink'
                type='text'
                className='form-control'
                defaultValue={this.props.element.link}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'link', 'value')}
              />
            </div>
            <div className='form-group'>
              <div className='custom-control custom-checkbox'>
                <input
                  id='do-center'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_checked_center}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'center',
                    'checked'
                  )}
                />
                <label className='custom-control-label' htmlFor='do-center'>
                  Center?
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-3'>
                <label className='control-label' htmlFor='elementWidth'>
                  Width:
                </label>
                <input
                  id='elementWidth'
                  type='text'
                  className='form-control'
                  defaultValue={this.props.element.width}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, 'width', 'value')}
                />
              </div>
            </div>
          </div>
        )}
        {this.props.element.hasOwnProperty('label') && (
          <div className='form-group'>
            <label>Display Label</label>
            <Editor
              toolbar={toolbar}
              defaultEditorState={editorState}
              onBlur={this.updateElement.bind(this)}
              onEditorStateChange={this.onEditorStateChange.bind(
                this,
                0,
                'label'
              )}
              stripPastedStyles={true}
            />
            <br />
            <div className='custom-control custom-checkbox'>
              <input
                id='is-required'
                className='custom-control-input'
                type='checkbox'
                checked={this_checked}
                value={true}
                onChange={this.editElementProp.bind(
                  this,
                  'required',
                  'checked'
                )}
              />
              <label className='custom-control-label' htmlFor='is-required'>
                Required
              </label>
            </div>
            {this.props.element.hasOwnProperty('readOnly') && (
              <div className='custom-control custom-checkbox'>
                <input
                  id='is-read-only'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_read_only}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'readOnly',
                    'checked'
                  )}
                />
                <label className='custom-control-label' htmlFor='is-read-only'>
                  Read only
                </label>
              </div>
            )}
            {this.props.element.hasOwnProperty('defaultToday') && (
              <div className='custom-control custom-checkbox'>
                <input
                  id='is-default-to-today'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_default_today}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'defaultToday',
                    'checked'
                  )}
                />
                <label
                  className='custom-control-label'
                  htmlFor='is-default-to-today'
                >
                  Default to Today?
                </label>
              </div>
            )}
            {this.props.element.hasOwnProperty('showTimeSelect') && (
              <div className='custom-control custom-checkbox'>
                <input
                  id='show-time-select'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_show_time_select}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'showTimeSelect',
                    'checked'
                  )}
                />
                <label
                  className='custom-control-label'
                  htmlFor='show-time-select'
                >
                  Show Time Select?
                </label>
              </div>
            )}
            {this_show_time_select &&
              this.props.element.hasOwnProperty('showTimeSelectOnly') && (
                <div className='custom-control custom-checkbox'>
                  <input
                    id='show-time-select-only'
                    className='custom-control-input'
                    type='checkbox'
                    checked={this_show_time_select_only}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      'showTimeSelectOnly',
                      'checked'
                    )}
                  />
                  <label
                    className='custom-control-label'
                    htmlFor='show-time-select-only'
                  >
                    Show Time Select Only?
                  </label>
                </div>
              )}
            {(this.state.element.element === 'RadioButtons' ||
              this.state.element.element === 'Checkboxes') &&
              canHaveDisplayHorizontal && (
                <div className='custom-control custom-checkbox'>
                  <input
                    id='display-horizontal'
                    className='custom-control-input'
                    type='checkbox'
                    checked={this_checked_inline}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      'inline',
                      'checked'
                    )}
                  />
                  <label
                    className='custom-control-label'
                    htmlFor='display-horizontal'
                  >
                    Display horizonal
                  </label>
                </div>
              )}
          </div>
        )}

        {this.state.element.element === 'Signature' &&
          this.props.element.readOnly ? (
          <div className='form-group'>
            <label className='control-label' htmlFor='variableKey'>
              Variable Key:
            </label>
            <input
              id='variableKey'
              type='text'
              className='form-control'
              defaultValue={this.props.element.variableKey}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'variableKey', 'value')}
            />
            <p className='help-block'>
              This will give the element a key that can be used to replace the
              content with a runtime value.
            </p>
          </div>
        ) : (
          <div />
        )}

        {canHavePageBreakBefore && (
          <div className='form-group'>
            <label className='control-label'>Print Options</label>
            <div className='custom-control custom-checkbox'>
              <input
                id='page-break-before-element'
                className='custom-control-input'
                type='checkbox'
                checked={this_checked_page_break}
                value={true}
                onChange={this.editElementProp.bind(
                  this,
                  'pageBreakBefore',
                  'checked'
                )}
              />
              <label
                className='custom-control-label'
                htmlFor='page-break-before-element'
              >
                Page Break Before Element?
              </label>
            </div>
          </div>
        )}

        {canHaveAlternateForm &&
          this.props.element.element !== 'Header' &&
          this.props.element.element !== 'Paragraph' &&
          (
            <div className='form-group'>
              <label className='control-label'>Alternate/Signature Page</label>
              <div className='custom-control custom-checkbox'>
                <input
                  id='display-on-alternate'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_checked_alternate_form}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'alternateForm',
                    'checked'
                  )}
                />
                <label
                  className='custom-control-label'
                  htmlFor='display-on-alternate'
                >
                  Display on alternate/signature Page?
                </label>
              </div>
            </div>
          )}

        {canHavePenalty && (
          <div className='form-group'>
            <label className='control-label'>Penalty</label>
            <div className='custom-control custom-checkbox'>
              <input
                id='penalty-on-fault'
                className='custom-control-input'
                type='checkbox'
                checked={this_checked_penalty}
                value={true}
                onChange={this.editElementProp.bind(
                  this,
                  'penalty',
                  'checked'
                )}
              />
              <label
                className='custom-control-label'
                htmlFor='penalty-on-fault'
              >
                If the player answers this incorrectly will have additional penalty clicks added to the counter.
              </label>
            </div>
            <div className='custom-control custom-checkbox'>
              <div className='row'>
                <div className='col-sm-4' style={{ alignItems: 'center', display: 'flex', height: 40 }}>
                  <label
                    className='custom-control-label'
                    htmlFor='penalty-click-amount'
                    style={{ opacity: !this_checked_penalty && 0.5 }}
                  >
                    Amount of the penalty clicks
                  </label>
                </div>
                <div className='col-sm-2'>
                  <input
                    id='penalty-click-amount'
                    className='form-control'
                    disabled={!this_checked_penalty}
                    type='number'
                    min={1}
                    defaultValue={this.props.element.penaltyClickAmount || 1}
                    max={10}
                    checked={this_checked_disable_penalty_result}
                    onChange={this.editElementProp.bind(this, 'penaltyClickAmount', 'value')}
                  />
                </div>
              </div>
            </div>
            <div className='custom-control custom-checkbox'>
              <input
                id='disable-penalty-result'
                className='custom-control-input'
                disabled={!this_checked_penalty}
                type='checkbox'
                checked={this_checked_disable_penalty_result}
                value={true}
                onChange={this.editElementProp.bind(
                  this,
                  'disablePenaltyResult',
                  'checked'
                )}
              />
              <label
                className='custom-control-label'
                htmlFor='disable-penalty-result'
                style={{ opacity: !this_checked_penalty && 0.5 }}
              >
                Disable displaying penalty result
              </label>
            </div>
            <div className='custom-control custom-checkbox'>
              <input
                id='apply-on-sumbit'
                className='custom-control-input'
                disabled={!this_checked_penalty}
                type='checkbox'
                checked={this_checked_apply_on_submit}
                value={true}
                onChange={this.editElementProp.bind(
                  this,
                  'applyOnSubmit',
                  'checked'
                )}
              />
              <label
                className='custom-control-label'
                htmlFor='apply-on-sumbit'
                style={{ opacity: !this_checked_penalty && 0.5 }}
              >
                {"Apply on submit(If not checked, penalty will be applied right after clicking an option)"}
              </label>
            </div>
          </div>
        )}

        {this.props.element.hasOwnProperty('step') && (
          <div className='form-group'>
            <div className='form-group-range'>
              <label className='control-label' htmlFor='rangeStep'>
                Step
              </label>
              <input
                id='rangeStep'
                type='number'
                className='form-control'
                defaultValue={this.props.element.step}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'step', 'value')}
              />
            </div>
          </div>
        )}
        {this.props.element.hasOwnProperty('min_value') && (
          <div className='form-group'>
            <div className='form-group-range'>
              <label className='control-label' htmlFor='rangeMin'>
                Min
              </label>
              <input
                id='rangeMin'
                type='number'
                className='form-control'
                defaultValue={this.props.element.min_value}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'min_value', 'value')}
              />
              <input
                type='text'
                className='form-control'
                defaultValue={this.props.element.min_label}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'min_label', 'value')}
              />
            </div>
          </div>
        )}
        {this.props.element.hasOwnProperty('max_value') && (
          <div className='form-group'>
            <div className='form-group-range'>
              <label className='control-label' htmlFor='rangeMax'>
                Max
              </label>
              <input
                id='rangeMax'
                type='number'
                className='form-control'
                defaultValue={this.props.element.max_value}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'max_value', 'value')}
              />
              <input
                type='text'
                className='form-control'
                defaultValue={this.props.element.max_label}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'max_label', 'value')}
              />
            </div>
          </div>
        )}
        {this.props.element.hasOwnProperty('default_value') && (
          <div className='form-group'>
            <div className='form-group-range'>
              <label className='control-label' htmlFor='defaultSelected'>
                Default Selected
              </label>
              <input
                id='defaultSelected'
                type='number'
                className='form-control'
                defaultValue={this.props.element.default_value}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  'default_value',
                  'value'
                )}
              />
            </div>
          </div>
        )}
        {this.props.element.hasOwnProperty('static') &&
          this.props.element.static && this.props.element.element !== 'ButtonLink' &&
          this.props.element.element !== 'Button' &&
          this.props.element.element !== 'Header' &&
          this.props.element.element !== 'Paragraph' &&
          (
            <div className='form-group'>
              <label className='control-label'>Text Style</label>
              <div className='custom-control custom-checkbox'>
                <input
                  id='do-bold'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_checked_bold}
                  value={true}
                  onChange={this.editElementProp.bind(this, 'bold', 'checked')}
                />
                <label className='custom-control-label' htmlFor='do-bold'>
                  Bold
                </label>
              </div>
              <div className='custom-control custom-checkbox'>
                <input
                  id='do-italic'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_checked_italic}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'italic',
                    'checked'
                  )}
                />
                <label className='custom-control-label' htmlFor='do-italic'>
                  Italic
                </label>
              </div>
            </div>
          )}
        {(this.props.element.element === 'ButtonLink' || this.props.element.element === 'Button') && (
          <div className='form-group'>
            {
              this.props.element.element === 'ButtonLink' &&
              <div className='form-group'>
                <label className='control-label' htmlFor='font'>
                  {"Link URL: (e.g. https://google.com)"}:
                </label>
                <input
                  id='linkUrl'
                  type='text'
                  className='form-control'
                  placeholder=''
                  defaultValue={this.props.element.linkUrl}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, 'linkUrl', 'value')}
                />
              </div>
            }
            <div className='form-group'>
              <div className='custom-control custom-checkbox'>
                <input
                  id='newTab'
                  className='custom-control-input'
                  type='checkbox'
                  checked={this_checked_newTab}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    'newTab',
                    'checked'
                  )}
                />
                <label className='custom-control-label' htmlFor='newTab'>
                  Open in new tab?
                </label>
              </div>
            </div>
            <b className='control-label'>
              {'Style Settings (Overrides Global Button Styles)'}
            </b>
            <div className='form-group'>
              <label className='control-label' htmlFor='font'>
                Font Family:
              </label>
              <input
                id='fontFamily'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.fontFamily}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'fontFamily', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='font'>
                Font Size:
              </label>
              <input
                id='fontSize'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.fontSize}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'fontSize', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='font'>
                Color:
              </label>
              <input
                id='color'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.color}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'color', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='placeholder'>
                Background:
              </label>
              <input
                id='background'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.background}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'background', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='font'>
                Hover Color:
              </label>
              <input
                id='hoverColor'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.hoverColor}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'hoverColor', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='placeholder'>
                Hover Background:
              </label>
              <input
                id='hoverBackground'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.hoverBackground}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'hoverBackground', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='placeholder'>
                Border Type:
              </label>
              <input
                id='borderType'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.borderType}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'borderType', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='placeholder'>
                Border Width:
              </label>
              <input
                id='borderWidth'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.borderWidth}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'borderWidth', 'value')}
              />
            </div>
            <div className='form-group'>
              <label className='control-label' htmlFor='placeholder'>
                Border Radius:
              </label>
              <input
                id='borderRadius'
                type='text'
                className='form-control'
                placeholder=''
                defaultValue={this.props.element.borderRadius}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'borderRadius', 'value')}
              />
            </div>
          </div>
        )}
        {this.props.element.showDescription && (
          <div className='form-group'>
            <label className='control-label' htmlFor='questionDescription'>
              Description
            </label>
            <TextAreaAutosize
              type='text'
              className='form-control'
              id='questionDescription'
              defaultValue={this.props.element.description}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, 'description', 'value')}
            />
          </div>
        )}
        {this.props.showCorrectColumn &&
          this.props.element.canHaveAnswer &&
          !this.props.element.hasOwnProperty('options') && (
            <div className='form-group'>
              <label className='control-label' htmlFor='correctAnswer'>
                Correct Answer
              </label>
              <input
                id='correctAnswer'
                type='text'
                className='form-control'
                defaultValue={this.props.element.correct}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, 'correct', 'value')}
              />
            </div>
          )}
        {this.props.element.canPopulateFromApi &&
          this.props.element.hasOwnProperty('options') && (
            <div className='form-group'>
              <label className='control-label' htmlFor='optionsApiUrl'>
                Populate Options from API
              </label>
              <div className='row'>
                <div className='col-sm-6'>
                  <input
                    className='form-control'
                    style={{ width: '100%' }}
                    type='text'
                    id='optionsApiUrl'
                    placeholder='http://localhost:8080/api/optionsdata'
                  />
                </div>
                <div className='col-sm-6'>
                  <button
                    onClick={this.addOptions.bind(this)}
                    className='btn btn-success'
                  >
                    Populate
                  </button>
                </div>
              </div>
            </div>
          )}
        {this.props.element.hasOwnProperty('options') && (
          <DynamicOptionList
            showCorrectColumn={this.props.showCorrectColumn}
            canHaveOptionCorrect={canHaveOptionCorrect}
            canHaveOptionValue={canHaveOptionValue}
            data={this.props.preview.state.data}
            updateElement={this.props.updateElement}
            preview={this.props.preview}
            element={this.props.element}
            key={this.props.element.options.length}
          />
        )}
      </div>
    )
  }
}
FormElementsEdit.defaultProps = { className: 'edit-element-fields' }
const mapStateToProps = state => {
  return {
    activeCompany: state.activeCompany,
  }
}

export default connect(
  mapStateToProps,
)(FormElementsEdit)
