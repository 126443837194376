import React, { useState } from 'react'
import Button from '../../components/CustomButton/CustomButton'
import {
  Grid,
  Row,
  Col,
  HelpBlock,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import { FormInputs } from '../../components/FormInputs/FormInputs'
import { translate } from 'react-i18next'
import { firebaseDb as db } from '../../firebase'
import Loading from '../../components/Loading/Loading'
import DataFromDb from '../../helpers/DataFromDb'
import Templates from './Templates'
import { map } from 'lodash'

// Data
import languagesData from '../../data/languages.json'

// Redux
import { useSelector, useDispatch } from 'react-redux'

const CreateNewCalendar = props => {
  const calendarTypes = [
    {
      name: 'Advent Calendar',
      code: 'advent'
    },
    {
      name: 'Hide And Seek Game',
      code: 'hide-and-seek'
    },
    {
      name: 'Memory Game',
      code: 'memory-game'
    }
  ]

  const { withoutbutton, history } = props

  const dispatch = useDispatch()
  const { activeCompany, companiesData } = useSelector(state => state)
  const [errorName, setErrorName] = useState(null)
  const [errorCalendarType, setErrorCalendarType] = useState(null)
  const [isCreated, setIsCreated] = useState(false)
  const [newCalendarId, setNewCalendarId] = useState(null)
  const [addingCalendar, setAddingCalendar] = useState(false)
  const [copyMessage, setCopyMessage] = useState('data')
  const [calendarType, setCalendarType] = useState(null)

  const handleSubmit = event => {
    console.log('calendarType', calendarType)
    setErrorName(null)
    setErrorCalendarType(null)
    event.preventDefault()
    const { t } = props
    const data = new FormData(event.target)
    const name = data.get('name')
    const template = data.get('template')
    let type = data.get('type') || 'advent'

    const primaryLanguage = data.get('primaryLanguage')

    const days = {}
    const gridDays = {}

    if (type === 'hide-and-seek' || type === 'memory-game') {
      const boxCount = 12 // Can be adjustable
      for (let index = 0; index < boxCount; index++) {
        days[index + 1] = {
          bgPos: '',
          color: '',
          coords: {
            0: index % 4,
            1: Math.floor(index / 4),
            2: 1,
            3: 1
          }
        }
        gridDays[index + 1] = {
          bgPos: '',
          color: '',
          coords: {
            0: index % 4,
            1: Math.floor(index / 4),
            2: 1,
            3: 1
          }
        }
        if(type === 'memory-game') {
          gridDays[`pair_${index + 1}`] = {
            bgPos: '',
            color: '',
            coords: {
              0: (boxCount + index + 1) % 4,
              1: Math.floor((boxCount + index + 1) / 4),
              2: 1,
              3: 1
            }
          }
        }
      }
      gridDays.winning = {
        bgPos: '',
        color: '',
        coords: {
          0: 0,
          1: 0,
          2: 1,
          3: 0
        }
      }
      days.winning = {
        bgPos: '',
        color: '',
        coords: {
          0: 0,
          1: 0,
          2: 1,
          3: 0
        }
      }
    }
    // name validation
    if (!name) {
      setErrorName(t('createNewCalendar.calendarNameIsRequired'))
    }
    if (!calendarType) {
      setErrorCalendarType(t('createNewCalendar.calendarTypeIsRequired'))
    }
    if (name && calendarType !== null) {
      setAddingCalendar(true)
      // CREATE
      let calendarId
      // copy another calendar
      const templateId = template !== 'empty' ? template : null

      db.collection('companies/' + activeCompany + '/calendars')
        .add({
          name: name,
          type: type,
          templateId,
          changeToPublish: false,
          generated: new Date()
        })
        .then(ref => {
          calendarId = ref.id
          return calendarId
        })
        .then(calendarId => {
          // if it's brand new calendar
          if (templateId === null) {
            db.collection(
              'companies/' +
              activeCompany +
              '/calendars/' +
              calendarId +
              '/settings'
            )
              .doc('grid')
              .set({
                type: type,
                calendarName: 'New calendar',
                languagePrimary: primaryLanguage,
                languages: {
                  [primaryLanguage]: languagesData[primaryLanguage].name
                },

                calendarUrl: '',
                numGridCols: (type !== 'memory-game') ? 6 : 4,
                afterFormMessage: '',
                allowModalsAfterShutdown: '',
                beforeLaunchModal: '',
                boxAnimation: 'Window',
                affterLaunchModal: '',
                startDate: '',
                backgroundImage: '',
                colors: {
                  1: '#ff7e79',
                  2: '#ff9300',
                  3: '#8f7800'
                },
                days: gridDays,
                customCss: '',
                header: {
                  display: true,
                  header: 'New Calendar Header',
                  linkLabel: 'Link new Calendar',
                  linkUrl: 'https://...',
                  test: 'new calendar text'
                },
                displayDayNumber: type !== 'memory-game',
                losingModal: {
                  display: true,
                  text: '',
                  behavior: 'random'
                },
                newsletter: {
                  text: 'Newsletter content of the new Calendar',
                  display: true,
                  linkLabel: '',
                  linkUrl: ''
                },
                reminder: {
                  text: 'Reminder content of the new Calendar',
                  display: true,
                  linkLabelReminder: '',
                  linkUrlReminder: ''
                },
                counterText: 'Counter content of the new Calendar',
                social: {
                  headline: '',
                  facebook: '',
                  instagram: '',
                  linkedin: '',
                  twitter: '',
                  youtube: '',
                  xing: ''
                },
                hubspotPortalId: '',
                gaTrackingId: '',
                gaTagManager: '',
                modalBeforeDesign: 'default',
                ogTitle: '',
                ogDescription: '',
                ogType: 'website',
                ogUrl: '',
                shareButton: 'yes',
                errorField: 'This field is required'
              })
            if (type === 'hide-and-seek' || type === 'memory-game') {
              Object.keys(days).map(dayNumber => {
                db.doc(
                  'companies/' +
                  activeCompany +
                  '/calendars/' +
                  calendarId +
                  '/days/' + dayNumber
                )
                  .set({
                    data: '',
                    formSubmitClicks: 'false'
                  })
                  .then(ref => { })
              })
            }
            db.doc(
              'companies/' +
              activeCompany +
              '/calendars/' +
              calendarId +
              '/clicks/total'
            )
              .set({
                clicks: '0'
              })
              .then(ref => { })
          }

          return calendarId
        })
        .then(calendarId => {
          db.collection(
            'companies/' +
            activeCompany +
            '/calendars/' +
            calendarId +
            '/generation'
          )
            .doc('control')
            .set({
              action: 'generate',
              dateAction: new Date()
            })
            .then(res => {
              setErrorName(null)
              // setIsCreated(true)
              // setNewCalendarId(calendarId)
              // Listen for changing in firebase cloud functions
              db.doc(
                'companies/' + activeCompany + '/calendars/' + calendarId
              ).onSnapshot(doc => {
                const data = doc.data()
                if (data.copyMessage === 'done') {
                  setIsCreated(true)
                  setNewCalendarId(calendarId)
                } else {
                  setCopyMessage(data.copyMessage)
                }
              })
            })
        })
    }
  }
  const redirectToCalendar = (companyId, calendarId, page = 'dashboard') => {
    dispatch({ type: 'CHANGE_ACTIVE_CALENDAR', value: calendarId })
    history.push(
      '/company/' + companyId + '/calendar/' + calendarId + '/' + page
    )
  }

  const changeCalendarType = event => {
    event.target.value === 'empty'
      ? setCalendarType(null)
      : setCalendarType(event.target.value)
  }

  const { t } = props

  return (
    <div className='content'>
      {isCreated ? (
        <div style={{ textAlign: 'center' }}>
          <DataFromDb />
          <i
            className='pe-7s-check'
            style={{ fontSize: '150px', marginBottom: '20px', color: 'green' }}
          />
          <h3>{t('createNewCalendar.newCalendarCreated')}</h3>
          <p>{t('createNewCalendar.letsDoSomethingExtra')}</p>
          {!withoutbutton && (
            <Button
              fill
              bsStyle='success'
              onClick={() => redirectToCalendar(activeCompany, newCalendarId)}
            >
              {t('createNewCalendar.editNewCalendar')}
            </Button>
          )}
        </div>
      ) : companiesData &&
        companiesData[activeCompany].calendarsLimit <=
        companiesData[activeCompany].numCalendars ? (
        <div style={{ textAlign: 'center' }}>
          <i
            className='pe-7s-close'
            style={{ fontSize: '150px', marginBottom: '20px', color: 'red' }}
          />
          <h3>
            {t('createNewCalendar.limitReached')}{' '}
            {companiesData[activeCompany].calendarsLimit +
              '/' +
              companiesData[activeCompany].calendarsLimit}
          </h3>
          <p>{t('createNewCalendar.limitReachedPlease')}</p>
        </div>
      ) : (
        <div>
          <Grid>
            <Row>
              <Col xs={0} sm={2} md={4} />
              <Col xs={12} sm={6} md={4}>
                <div className='logo' style={{ textAlign: 'center' }}>
                  <i
                    className='pe-7s-date'
                    style={{
                      fontSize: '150px',
                      marginBottom: '20px',
                      color: 'green'
                    }}
                  />
                  <h3>{t('createNewCalendar.createNewCalendar')}</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    controlId='formBasicText'
                    validationState={errorName ? 'error' : null}
                  >
                    <FormInputs
                      ncols={['col-md-12']}
                      proprieties={[
                        {
                          label: 'Name',
                          type: 'test',
                          id: 'test',
                          name: 'name',
                          bsClass: 'form-control',
                          defaultValue: '',
                          placeholder: '',
                          disabled: addingCalendar
                        }
                      ]}
                    />
                    {errorName && <HelpBlock>{errorName}</HelpBlock>}
                  </FormGroup>
                  <ControlLabel>Primary language</ControlLabel>
                  <FormGroup controlId=''>
                    <FormControl
                      componentClass='select'
                      disabled={addingCalendar}
                      name='primaryLanguage'
                      placeholder='select'
                    >
                      {languagesData &&
                        map(languagesData, (lang, index) => {
                          return <option value={index}>{lang.name}</option>
                        })}
                    </FormControl>
                  </FormGroup>
                  <FormGroup
                    controlId='formBasicText'
                    validationState={errorCalendarType ? 'error' : null}
                  >
                    <ControlLabel>{t('createNewCalendar.type')}</ControlLabel>
                    <FormControl
                      componentClass='select'
                      disabled={addingCalendar}
                      name='type'
                      placeholder=''
                      onChange={changeCalendarType}
                    >
                      <option value='empty'>
                        {t('createNewCalendar.selectCalendarType')}
                      </option>
                      {calendarTypes.map((type, index) => {
                        return (
                          <option key={index} value={type.code}>
                            {type.name}
                          </option>
                        )
                      })}
                    </FormControl>
                    {errorCalendarType && (
                      <HelpBlock>{errorCalendarType}</HelpBlock>
                    )}
                  </FormGroup>
                  <Templates calendarType={calendarType} />
                  {addingCalendar && (
                    <FormGroup controlId='formBasicText'>
                      <HelpBlock>
                        {t('duplicateCalendar.coping')}{' '}
                        {t('duplicateCalendar.copyMessage.' + copyMessage)}
                      </HelpBlock>
                    </FormGroup>
                  )}

                  {addingCalendar ? (
                    <Button fill bsStyle='success'>
                      <Loading
                        type='spin'
                        color='white'
                        height='20'
                        width='20'
                      />
                    </Button>
                  ) : (
                    <Button fill bsStyle='success' type='submit'>
                      {t('createNewCalendar.create')}
                    </Button>
                  )}
                </form>
              </Col>
              <Col xs={0} sm={2} md={4} />
            </Row>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default translate('translations')(CreateNewCalendar)
